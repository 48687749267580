import { useDispatch, useSelector } from 'react-redux';

import { MAP_GIRL_FILTERS, updateFilters } from 'redux/ducks/map';
import { selectIsMapFilterSelected } from 'redux/selectors/selectors';
import { classModifier } from 'utils';

import ICONS from 'assets/icons';


const ShowSessionEscortsButton = () => {
  const isSessionEscortsFilterEnabled = useSelector(
    (state) => selectIsMapFilterSelected(state, MAP_GIRL_FILTERS.BUFFERED_ESCORTS)
  )
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      updateFilters(
        MAP_GIRL_FILTERS.BUFFERED_ESCORTS,
        [MAP_GIRL_FILTERS.BUFFERED_ESCORTS, MAP_GIRL_FILTERS.CHAT_ESCORTS]
      )
    );
  }

  return (
    <button
      className={classModifier("girls-map__toggle-btn", isSessionEscortsFilterEnabled && 'active')}
      onClick={handleClick}
    >
      Show Session Escorts
      <ICONS.buffer className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default ShowSessionEscortsButton;
