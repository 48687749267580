import React, { useState } from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import './CheckBoxFormItem.scss';
import CheckBoxItemOption from './CheckBoxItemOption';

const CheckBoxFormItem = ({ viaTitle, viaFieldName, selectOptions, valueToChange, setValues, saveContact }) => {

  const [isOpen, setIsOpen] = useState(false);

  const renderViaItemStateIcon = (item) => item 
    ? <ICONS.check className="contact-form-via-item__icon-check"/>
    : <span className="contact-form-via-item__btn-checkbox"></span>

  const toggleMenuIsOpen = (e, onChange, item) => {
    if (e.target.closest("span[data-btn='title']")) {
      setIsOpen(!isOpen);
      return;
    } 

    if (!item) {
      setIsOpen(!isOpen);
    } else {
      if(isOpen) {
        setIsOpen(false);
      } else {
        onChange(null);
      }
    }
  };

  const handleChange = (input) => {
    if (viaFieldName === 'is_ex') {
      // remove the prefix if the status ex is removed
      if (!!input.value) {
        setValues('fn', valueToChange.slice(3));
      } else {
        // add a prefix if the status ex is enabled
        const updatedFn = 'EX ' + (valueToChange || '');

        setValues('fn', updatedFn);
      }
    }

    input.onChange(!input.value);

    if (viaFieldName === 'is_ex' || viaFieldName === 'available_card' || viaFieldName === 'is_mistress') {
      saveContact(viaFieldName, !input.value);
    }
  };

  return (
    <Field
      name={viaFieldName}
      render={({ input }) => (
        <div className="contact-form-via-item">
          <button 
            type="button"
            onBlur={() => isOpen && setIsOpen(false)}
            className="contact-form-via-item__btn"
            onClick={(e) => !selectOptions 
              ? handleChange(input)
              : toggleMenuIsOpen(e, input.onChange, input.value) 
            }
          >
            {isOpen 
              ? <ICONS.chevron className="contact-form-via-item__icon-chevron"/>
              : renderViaItemStateIcon(input.value)
            }

            <span 
              data-btn='title' 
              className={classModifier("contact-form-via-item__btn-title", input.value && "checked")}
            >
              {viaTitle}
            </span>
          </button>

          {selectOptions && 
            <Select
              className="react-select"
              classNamePrefix="react-select"
              menuIsOpen={isOpen}
              isSearchable={false}
              onChange={option => {
                input.onChange(option.value);
                isOpen && setIsOpen(false);
              }}
              options={selectOptions}
              getOptionLabel={option => option}
              getOptionValue={option => option.value}
              value={selectOptions?.find(item => item.value === input.value) || null}
              components={{
                Control: () => <div></div>,
                Option: CheckBoxItemOption
              }}
            />
          }
        </div>
      )}
    />

  )
}

export default React.memo(CheckBoxFormItem);
