import { useDispatch, useSelector } from 'react-redux';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import ICONS from 'assets/icons';
import { selectMapGirlBySecondaryMode } from 'redux/selectors/selectors';
import { MAP_SECONDARY_MODES } from 'redux/ducks/map';


const SendNavigationPathButton = () => {
  const { clientData } = useSelector((state) => state.map);
  const [navigationGirl] = useSelector((state) => selectMapGirlBySecondaryMode(state, MAP_SECONDARY_MODES.TARGET_ESCORT));

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openModal(MODAL_TYPES.sendNavigationPathModal, {
      origin: clientData,
      destination: {
        latitude: navigationGirl.girl.latitude,
        longitude: navigationGirl.girl.longitude,
      },
    }))
  }

  return (
    <button
      className="girls-map__toggle-btn"
      onClick={handleClick}
    >
      Send Directions
      <ICONS.mapDash className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default SendNavigationPathButton;
