import React from 'react';

const DownloadButton = (props) => {
  const {
    className,
    urlsList,
    fileName,
    children,
  } = props;

  function fixUrl(url) {
    const unwantedPart = "s3.eu-west-2.amazonaws.com/";
    
    if (url.includes(unwantedPart)) {
        return url.replace(unwantedPart, "");
    }

    return url;
  }

  const download = (e, urlsList) => {
    e.preventDefault();
    e.stopPropagation()

    urlsList.forEach(src => {
      if(!src) return;
      const prepUrl = fixUrl(src)

      fetch(prepUrl)
        .then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            const docName = prepUrl.split('/')
              .reverse()[0]

            a.href = url;
            a.download = fileName || docName;
            a.click();
          });
        })
        .catch(console.error);
    })
  }

  return (
    <button
      className={className}
      onClick={(e) => download(e, urlsList)}
      title="download"
    >
      {children}
    </button>
  );
};

export default DownloadButton;
