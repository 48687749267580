import GoogleMapReact from 'google-map-react';
import { useJsApiLoader } from '@react-google-maps/api';

import { GOOGLE_MAPS_API_KEY, MAP_LIBRARIES, PREVIEW_MAP_DEFAULT_OPTIONS } from 'config/constants';
import useBuildNavigation from 'hooks/useBuildNavigation';

import Marker from 'components/UI/Marker/Marker';


const NavigationPreview = ({ origin, destination }) => {
  const { initializeNavigation, buildNavigation } = useBuildNavigation();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: MAP_LIBRARIES
  });

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, language: 'en' }}
      yesIWantToUseGoogleMapApiInternals
      shouldUnregisterMapOnUnmount={false}
      options={PREVIEW_MAP_DEFAULT_OPTIONS}
      defaultZoom={11}
      defaultCenter={{ lat: 0, lng: 0 }}
      onGoogleApiLoaded={({ map }) => {
        initializeNavigation(map);
        buildNavigation(origin, destination);
      }}
    >
      <Marker
        className="client-marker"
        lat={origin.lat || origin.latitude}
        lng={origin.lng || origin.longitude}
        isSmall
      />

      <Marker
        className="girl-marker"
        lat={destination.lat || destination.latitude}
        lng={destination.lng || destination.longitude}
        isSmall
      />
    </GoogleMapReact>
  )
}

export default NavigationPreview;
