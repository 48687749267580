export default (linkObj) => {
  const locationCoordinates = linkObj.site_url?.match(/[-+]?\d{1,3}\.\d+/g) || [];

  if (locationCoordinates.length === 2) {
    return { location: { lat: locationCoordinates[0], lng: locationCoordinates[1] } };
  }

  if (locationCoordinates.length === 4) {
    return {
      route: {
        origin: { lat: locationCoordinates[0], lng: locationCoordinates[1] },
        destination: { lat: locationCoordinates[2], lng: locationCoordinates[3] }
      }
    }
  }

  return null;
}

