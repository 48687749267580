import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import {
  Drafts,
  debounce,
  throttle,
  classModifier,
  parseMsgText,
  getDefaultField,
  getDateByTimezoneOffset
} from 'utils';
import API from 'api/api';
import { usePrevious, useDidUpdate } from 'hooks';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';
import { CHAT_TYPES, CHAT_ACTION_MENUS } from 'config/constants';
import { selectOperatorsForMention } from 'redux/selectors/selectors';
import ICONS from 'assets/icons';
import useSelectorToggle from 'hooks/useSelectorToggle';

import './ChatMessageInput.scss';
import MessageTemplates from './components/MessageTemplates';
import TypingIndicator from './components/TypingIndicator/TypingIndicator';
import TypingOperators from './components/TypingIndicator/TypingOperators';
import AudioStatusBtn from './components/AudioStatusBtn';
import ChatMessageForbidInput from './components/ChatMessageForbidInput/ChatMessageForbidInput'; // TODO v2
import EmojiPickerBtn from './components/EmojiPickerBtn/EmojiPickerBtn';
import VoiceMessageRecorder from './components/VoiceMessageRecorder';
import ChatSendingMessage from 'components/ChatSendingMessage/ChatSendingMessage';
import MentionMenu from './components/MentionMenu/MentionMenu';

const mentionInitialState = {
  show: false,
  options: [],
  active: null,
}

const MessageInput = (props) => {
  const {
    activeRecipient,
    profileId,
    editedMsg,
    sharedMsg,
    repliedMsg,
    publicChat,
    girlChat,
    clientChat,
    voiceMsg,
    images,
    videos,
    type,
    isDisabled,
    initialMsg,
    isHideMuteBtn,
    activeGroup,
    activeGroupType,
    userImessageMode,
  } = props;

  const getCommunicationDisabledStatus = () => {
    if (!!activeGroup) return false;

    const defaultTel = activeRecipient.tels?.find((tel) => tel.default);

    const hasContactViaOption = activeRecipient.via &&
      Object.values(activeRecipient.via).some(option => !!option);
    const isTelegramChanelAllowed = activeRecipient.default_channel === 'telegram' && !!activeRecipient.via?.via_telegram;
    const isTelegramP2PChanelAllowed = activeRecipient.default_channel === 'telegram_client' && !!activeRecipient.via?.via_telegram;
    const isTelChanelAllowed = activeRecipient.default_channel === 'tel'
      && !!activeRecipient.via?.via_sms_phone_uuid
      && activeRecipient.via?.via_sms_phone_uuid === defaultTel.uuid;

    const isIphoneChanelNotAllowed = [CHAT_TYPES.GIRL, CHAT_TYPES.CLIENT].includes(type)
      && !activeGroup && (activeRecipient.default_channel === 'iphone' && !userImessageMode);
    const isWhatsappChanelNotAllowed = !activeGroup && (
      activeRecipient.default_channel === 'whatsapp' &&
      !activeRecipient.whatsapp_tels?.some((tel) => tel === defaultTel?.tel)
    );

    return !!(hasContactViaOption && !(isTelegramChanelAllowed || isTelChanelAllowed || isTelegramP2PChanelAllowed)) || isIphoneChanelNotAllowed || isWhatsappChanelNotAllowed;
  };

  // const [textareaClassName, setTextareaClassName] = useState('chat-input__input');
  const [message, setMessage] = useState('');
  const [isCommunicationDisabled, setIsCommunicationDisabled] = useState(getCommunicationDisabledStatus());
  const [tempMessage, setTempMessage] = useState('');
  const [actionMenu, setActionMenu] = useSelectorToggle(null);
  const [noMicro, setNoMicro] = useState(false);

  const [shortcut, setShortcut] = useState(null);
  const [mention, setMention] = useState(mentionInitialState);
  const [activeMentionOperator, setActiveMentionOperator] = useState(null);

  const activeMentionRef = useRef(null);
  const fileInputRef = useRef(null);

  const lastWord = useRef('');
  const initialDraft = useRef(
    Drafts.getOne({ ...activeRecipient, ...(activeGroup ? { id: activeGroup } : {}) },
      profileId)
  );
  const usedShortcutsIds = useRef([]);

  const isDidMount = useRef(true);
  const isTypingRef = useRef(false);
  const messageInputRef = useRef('');

  const [shortcutPosition, setShortcutPosition] = useState({
    x: null,
    y: null
  });

  const prevMsg = usePrevious(message);
  // const prevContactId = usePrevious(activeRecipient.id);

  const defaultTel = activeRecipient.tels?.find((tel) => tel.default);
  const isNumberHaveTelegramChanel = activeRecipient.telegram_tels?.some((tel) => tel.value === defaultTel?.tel);
  const isNumberHaveTelegramP2PChanel = activeRecipient.telegram_tels?.some((tel) => tel.value === defaultTel?.tel && tel.type === 'telegram_client');
  const isNumberHaveWhatsappChanel = activeRecipient.whatsapp_tels?.some((tel) => tel === defaultTel?.tel);
  const isNumberHaveIphoneChanel = activeRecipient.iphone_tels?.some((tel) => tel === defaultTel?.tel);
  const currentSymbol = props.type === CHAT_TYPES.ROOM ? '@' : '#';

  const changeChannel = (channel) => {
    if (activeRecipient.default_channel === channel) return;

    API.changeContactDefaultChannel(activeRecipient.id, channel);
  };

  const throttledSendTypingPing = useCallback(
    throttle(
      API.sendTyping,
      3000,
      true
    ), []);

  const debouncedSendTyping = useCallback(
    debounce((chatId, isTyping, text) => {
      if (isTypingRef.current) {
        isTypingRef.current = false;

        API.sendTyping(chatId, isTyping, text);
      }
    }, 3000),
    []);


  useDidUpdate(() => {
    if (activeGroup) {
      initialDraft.current = Drafts.getOne({ id: activeGroup, type: activeRecipient.type }, profileId);
    } else {
      initialDraft.current = Drafts.getOne(activeRecipient, profileId);
    }
  }, [activeRecipient.id, activeGroup])

  useLayoutEffect(() => {         // change inputHeight of every message change
    if (isDidMount.current) {
      isDidMount.current = false;
    }

    if (props.fixTab) {
      if (
        message.length &&
        props.unfixedTab === activeRecipient.id &&
        activeRecipient.draft_message !== message &&  // For contact
        initialDraft.current !== message  // For room
      ) {
        props.fixTab(activeRecipient.id);
      }
    }
  }, [message, tempMessage]);

  useLayoutEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      // check audio stream and stop it
      .then(stream => console.log(stream.getTracks().forEach((track) => track.stop())))
      // If user haven't microphone
      .catch(() => setNoMicro(true));
  }, []);

  useEffect(() => {
    if (editedMsg) {
      setTempMessage(parseMsgText(editedMsg.body, true));
    }
    if (!editedMsg) {
      setTempMessage('');
    }
  }, [editedMsg]);

  useEffect(() => {     // if user have changed => focus message input
    if (activeGroup) {
      !isCommunicationDisabled && messageInputRef.current.focus();
      return;
    }

    if (!(isClientOrGirl && isEmptyNumber)) {
      !isCommunicationDisabled && messageInputRef.current.focus();
    }
  }, [activeRecipient.id, activeGroup])

  useEffect(() => {     // if user have changed => check drafts or clean msg
    if (!props.isMailingMode) {
      const draft = Drafts.getOne(
        { ...activeRecipient, ...(activeGroup ? { id: activeGroup } : {}) },
        profileId
      );

      if (activeRecipient.draft_message && !activeGroup) {
        setMessage(activeRecipient.draft_message);
      } else if (draft && (!isClientOrGirl || (isClientOrGirl && activeGroup))) {
        setMessage(draft);
      } else if (message) {
        setMessage('');
      }
      // if (!(isClientOrGirl && isEmptyNumber)) {
      //   !isCommunicationDisabled && messageInputRef.current.focus();
      // }
    } else {
      if (Array.isArray(activeRecipient.id)) {
        setMessage(initialMsg ? initialMsg.body : '');
      }
    }
  }, [activeRecipient.id, activeGroup]);

  useEffect(() => {
    if (props.isAnyAttachments) {
      !isCommunicationDisabled && messageInputRef.current.focus();
    }
  }, [props.isAnyAttachments]);

  useEffect(() => {
    if (!!activeGroup) return;

    if (shortcut) {
      const msg = tempMessage
        ? tempMessage
        : message;

      calcShortcutPosition(messageInputRef.current, msg.trim());
    }

    if (prevMsg !== message && !props.isMailingMode && isTypingRef.current) {
      throttledSendTypingPing(activeRecipient.id + '_' + type, true);
    }
  }, [message, tempMessage, shortcut]);

  useEffect(() => {
    if (props.repliedMsg) {
      messageInputRef.current.focus();
    }
  }, [props.repliedMsg])

  useDidUpdate(() => {
    if (activeGroup) return;

    setIsCommunicationDisabled(getCommunicationDisabledStatus());
  }, [activeRecipient.default_channel, activeRecipient.via, userImessageMode]);

  // useDidUpdate(() => {
  //   if (prevContactId === activeRecipient.id && !activeGroup) {
  //     let timeoutId;

  //     if (!props.isMailingMode) {
  //       setTextareaClassName('chat-input__input chat-input__input--changed');

  //       timeoutId = setTimeout(() => {
  //         setTextareaClassName('chat-input__input');
  //       }, 100);
  //     }

  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [activeRecipient.draft_message]);

  useDidUpdate(() => {
    if (mention.active && activeMentionRef.current && !activeGroup) {
      activeMentionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
    }
  }, [mention])


  const updateDraft = (msg) => {
    const drafts = Drafts.getAll(profileId);

    const id = activeRecipient.type
      ? activeGroup
        ? `${activeGroup}_${activeRecipient.type}`
        : `${activeRecipient.id}_${activeRecipient.type}`
      : `${activeRecipient.id}_${activeRecipient.chatType}`;

    if (msg.length) {
      drafts[id] = msg;
      Drafts.update(drafts, profileId);
    } else {
      delete drafts[id];
      Drafts.update(drafts, profileId);
    }
  };

  const addEmoji = (emoji) => {
    const sym = emoji.unified.split('-');
    const codesArray = [];

    sym.forEach(el => codesArray.push('0x' + el));
    const emojiPic = String.fromCodePoint(...codesArray);

    isTypingRef.current = true;

    if (!props.isMailingMode && !activeGroup) {
      debouncedSendTyping(activeRecipient.id + '_' + type, false, message + emojiPic);
    }
    setMessage(message + emojiPic);

    !isCommunicationDisabled && messageInputRef.current.focus();
  };

  const addBoilerplate = (boilerplate) => {
    setMessage(boilerplate);
    !isCommunicationDisabled && messageInputRef.current.focus();

    isTypingRef.current = true;

    if (!props.isMailingMode && !activeGroup) {
      debouncedSendTyping(activeRecipient.id + '_' + type, false, boilerplate);
    }
  };

  const editMsg = () => {
    if (editedMsg.body.trim() !== tempMessage.trim() && tempMessage.trim().length) {
      const updatedMsg = {
        id: editedMsg.id,
        body: tempMessage
      };
      // edit scheduledMsg
      if (props.isScheduledMsgsSource) {
        API.updateScheduledMsg(updatedMsg);
      }
      else {
        API.updateChatMsg(updatedMsg)
      }
    }
    afterSubmit();
  };

  const expandShortcut = () => {
    const shortcutPosition = message.lastIndexOf(lastWord.current);

    const beforeShortcut = message.slice(0, shortcutPosition);

    const afterShortcut = message
      .slice(shortcutPosition)
      .replace(lastWord.current, shortcut.value);

    const updatedMsg = beforeShortcut + afterShortcut;

    usedShortcutsIds.current.push(shortcut.id);

    isTypingRef.current = true;

    if (!props.isMailingMode && !activeGroup) {
      debouncedSendTyping(activeRecipient.id + '_' + type, false, updatedMsg);
    }

    setMessage(updatedMsg);
    setShortcut(null);

    !isCommunicationDisabled && messageInputRef.current.focus();
  };

  const expandMention = (activeMention) => {
    const mentionPosition = message.lastIndexOf(lastWord.current);

    const mentionShortcut = message.slice(0, mentionPosition);

    const afterMention = message
      .slice(mentionPosition)
      .replace(lastWord.current, currentSymbol + (activeMention || mention.active));

    const updatedMsg = mentionShortcut + afterMention;

    setMessage(updatedMsg);
    setMention(mentionInitialState);

    !isCommunicationDisabled && messageInputRef.current.focus();
  };

  const handleChange = (e) => {
    lastWord.current = e.target.value.split(/\n| /).pop();
    isTypingRef.current = true;

    if (editedMsg) {
      setTempMessage(e.target.value);
    } else {
      setMessage(e.target.value);

      if (!isClientOrGirl || (isClientOrGirl && activeGroup)) {
        updateDraft(e.target.value);
      }
      if (!props.isMailingMode && !activeGroup) {
        debouncedSendTyping(activeRecipient.id + '_' + type, false, e.target.value);
      }
    }
    // shortcuts part
    if (props.shortcuts[lastWord.current]) {
      setShortcut(props.shortcuts[lastWord.current]);
    }
    else if (!props.shortcuts[lastWord.current] && shortcut) {
      setShortcut(null);
    }
    // mentions works in all chats
    if (
      props.operatorsShortInfo.length &&
      [CHAT_TYPES.ROOM, CHAT_TYPES.CLIENT, CHAT_TYPES.GIRL].includes(props.type)
    ) {
      if (lastWord.current[0] === currentSymbol) {
        // open mentions menu with all options
        if (lastWord.current.length === 1) {
          setMention({
            show: true,
            options: props.operatorsShortInfo,
            active: props.operatorsShortInfo[0].username
          })
          setActiveMentionOperator(props.operatorsShortInfo[0]);
        }
        // open mentions menu with possible options
        else {
          const filteredMentionOptions = props.operatorsShortInfo
            .filter(option => 
              (currentSymbol + option.username.toLowerCase()).startsWith(lastWord.current.toLowerCase())
            );

          // if possible options exists
          if (filteredMentionOptions.length) {
            setMention({
              show: true,
              options: filteredMentionOptions,
              active: filteredMentionOptions[0].username
            })
            setActiveMentionOperator(filteredMentionOptions[0]);
          }
          // if no options
          else {
            setMention(mentionInitialState);
            setActiveMentionOperator(null)
          }
        }
      }
      else if (mention.show) {
        setMention(mentionInitialState)
        setActiveMentionOperator(null)
      }
    }
  };

  const calcShortcutPosition = (input, msg) => {
    if (!(input instanceof Element)) return;

    const {
      offsetTop,
      offsetHeight,
      scrollTop
    } = input;

    const { lineHeight } = getComputedStyle(input);

    const { x, y } = getInputCursorXY(input, msg);

    const newLeft = x;

    const newTop = Math.min(
      y - scrollTop,
      (offsetTop + offsetHeight) - parseInt(lineHeight, 10)
    );

    setShortcutPosition({
      left: newLeft + 'px',
      top: newTop + 'px'
    });
  };

  const getInputCursorXY = (input, msg) => {
    if (!(input instanceof Element
      && input.parentElement instanceof Element
      && input.nextElementSibling instanceof Element)) return;

    const {
      offsetLeft: inputX,
      offsetTop: inputY,
    } = input;

    const form = document.createElement('div');
    const formStyle = getComputedStyle(input.parentElement);

    for (const prop of formStyle) {
      form.style[prop] = formStyle[prop];
    }

    const buttons = document.createElement('div');

    buttons.style.width = getComputedStyle(input.nextElementSibling).width;

    const div = document.createElement('div');
    const inputStyle = getComputedStyle(input);

    for (const prop of inputStyle) {
      div.style[prop] = inputStyle[prop];
    }

    const textContent = msg.substr(0, msg.length - 1);

    div.textContent = textContent;
    form.classList.add('custom-scroll');

    const span = document.createElement('span');

    span.textContent = msg.substr(msg.length - 1);

    div.appendChild(span);

    form.appendChild(div);
    form.appendChild(buttons);

    const root = document.getElementById('root');

    root.appendChild(form);

    const { offsetLeft: spanX, offsetTop: spanY } = span;

    root.removeChild(form);

    return {
      x: inputX + spanX,
      y: inputY + spanY
    };
  };

  const changeActiveMentionByKeyDown = (key) => {
    if (mention.options.length === 1) return;

    const activeIdx = mention.options.findIndex((option) => option.username === mention.active);
    const maxIdx = mention.options.length - 1;

    let newActiveIdx;

    if (key === 'ArrowUp') {
      if (activeIdx > 0) {
        newActiveIdx = activeIdx - 1;
      }
      else if (activeIdx === 0) {
        newActiveIdx = maxIdx;
      }
    }
    else {
      if (activeIdx < maxIdx) {
        newActiveIdx = activeIdx + 1;
      }
      else if (activeIdx === maxIdx) {
        newActiveIdx = 0;
      }
    }

    setMention(prevMention => ({
      ...prevMention,
      active: mention.options[newActiveIdx].username
    }));
    setActiveMentionOperator(mention.options[newActiveIdx]);
  };

  const createScheduledMsg = () => {
    const messageObj = {
      body: message,
      contactId: activeRecipient.id
    }

    props.openModal(
      MODAL_TYPES.timePicker,
      {
        onSelectDate: date => {
          return API.createScheduledMsg({ ...messageObj, date })
            .then(() => {
              afterSubmit();
              props.closeModal(MODAL_TYPES.timePicker);
            })
            .catch(console.error);
        },
        isShowTimeMenu: true,
        minDate: +getDateByTimezoneOffset(props.userTimezone) + 900000,
        userTimezone: props.userTimezone,
      }
    )
  };

  const handleKeyDownDown = (e) => {
    if (activeGroup) return;

    if (mention.show) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        changeActiveMentionByKeyDown(e.key);
      }
      else if (e.key === 'Enter') {
        e.preventDefault();
        return expandMention();
      }
    }

    if (e.key === 'Enter') {
      if (shortcut) {
        e.preventDefault();
        return expandShortcut();
      }
      if (e.nativeEvent.metaKey || e.shiftKey) {
        if (editedMsg) {
          e.preventDefault();
          return editMsg();
        }
      }
      if (e.altKey && props.isScheduledMsgsSource) {
        e.preventDefault();
        return createScheduledMsg();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    isTypingRef.current = false;

    if (editedMsg) {
      return editMsg();
    }

    if (!message.trim().length && !sharedMsg && !voiceMsg && !images && !videos && !repliedMsg) {
      return;
    }

    let files = null;

    if (images && videos) {
      files = [...images, ...videos];
    }
    else if (images) {
      files = images;
    }
    else if (videos) {
      files = videos;
    }

    const messageObj = {
      text: message,
      files: files,
      voiceMsg: voiceMsg
        ? {
          blob: voiceMsg.blob,
          duration: voiceMsg.duration
        }
        : '',
      shortcuts: usedShortcutsIds.current.length
        ? usedShortcutsIds.current
        : '',
      sharedMsgId: sharedMsg
        ? sharedMsg.id
        : '',
      repliedMsgId: repliedMsg
        ? repliedMsg.id :
        '',
      sharedContactId: props.sharedContactId
        ? props.sharedContactId
        : '',
      channel: activeRecipient.default_channel,
    };

    if (props.isScheduledMsgsSource) {
      return createScheduledMsg();
    }

    if (props.isMailingMode) {
      props.sendMessage(messageObj, activeRecipient);
    } else if (activeMentionOperator && CHAT_TYPES.ROOM !== props.type) {
      API.lockContact("lock", activeRecipient.id, activeMentionOperator.id);

      setActiveMentionOperator(null);
    }
     else if (props.type === CHAT_TYPES.ROOM) {
      API.sendMessageToChat(messageObj, activeRecipient.id)
        .catch(console.error)
      
    } else if (activeGroup) {
      API.sendGroupMessage(messageObj, activeGroup, activeGroupType).catch(console.error)
    } else {
      API.sendMessage(messageObj, activeRecipient)
        .catch(err => {
          console.error(err);

          if (err.response && err.response.data && err.response.data.status === 403) {
            props.openModal(MODAL_TYPES.error, { text: err.response.data.message });
          }
        });
    }

    setActionMenu(null);

    afterSubmit();
  };

  const afterSubmit = () => {
    updateDraft('');

    if (sharedMsg) {
      props.cleanSharedMsg();
    }
    if (props.sharedContactId) {
      props.updateSharedContact(null);
    }
    if (images) {
      props.cleanImages();
    }
    if (videos) {
      props.cleanVideos();
    }
    if (voiceMsg) {
      props.updateVoiceMsg();
    }
    if (message && !editedMsg) {
      setMessage('');
    }
    if (editedMsg) {
      props.cleanEditedMsg();
    }
    if (repliedMsg) {
      props.cleanRepliedMsg();
    }

    usedShortcutsIds.current = [];
  };

  const renderTypingIndicator = () => {
    if (!props.typingStatus) {
      return null;
    }

    return (
      <div className='chat-input__indicator-wrap'>
        <TypingOperators /* operatorsIds={props.typingStatus} */ />
        <TypingIndicator />
      </div>
    );
  };

  const getCommunicationAdvice = () => {
    const isTelChanelAllowedForCurrentNumber = activeRecipient.via?.via_sms_phone_uuid === defaultTel?.uuid;
    const telChosenForCommunicationViaSms = activeRecipient.tels?.find((tel) => tel.uuid === activeRecipient.via?.via_sms_phone_uuid);

    const activeCommunications = [];
    const activeCommunicationsForCurrentNumber = [];
    const communicationLabels = {
      via_email_uuid: 'Email',
      via_sms_phone_uuid: 'SMS',
      via_telegram: 'Telegram',
      via_phone_uuid: 'Phone',
    };

    if (activeRecipient.via) {
      Object.keys(activeRecipient.via).forEach((key) => {
        if (!!activeRecipient.via[key]) {
          if (key === 'via_sms_phone_uuid' && !isTelChanelAllowedForCurrentNumber) {
            activeCommunications.push(communicationLabels[key]);
          } else {
            activeCommunicationsForCurrentNumber.push(communicationLabels[key]);
          }
        }
      });
    }

    if (activeRecipient.default_channel === 'iphone' && !userImessageMode.is_imessage_active) {
      return 'Imessage bridge is not available. Please check the status of the Macbook and the Internet.';
    }

    const communicationAdvice = `use ${activeCommunicationsForCurrentNumber.length ? activeCommunicationsForCurrentNumber.join('/') : 'the another line'} instead`;

    if (!isTelChanelAllowedForCurrentNumber && telChosenForCommunicationViaSms) {
      return (
        `This communication line is restricted for this client. Please choose ${telChosenForCommunicationViaSms.tel} for ${activeCommunications.join('/')}
        ${activeCommunicationsForCurrentNumber.length ? `or ${communicationAdvice}` : ''}`
      );
    }

    return `This communication line is restricted for this client. Please ${communicationAdvice}`;
  };

  const isEmptyNumber = !getDefaultField(activeRecipient.tels, 'tel');
  const isClientOrGirl = [CHAT_TYPES.GIRL, CHAT_TYPES.CLIENT].includes(type);
  const isTelegramNumber = activeRecipient.telegram_tels && !!activeRecipient.telegram_tels.length;
  const isIphoneNumber = activeRecipient.iphone_tels && !!activeRecipient.iphone_tels.length;

  const isDefaultChatChannel = activeRecipient.default_channel === 'tel' || !activeRecipient.default_channel;
  const isTelegramChatChannel = activeRecipient.default_channel === 'telegram';
  const isWhatsappChatChannel = activeRecipient.default_channel === 'whatsapp';
  const isIphoneChatChannel = activeRecipient.default_channel === 'iphone';
  const isPrivateTgChatChannel = activeRecipient.default_channel === 'telegram_client';

  const isShowFileInput = props.fileInputProps && (
    publicChat || ((clientChat || girlChat) && (isTelegramChatChannel || isPrivateTgChatChannel || activeGroup))
  );
  const isShowVoiceRecorder = publicChat ||
    ((clientChat || girlChat) && isTelegramChatChannel && !activeGroup) ||
    ((clientChat || girlChat) && (isWhatsappChatChannel || isIphoneChatChannel || isPrivateTgChatChannel));

  // Code for sending files via iphone
  // const isShowFileInput = props.fileInputProps &&
  //   (publicChat || (
  //       (clientChat || girlChat) &&
  //       (isTelegramChatChannel || isWhatsappChatChannel || isIphoneChatChannel || activeGroup)
  //     )
  //   );

  if (isEmptyNumber && isClientOrGirl && !isTelegramNumber && !isIphoneNumber && !activeGroup) {
    return (
      <div className="chat-input">
        <ChatMessageForbidInput
          activeRecipient={activeRecipient}
          openModal={props.openModal}
        /> {/* //TODO v2 */}
      </div>
    )
  }

  return (
    <div className="chat-input">
      <div className="chat-input__header">
        <div className="chat-input__indicator">
          {renderTypingIndicator()}
        </div>

        {isClientOrGirl &&
          <div className="chat-input__dispatch-types">
            {!activeGroup && (
              <button
                onClick={() => changeChannel('tel')}
                disabled={!activeRecipient.tels.length || actionMenu === CHAT_ACTION_MENUS.VOICE_RECORDER}
                className={classModifier('chat-input__dispatch-type-btn', isDefaultChatChannel && 'active')}
              >
                <ICONS.comments width="20" height="20" />
              </button>
            )}

            <button
              onClick={() => changeChannel('telegram')}
              disabled={!activeGroup && !isNumberHaveTelegramChanel}
              className={
              classModifier('chat-input__dispatch-type-btn', [
                (isTelegramChatChannel || activeGroup) && 'active'
              ])}
            >
              {activeGroup
                ? <ICONS.telegram width="20" height="20"/>
                : <ICONS.robot width="20" height="20" />
              }
            </button>

            {!activeGroup && (
              <button
                onClick={() => changeChannel('whatsapp')}
                disabled={!isNumberHaveWhatsappChanel}
                className={classModifier('chat-input__dispatch-type-btn', [
                  isWhatsappChatChannel && 'active'
                ])}
              >
                <ICONS.whatsapp width="20" height="20"/>
              </button>
            )}

            {!activeGroup && (
              <button
                onClick={() => changeChannel('iphone')}
                disabled={!isNumberHaveIphoneChanel}
                className={classModifier('chat-input__dispatch-type-btn', [
                  isIphoneChatChannel && 'active'
                ])}
              >
                <ICONS.apple width="20" height="20"/>
              </button>
            )}

            {!activeGroup && (
              <button
                onClick={() => changeChannel('telegram_client')}
                disabled={!isNumberHaveTelegramP2PChanel}
                className={classModifier('chat-input__dispatch-type-btn', [
                  isPrivateTgChatChannel && 'active'
                ])}
              >
                <ICONS.telegram width="20" height="20"/>
              </button>
            )}
          </div>
        }

        <div
          className={classModifier('chat-input__actions', [
            isCommunicationDisabled && 'disabled',
          ])}
        >
          {isShowVoiceRecorder &&
            <button
              type="button"
              onClick={() => !noMicro && setActionMenu(CHAT_ACTION_MENUS.VOICE_RECORDER)}
              className='chat-input__btn'
            >
              <ICONS.microphone
                className={classModifier('chat-input__btn-icon', [actionMenu === CHAT_ACTION_MENUS.VOICE_RECORDER && 'selected'])}
              />
            </button>
          }

          {isShowFileInput &&
            <button
              className="chat-input__btn chat-input__btn--attach"
              title="attach file"
              onClick={() => fileInputRef.current?.click()}
            >
              <input
                {...props.fileInputProps()}
                ref={fileInputRef}
                disabled={!!voiceMsg}
              />
              <ICONS.clip className="chat-input__btn-icon" />
            </button>
          }

          {activeRecipient.id !== 'webmaster' && !isHideMuteBtn && !activeGroup &&
            <AudioStatusBtn //TODO v2 FontAwesome
              changeRecipientAudioStatus={props.changeRecipientAudioStatus}
              activeRecipient={activeRecipient}
            />
          }

          {props.msgTemplates &&
            <MessageTemplates
              msgTemplates={props.msgTemplates}
              addBoilerplate={addBoilerplate}
              isShow={actionMenu === CHAT_ACTION_MENUS.TEMPLATES}
              onClick={() => setActionMenu(CHAT_ACTION_MENUS.TEMPLATES)}
            />
          }

          <EmojiPickerBtn
            onSelect={addEmoji}
            isShow={actionMenu === CHAT_ACTION_MENUS.SMILES}
            onClick={() => setActionMenu(CHAT_ACTION_MENUS.SMILES)}
          />
        </div>
      </div>

      <div className='chat-input__form-container'>
        {actionMenu === CHAT_ACTION_MENUS.VOICE_RECORDER && (
          <VoiceMessageRecorder
            updateVoiceMsg={props.updateVoiceMsg}
            activeRecipient={activeRecipient}
            chatType={props.type}
            disabled={!!images?.length || !!videos?.length || !!voiceMsg}
            handleSubmit={handleSubmit}
            setActive={() => setActionMenu(CHAT_ACTION_MENUS.VOICE_RECORDER)}
          />
        )}

        {isCommunicationDisabled &&
          <div className='chat-input__comm-warning'>
            {getCommunicationAdvice()}
          </div>
        }

        {!isCommunicationDisabled &&
          <form
            className='custom-scroll'
            onSubmit={handleSubmit}
          >
            <ChatSendingMessage
              handleSubmit={handleSubmit}
              inputRef={messageInputRef}
              onKeyDown={handleKeyDownDown}
              isScheduledMsgsSource={props.isScheduledMsgsSource}
              autosize
              inputProps={{
                placeholder: "Your message",
                autoComplete: "off",
                value: editedMsg ? tempMessage : message,
                onChange: handleChange,
                disabled: isDisabled
              }}
            />

            {shortcut &&
              <span
                onClick={expandShortcut}
                style={shortcutPosition}
                className="chat-input__shortcut"
              >
                {shortcut.value}
              </span>
            }

            <MentionMenu
              message={message}
              setMessage={setMessage}
              mention={mention}
              setMention={setMention}
              messageInputRef={messageInputRef}
              lastWord={lastWord}
              mentionInitialState={mentionInitialState}
              setActiveMentionOperator={setActiveMentionOperator}
              currentSymbol={currentSymbol}
            />
          </form>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  operatorsShortInfo: selectOperatorsForMention(state),
  userImessageMode: ('is_imessage_active' in state.user.mode) ? !!state.user.mode.is_imessage_active : true,
});

const mapDispatchToProps = {
  closeModal,
  openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput);

