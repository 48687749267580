
import classNames from 'classnames';
import { components as globalComponents } from 'react-select';
import { useEffect, useMemo, useRef } from 'react';

import { classModifier } from 'utils';

import './Select.scss';
import Creatable from 'react-select/creatable';
import { AsyncPaginate } from 'react-select-async-paginate';
import Tooltip from 'components/UI/Tooltip/Tooltip';



const Select = (props) => {
  const { components, className, type, disableTooltips, ...restProps } = props;
  const { MenuList: DefaultMenuList, Option: DefaultOption } = components || {};
  const menuRef = useRef();

  const checkIsMenuScrolled = () => {
    const scrollPosition = menuRef.current?.scrollTop;

    if (scrollPosition > 0 && scrollPosition !== undefined) {
      menuRef.current.classList.add('select__menu--scrolled');
    } else {
      menuRef.current.classList.remove('select__menu--scrolled');
    }
  }

  const SingleValue = ({ innerProps, ...props }) => {
    const { children, selectProps: { menuIsOpen } } = props;
  
    return (
      <Tooltip
        text={children}
        visibleWhenIsOverflowed
        extraCondition={!menuIsOpen}
        disable={disableTooltips}
      >
        {(setTriggerRef) => (
          <globalComponents.SingleValue
            innerProps={{ ref: setTriggerRef, ...innerProps }}
            {...props}
          >
            {children}
          </globalComponents.SingleValue>
        )}
      </Tooltip>
    )
  }

  const InnerMenuList = (props) => {
    const { children } = props;
    
    return (
      <globalComponents.MenuList {...props}>
        {children}
      </globalComponents.MenuList>
    )
  }

  const MenuList = (props) => {
    const MenuList = DefaultMenuList || InnerMenuList;

    useEffect(() => {
      menuRef.current?.addEventListener('scroll', checkIsMenuScrolled);

      return () => {
        menuRef.current?.removeEventListener('scroll', checkIsMenuScrolled);
      };
    }, []);

    return (
      <menu>
        <MenuList
          innerRef={menuRef}
          {...props}
        >
          {props.children}
        </MenuList>
      </menu>
    )
  }

  const Menu = (props) => {
    return (
      // <Portal>
        <globalComponents.Menu {...props}>
          {props.children}
        </globalComponents.Menu>
      // </Portal>
    )
  }

  const Option = (props) => {
    const newProps = props.data.value === 'special'
      ? { className: 'select__option--special', ...props }
      : props

    return (
      <Tooltip
        text={props.data.label}
        visibleWhenIsOverflowed
        disable={disableTooltips}
      >
        {(setTriggerRef) => {
          const optionLabel = useMemo(() => (
            <>
              {props.data.leftIcon || null}

              <p
                className='select__option-text'
                ref={setTriggerRef}
              >
                {props.label}
              </p>

              {props.data.rightIcon || null}
            </>
          ), [props])

          if (DefaultOption) {
            return <DefaultOption {...newProps}>{optionLabel}</DefaultOption>
          } else {
            return <globalComponents.Option {...newProps}>{optionLabel}</globalComponents.Option>
          }
        }}
      </Tooltip>
    )
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.value === 'special' ? '#0092F2' : '#808080',
    })
  };

  const SelectComponent = props.creatable ? Creatable : AsyncPaginate;

  return (
    <SelectComponent
      className={classNames(className, classModifier('select', type))}
      classNamePrefix='select'
      styles={{ ...customStyles, ...props.customStyles }}
      components={{
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        SingleValue,
        MenuList,
        Menu,
        Option,
        ...components
      }}
      {...restProps}
    />
  )
}

export default Select;
