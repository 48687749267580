import React, { useState, useEffect } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { CHAT_TYPES } from 'config/constants';

import './ChatHeaderActionMenu.scss';
import Icon from 'components/Icon/Icon';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';

const ChatHeaderActionMenu = ({
  type,
  userId,
  girlDivaId,
  activeRecipient,
  ...props
}) => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    trigger: 'click',
    mutationObserverOptions: null,
    visible: isMenuActive,
    onVisibleChange: setIsMenuActive,
  });

  const generateSubMenuItems = () => {
    const items = [
      {
        action: () => props.toggleContactMark(activeRecipient),
        title: activeRecipient.marked ? 'Unmark' : 'Mark',
        content: (
          <span className={classModifier('chat-header-action-menu__icon', ['mark', activeRecipient.marked && 'marked'])} />
        ),
      },
      {
        action: () => props.toggleContactPin(activeRecipient),
        title: activeRecipient.pinned ? 'Unpin' : 'Pin',
        content: (
          <ICONS.pin
            className={classModifier('chat-header-action-menu__icon', ['pin', activeRecipient.pinned && 'pinned'])}
          />
        ),
      },
    ]

    if (activeRecipient.type === 2) {
      items.push({
        action: () => props.openModal(MODAL_TYPES.bufferedGirlsBookingsModal, { girlId: girlDivaId }),
        title: 'Actual bookings',
        content: (
          <ICONS.calendar className="chat-header-action-menu__icon" />
        ),
        disabled: !girlDivaId,
      })

      items.push({
        action: () => window.open(activeRecipient.urls[0].url, '_blank'),
        title: 'Open profile',
        disabled: !activeRecipient.urls.length,
        content: (
          <ICONS.externalLinkSquare className="chat-header-action-menu__icon" />
        ),
      })
    }

    return items;
  };

  const handleItemClick = (e, item) => {
    item.action && item.action();

    if (item.closeOnClick) {
      setIsMenuActive(false);
    }
  }

  return (
    <div className={classModifier('chat-header-action-menu', visible && 'active')}>
      <button
        ref={setTriggerRef}
        className="chat-header-action-menu__menu-btn"
      >
        <ICONS.burgerMenu width="13" height="13"/>
      </button>
 
      <div className="chat-header-action-menu__clip-wrapper">
        <ul className={classModifier('chat-header-action-menu__list', visible && 'visible')} ref={setTooltipRef}>
          {generateSubMenuItems().map((item, idx) => (
            <li key={idx} className="chat-header-action-menu__list-item">
              <button
                title={item.title}
                disabled={item.disabled}
                className="chat-header-action-menu__btn"
                onMouseOver={item.onMouseOver || null}
                onClick={(e) => handleItemClick(e, item)}
              >
                {item.content}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ChatHeaderActionMenu;
