import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { connect } from 'react-redux';

import { selectUserTimezone } from 'redux/selectors/selectors';
import { convertTime12To24, getDateByTimezoneOffset } from 'utils';
import './TimeSelect.scss';
import { useDeepEffect, usePrevious } from 'hooks';


const TimeSelect = props => {
  const {
    timeIntervals = 15, // in minutes
    minTime,
    currentOption,
    onChange,
    userHour12,
    userTimezone,
    initDate,
    ignoreTimezone,
  } = props;

  const [timeOptions, setTimeOptions] = useState([]);
  const [timeMeridiem, setTimeMeridiem] = useState(TIME12MERIDIEM[0]);
  const prevTimeMeridiem = usePrevious(timeMeridiem);

  useEffect(() => {
    if(userHour12 && currentOption) {
      const amPm = currentOption.meridiem;

      if(amPm && timeMeridiem.value !== amPm) {
        setTimeMeridiem({ value: amPm, label: amPm });
      }
    }
  }, [userHour12, currentOption]);

  useDeepEffect(() => {
    const updatedTimeOptions = [];
    const oneDay = 60 * 24;
    let minutes = 0;

    while (minutes < oneDay) {
      const mins = minutes % 60;
      const hours = Math.floor(minutes / 60);

      const [time24, time12, amPm] = getTimeWithTwoFormats(hours, mins);

      const isCurrentTimeMoreThanMinTime = isSecondTimeMoreThanFirst(minTime || ':', time24);
      const canAddTime = !minTime || minTime === time24 || (isCurrentTimeMoreThanMinTime && amPm === timeMeridiem.value);

      if (canAddTime) {
        updatedTimeOptions.push({ value: time24, label: userHour12 ? time12 : time24 });
      }
      minutes += timeIntervals;
    }

    // Set current time by default
    if (!currentOption) {
      const date = ignoreTimezone ? new Date(initDate) : getDateByTimezoneOffset(userTimezone, initDate);

      const minutesToDate = date.getHours() * 60 + date.getMinutes();
      // const minutesToNextPoint = Math.ceil(minutesToDate / timeIntervals) * timeIntervals;
      const minutesToNextPoint = minutesToDate;

      const [time24, time12, amPm] = getTimeWithTwoFormats(Math.floor(minutesToNextPoint / 60), minutesToNextPoint % 60);
      const minTimeStr = minTime || '00:00';

      setTimeMeridiem({ value: amPm, label: amPm });

      if (time24 >= minTimeStr && time24 <= '23:59') {

        // if (userHour12) { setTimeMeridiem({ value: amPm, label: amPm }) };

        onChange({ value: time24, label: userHour12 ? time12 : time24 });
      } else if (updatedTimeOptions.length) {
        const newIndex = time24 < minTimeStr ? 0 : updatedTimeOptions.length - 1;
        const newOption = updatedTimeOptions[newIndex];
        onChange(newOption);
      }
    }

    if (currentOption && minTime) {
      const formattedCurentOptionValue = convertTime12To24(currentOption.label + " " + timeMeridiem.value);
  
      if (isSecondTimeMoreThanFirst(formattedCurentOptionValue, minTime)) {
        onChange(updatedTimeOptions[0]);
      }
    }

    if (prevTimeMeridiem && timeMeridiem.value !== prevTimeMeridiem.value && !initDate) {
      onChange(updatedTimeOptions[0]);
    }

    setTimeOptions(updatedTimeOptions);
  }, [minTime, currentOption, userHour12, timeMeridiem]);

  const onChangeTimeMeridiem = option => {
    const timeByMeridiem = convertTime12To24(`${currentOption.label} ${option.value}`);
    const fullOption = { ...currentOption, value: timeByMeridiem };
    delete fullOption.__isNew__;

    setTimeMeridiem(option);
    // onChange(fullOption);
  };

  const onMenuOpen = () => {
    // scrolling options list to the selected item when menu is open

    setTimeout(() => {
      const selectedItem = document.getElementsByClassName("react-select__option--is-selected")[0]
        || Array.from(document.getElementsByClassName("react-select__option"))
          .find((option, idx, arr) => {
            const isScrollToCenter = arr[idx - 1]?.textContent < currentOption.label && 
              currentOption.label < arr[idx + 1]?.textContent || (!arr[idx + 1]?.textContent && currentOption.label < '23:59');
            return option.textContent === currentOption.label || isScrollToCenter;
          });

      if(selectedItem) {
        // сreating a polyfill if current browser doesn`t support scrollIntoViewIfNeeded method

        if(!Element.prototype.scrollIntoViewIfNeeded) {
          Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded) {
            centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded;
        
            let parent = this.parentNode,
                parentComputedStyle = window.getComputedStyle(parent, null),
                parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width')),
                parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width')),
                overTop = this.offsetTop - parent.offsetTop < parent.scrollTop,
                overBottom = (this.offsetTop - parent.offsetTop + this.clientHeight - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight),
                overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft,
                overRight = (this.offsetLeft - parent.offsetLeft + this.clientWidth - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth),
                alignWithTop = overTop && !overBottom;
        
            if ((overTop || overBottom) && centerIfNeeded) {
              parent.scrollTop = this.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + this.clientHeight / 2;
            }
        
            if ((overLeft || overRight) && centerIfNeeded) {
              parent.scrollLeft = this.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + this.clientWidth / 2;
            }
        
            if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
              this.scrollIntoView(alignWithTop);
            }
          };
        };
        selectedItem.scrollIntoViewIfNeeded({ block: 'end', inline: 'nearest' });
      }
    }, 15);
  };

  return (
    <div className='time-select'>
      <CreatableSelect
        isSearchable
        className="react-select"
        classNamePrefix="react-select"
        options={timeOptions}
        onMenuOpen={onMenuOpen}
        value={timeOptions.length ? currentOption : null}
        onChange={(option) => onChange(option, timeMeridiem.value)}
        placeholder='Select'
        noOptionsMessage={() => null}
        components={{
          IndicatorSeparator: null,
        }}
        isDisabled={!timeOptions.length}
      />

      {userHour12 &&
        <Select 
          isSearchable={false}
          className="react-select"
          classNamePrefix="react-select"
          value={timeMeridiem}
          options={TIME12MERIDIEM}
          onChange={onChangeTimeMeridiem}
          placeholder='Select'
          noOptionsMessage={() => null}
          components={{
            IndicatorSeparator: null,
          }}
        />
      }
    </div>
  );
};

const getTimeWithTwoFormats = (hours, mins) => {
  const amPm = hours < 12 ? "AM" : "PM";

  let amPmHour = hours > 12 ? hours - 12 : hours;
  if (amPmHour == 0) {
    amPmHour = 12;
  }

  mins = String(mins).padStart(2, '0');
  hours = String(hours).padStart(2, '0');
  amPmHour = String(amPmHour).padStart(2, '0');

  const time24 = hours + ':' + mins;
  // const time12 = amPmHour + ':' + mins + amPm;
  const time12 = amPmHour + ':' + mins;

  return [time24, time12, amPm];
}

const isSecondTimeMoreThanFirst = (firstTime, secondTime) => {
  const [firstHours, firstMinutes] = firstTime.split(':').map(str => +str);
  const [secondHours, secondMinutes] = secondTime.split(':').map(str => +str);

  if (secondHours < firstHours) {
    return false;
  }
  if (secondHours > firstHours) {
    return true;
  }

  return secondMinutes > firstMinutes;
};

const mapStateToProps = state => ({
  userHour12: state.user.hour12,
  userTimezone: selectUserTimezone(state),
});

export default connect(mapStateToProps)(TimeSelect);

const TIME12MERIDIEM = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' }
];
