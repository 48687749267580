import GoogleMapReact from 'google-map-react';

import { GOOGLE_MAPS_API_KEY, MAP_LIBRARIES, PREVIEW_MAP_DEFAULT_OPTIONS } from 'config/constants';
import { useJsApiLoader } from '@react-google-maps/api';

import Marker from 'components/UI/Marker/Marker';


const LocationPreview = ({ location }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: MAP_LIBRARIES
  });

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, language: 'en' }}
      yesIWantToUseGoogleMapApiInternals
      shouldUnregisterMapOnUnmount={false}
      options={PREVIEW_MAP_DEFAULT_OPTIONS}
      defaultZoom={13}
      defaultCenter={{ lat: Number(location.lat), lng: Number(location.lng) }}
    >
      <Marker
        className="client-marker"
        lat={location.lat || location.latitude}
        lng={location.lng || location.longitude}
        isSmall
      />
    </GoogleMapReact>
  )
}

export default LocationPreview;
