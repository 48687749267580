import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';

import classNames from 'classnames';
import { selectContactById } from 'redux/selectors/selectors';
import { classModifier, getContactAvatar } from 'utils';
import { updateActiveContact } from 'redux/ducks/clientChats';
import setDeviderBetweenJSX from 'utils/setDeviderBetweenJSX';
import { CONTACT_TYPES } from 'config/constants';
import API from 'api/api';
import { useSessionState } from 'hooks/useSessionState';
import { getNotificationProps } from 'utils/getNotiifcationProps';
import { showSessionFromHistory, updateActiveSession } from 'redux/ducks/sessions';
import { LoadingContactsPengingContext } from 'containers/Chat/PublicChat/PublicChatNotificationsList/PublicChatNotificationsList';

import './Caller.scss';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import { NOTIFICATION_TYPES } from 'redux/ducks/notifications';


export const Caller = {};

Caller.Client = {};
Caller.Girl = {};

const withClickAction = (WrapperComponent) => {
  return ({ notification, callerId, ...props }) => {
    const sessionId = getNotificationProps(notification, 'sessionId');
    const caller = useSelector((state) => selectContactById(state, callerId));
    const isSalesPageOpen = useSelector((state) => state.user.isSalesPageOpen);
    const sessionState = useSessionState(sessionId);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const isSalesPage = location.pathname === '/sales';

    const openChat = () => {
      if (isSalesPage) {
        navigate('/');
      }

      dispatch(updateActiveContact(caller));
    }

    const openSession = () => {
      if (sessionState.status === 'not-available') return;

      if (sessionState.status === 'available') {
        // if (sessionState.isSessonActive) return;

        API.switchSalesSession(sessionId);
        dispatch(updateActiveSession(sessionId));
      } else {
        API.switchSalesSession(sessionState.session.id, true);
        dispatch(showSessionFromHistory(sessionState.session.id));
      }

      if (!isSalesPageOpen) {
        window.open('/sales', '_blank');
      } else {
        API.switchSalesSessionByCaller(callerId)
      }

      // if (extraActionAfterOpen) {
      //   extraActionAfterOpen();
      // }
    }

    const handleClick = () => {
      if (sessionId) {
        // openSession();
      }

      openChat();
    }

    return (
      <WrapperComponent
        caller={caller}
        callerId={callerId}
        onClick={handleClick}
        {...props}
      />
    )
  }
}

const Avatar = ({ caller, onClick, className }) => {
  const avatarSrc = getContactAvatar(caller);

  return (
    <LazyLoadImage
      className={classNames("caller__avatar", className)}
      src={avatarSrc}
      onClick={onClick}
      alt="contact_avatar"
    />
  )
}

const Name = ({ caller, callerId, onClick, callerType, className }) => {
  const { pendingCallerIds } = useContext(LoadingContactsPengingContext);
  const name = caller?.short_name || caller?.fn;

  const classNameModifiers = {
    [CONTACT_TYPES.CLIENT]: 'client',
    [CONTACT_TYPES.GIRL]: 'girl',
    [CONTACT_TYPES.OPERATOR]: 'operator',
    [CONTACT_TYPES.AGENT]: 'agent',
    [CONTACT_TYPES.SERVICES]: 'services',
    [CONTACT_TYPES.RECEPTION]: 'reception',
    [CONTACT_TYPES.NETWORK_PROVIDER]: 'network-provider',
  }

  const classes = classNames(
    classModifier('caller__name', callerType ? classNameModifiers[callerType] : 'client'),
    className,
  )

  return (
    <Tooltip text={name} visibleWhenIsOverflowed>
      <mark className={classes} onClick={onClick}>
        {name || (pendingCallerIds[callerId] ? '[Loading...]' : '[DELETED CONTACT]')}
      </mark>
    </Tooltip>
  )
};

const NameWithClickAction = withClickAction(Name);
const AvatarWithClickAction = withClickAction(Avatar);

const Names = ({ notification, className, callerType }) => {
  const callersIds = getNotificationProps(notification, 'callerId', callerType);
  const actualCallerType = getNotificationProps(notification, 'callerType');

  if (!callersIds[0] || (callerType && actualCallerType && callerType !== actualCallerType)) {
    return '[NOT FOUND]';
  }

  const jsx = callersIds.map((callerId, index) => (
    <NameWithClickAction
      key={index}
      className={className}
      callerId={callerId}
      notification={notification}
      callerType={actualCallerType}
    />
  ));

  return setDeviderBetweenJSX(jsx, ', ');
}

const Avatars = ({ notification, callerType, ...props }) => {
  const callerId = getNotificationProps(notification, 'callerId', callerType)[0];

  return (
    <AvatarWithClickAction
      notification={notification}
      callerId={callerId}
      {...props}
    />
  )
}

Caller.Client.Name = (props) => <Names callerType={CONTACT_TYPES.CLIENT} {...props} />;
Caller.Girl.Name = (props) => <Names callerType={CONTACT_TYPES.GIRL} {...props} />;
Caller.Name = (props) => <Names {...props} />;

Caller.Client.Avatar = (props) => <Avatars {...props} callerType={CONTACT_TYPES.CLIENT} />;
Caller.Girl.Avatar = (props) => <Avatars {...props} callerType={CONTACT_TYPES.GIRL} />;
Caller.Avatar = (props) => <Avatars {...props} />;
