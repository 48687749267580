import React from "react";

import { IMAGE_FORMAT_REGEX, VIDEO_FORMAT_REGEX } from "config/constants";

import "./MailAttachmentFiles.scss";
import LazyLoadImage from "components/LazyLoadImage/LazyLoadImage";
import VideoThumbnail from "components/VideoThumbnail/VideoThumbnail";
import ICONS from "assets/icons";
import { classModifier } from "utils";
import logo from "assets/images/logo-commd-110.png";
import DownloadButton from "components/DownloadButton/DownloadButton";

const MailAttachmentFiles = ({
  fileName,
  fileExtension,
  url,
  onClick,
  counter,
}) => {
  return (
    <li className="mail-attachment-files">
      <div className="mail-attachment-files__counter">{counter}.</div>
      <div className="mail-attachment-files__info">
        <div className="mail-attachment-files__content">
          {IMAGE_FORMAT_REGEX.test(fileExtension) ? (
            <div onClick={onClick}>
              <LazyLoadImage
                src={url}
                data-img-src={url}
                data-attach-img
                className="mail-attachment-files__preview"
                alt="msg img"
                spinnerSize={20}
              />
              <span
                className={classModifier("mail-attachment-files__banner-icon", [
                  !IMAGE_FORMAT_REGEX.test(fileExtension) &&
                    !VIDEO_FORMAT_REGEX.test(fileExtension) &&
                    "default",
                ])}
              >
                <ICONS.eye />
              </span>
            </div>
          ) : VIDEO_FORMAT_REGEX.test(fileExtension) ? (
            <VideoThumbnail
              spinnerSize={40}
              src={url}
              hideDuration
              className="mail-attachment-files__preview"
              onClick={onClick}
            />
          ) : (
            <img
              className="mail-attachment-files__preview mail-attachment-files__banner-icon--default"
              src={logo}
              alt="CommD Logo"
            />
          )}
        </div>
        <div className="mail-attachment-files__text">
          <DownloadButton
            className="mail-attachment-files__filename"
            urlsList={[url]}
            fileName={fileName}
          >
            {fileName}
          </DownloadButton>
        </div>
      </div>
    </li>
  );
};

export default MailAttachmentFiles;
