import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';
import { ROUTES } from 'config/constants';
import { LS, classModifier, getContactAvatar } from 'utils';
import { ADMIN_PAGE_ROOT } from 'services/axiosInstance';

import './UserMenu.scss';
import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Spinner from 'components/UI/Spinner/Spinner';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import TimezoneClock from './components/TimezoneClock';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

export const isAllObjValuesTrue = (obj) => {
  return Object.values(obj).every(value => value && typeof value === 'object'
    ? isAllObjValuesTrue(value)
    : !!value === true
  );
};

const UserMenu = ({ user, ...props }) => {
  const [pending, setPending] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const navigate = useNavigate();

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip(
    {
      trigger: 'click',
      visible: isMenuActive,
      onVisibleChange: setIsMenuActive
    }, 
    {
      modifiers: [
        { name: 'offset', options: { offset: [0, 10] } },
        { name: 'preventOverflow', options: { padding: 10 } },
      ]
    }
  );

  // useEffect(() => {
  //   if (user.mode) {
  //     setIsFullMode(isAllObjValuesTrue(user.mode))
  //   }
  // }, [user.mode]);

  const renderAwayButton = () => {
    if (pending) {
      return <Spinner spinnerSize={10} />
    }
    else {
      switch (user.status) {
        case "online":
          return 'Away';
        case "away":
          return 'Online';
        default:
          return 'Busy';
      }
    }
  };

  const changeProfileStatus = () => {
    if (user.status === 'busy') {
      return;
    }

    if (user.status === 'online') {
      setPending(true);

      return API.changeBusyStatus('away')
        .then(() => setPending(false))
        .catch(console.error)
    }

    setPending(true);
    return API.changeBusyStatus('online')
      .then(() => setPending(false))
      .catch(console.error)
  };

  const logoutUser = () => {
    props.setPagePending(true);

    LS.setItem("adrBookContactType", 0, localStorage.currentUserId);

    return API.logoutUser()
      .catch(console.dir)
      .finally(() => {

        delete localStorage.isLoggedIn;
        delete localStorage.currentUserId;

        sessionStorage.setItem('lastInteractionPage', window.location.pathname);

        navigate(ROUTES.login);
      })
  };

  const generateSubMenuItems = () => ([
    {
      content: renderAwayButton(),
      notCloseOnClick: true,
      action: changeProfileStatus,
    },
    {
      content: 'Settings',
      action: () => props.openModal(MODAL_TYPES.userSettings),
    },
    {
      content: 'Admin',
      action: () => window.location.href = ADMIN_PAGE_ROOT,
    },
    {
      content: 'Logout',
      action: logoutUser,
    },
  ]);

  return (
    <div
      className="user-menu"
      ref={setTriggerRef}
    >
      <div className="user-menu__img-wrap">
        <LazyLoadImage
          src={getContactAvatar(user)}
          className="user-menu__img"
        />

        <span className={classModifier('user-menu__status', [user.status && `${user.status}`])}></span>
      </div>

      <div className="user-menu__info">
        <span className="user-menu__name">{user.username}</span>

        <TimezoneClock />

        <span className='user-menu__city'>{user.timezone.name.slice(user.timezone.name.indexOf('/') + 1)}</span>
      </div>

      <ICONS.chevron
        title="settings" 
        className={classModifier('user-menu__drop-menu-icon', isMenuActive && 'rotated')}
      />

      {visible && 
        <div ref={setTooltipRef} {...getTooltipProps({ style: { zIndex: 100 } })}>
          <DropdownMenu
            items={generateSubMenuItems()}
            closeDropdownMenu={() => setIsMenuActive(false)}
          />
        </div>
      }
    </div>
  );
};


const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
