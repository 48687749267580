import { CONTACT_TYPES } from 'config/constants';
import { getContactsById } from './contacts';
import { getActiveDivaGirls } from './divaGirls';

export const DEFAULT_ZOOM = 14;

export const MAP_PRIMARY_MODES = {
  ALL_ESCORTS: 'all-escorts',
  CHAT_ESCORTS: 'chat-escorts',
  NAVIGATION: 'navigation',
  TARGET_ESCORT: 'target-escort',
}

export const MAP_SECONDARY_MODES = {
  HOST_FOR_SIMILAR_ESCORT: 'host-for-similar-escort',
  TARGET_ESCORT: 'target-escort',
  SELECT_ESCORTS: 'select-escorts',
  RADIUS_ESCORTS: 'radius-escorts',
}

export const MAP_GIRL_FILTERS = {
  CHAT_ESCORTS: 'chat-escorts',
  FINDED_ESCORT: 'finded-escort',
  AVAILABLE_ESCORTS: 'available-escorts',
  BUFFERED_ESCORTS: 'buffered-escorts',
}

export const CLIENT_INITIAL_OBJECT = {
  longitude: null,
  latitude: null,
  radius: { value: 0 },
};
export const CENTER_COORDINATES = { lat: 51.50208118987632, lng: -0.16862733974063682 };

const UPDATE_ZOOM = 'UPDATE_ZOOM';
const UPDATE_CENTER = 'UPDATE_CENTER';
const UPDATE_GIRLS_COORDINATES_DATA = 'UPDATE_GIRLS_COORDINATES_DATA';
const UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION = 'UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION';
const UPDATE_CLIENT_DATA = 'UPDATE_CLIENT_DATA';
const UPDATE_PRIMARY_MODE = 'UPDATE_PRIMARY_MODE';
const UPDATE_MODE_PROPS = 'UPDATE_MODE_PROPS';
const UPDATE_SECONDARY_MODE = 'UPDATE_SECONDARY_MODE';
const UPDATE_TARGET_COORDINATES_WITH_CURRENT_COORDINATES = 'UPDATE_TARGET_COORDINATES_WITH_CURRENT_COORDINATES';
const RESET_SECONDARY_MODE = 'RESET_SECONDARY_MODE';
const UPDATE_SECONDARY_MODE_IDS = 'UPDATE_SECONDARY_MODE_IDS';
const ADD_SECONDARY_MODE_ID = 'ADD_SECONDARY_MODE_ID';
const REMOVE_SECONDARY_MODE_ID = 'REMOVE_SECONDARY_MODE_ID';
const UPDATE_PRIMARY_MODE_PROPS = 'UPDATE_PRIMARY_MODE_PROPS';
const UPDATE_MAP_LOCATION = 'UPDATE_MAP_LOCATION';
const UPDATE_CLIENT_LOCATION = 'UPDATE_CLIENT_LOCATION';
const UPDATE_FILTER = 'UPDATE_FILTER';
const UPDATE_FILTERS = 'UPDATE_FILTERS';
const UPDATE_FILTER_GIRL_IDS = 'UPDATE_FILTER_GIRL_IDS;'
const UPDATE_PRIMARY_MODE_GIRL_IDS = 'UPDATE_PRIMARY_MODE_GIRL_IDS';
const UPDATE_PENDING = 'UPDATE_PENDING';
const UPDATE_MAP_STATE = 'UPDATE_MAP_STATE';
const UPDATE_SUBWAY_LINES = 'UPDATE_SUBWAY_LINES';
const RESET_MAP_STATE = 'RESET_MAP_STATE';
const RESET_MAP_LOCATION = 'RESET_MAP_LOCATION';
const RESET_COORDINATES_ON_TARGET = 'RESET_COORDINATES_ON_TARGET';
const RESET_MAP_FILTERS = 'RESET_MAP_FILTERS';

export const updateZoom = (zoom) => ({
  type: UPDATE_ZOOM,
  payload: { zoom },
});

export const updateCenter = (center) => ({
  type: UPDATE_CENTER,
  payload: { center },
});

export const updateGirlsCoordinatesData = (data) => ({
  type: UPDATE_GIRLS_COORDINATES_DATA,
  payload: {
    girls: data.data,
    radiusProfiles: data.radiusProfiles,
  }
})

export const updateGirlCoordinateWithProfile = (profileIds, contactUids) => (dispatch) => {
  const config = {
    params: { ['filter-ids']: profileIds, limit: 999 },
    quickBooking: true,
    isGetFromState: true,
  }

  dispatch(getActiveDivaGirls(config))
    .then(({ girls }) => {
      if (!girls.length) return;

      dispatch({
        type: UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION,
        payload: {
          ids: Array.isArray(contactUids) ? contactUids : [contactUids],
          data: girls.map(girl => ({ 'profile': girl })),
        }
      })
    })
}

export const updateGirlCoordinateWithContact = (callerIds, contactUids) => (dispatch) => {
  dispatch(getContactsById(callerIds, CONTACT_TYPES.GIRL, false, false, true))
    .then((contacts) => {
      if (!contacts) return;

      dispatch({
        type: UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION,
        payload: {
          ids: Array.isArray(contactUids) ? contactUids : [contactUids],
          data: [contacts].flat().map(contact => ({ contact }))
        }
      })
    })
}

export const updateGirlCoordinateWithExtraInformation = (id, data) => ({
  type: UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION,
  payload: { id, data },
})

export const updateClientCoordinates = (place) => ({
  type: UPDATE_CLIENT_DATA,
  payload: {
    longitude: place.lng instanceof Function ? place.lng() : Number(place.lng),
    latitude: place.lat instanceof Function ? place.lat() : Number(place.lat),
  },
})

export const updateClientRadius = (radius) => ({
  type: UPDATE_CLIENT_DATA,
  payload: { radius },
})

export const resetClientData = () => ({
  type: UPDATE_CLIENT_DATA,
  payload: CLIENT_INITIAL_OBJECT,
})

export const updatePrimaryMode = (mode) => ({
  type: UPDATE_PRIMARY_MODE,
  payload: { mode },
})

export const updateModeProps = (mode, props) => ({
  type: UPDATE_MODE_PROPS,
  payload: { [mode]: props },
})

export const updateSecondaryMode = (mode, value) => ({
  type: UPDATE_SECONDARY_MODE,
  payload: { mode, value },
})

export const resetSecondaryMode = () => ({ type: RESET_SECONDARY_MODE })

export const updateSecondaryModeIds = (mode, ids, shouldUpdateByProfileAndContacts) => (dispatch) => {
  const caller_ids = ids instanceof Object ? ids.map(({ caller_id }) => caller_id) : ids;
  const profile_ids = ids instanceof Object ? ids.map(({ profile_id }) => profile_id) : ids;
  const contact_uids = ids instanceof Object ? ids.map(({ contact_uid }) => contact_uid) : ids;

  if (shouldUpdateByProfileAndContacts) {
    dispatch(updateGirlCoordinateWithProfile(profile_ids, contact_uids));
    caller_ids.length && dispatch(updateGirlCoordinateWithContact(caller_ids, contact_uids));
  }

  dispatch({
    type: UPDATE_SECONDARY_MODE_IDS,
    payload: { mode, ids: contact_uids },
  })
}

export const updateSecondaryModeId = (mode, ids) => (dispatch) => {
  const { caller_id, profile_id, contact_uid } = ids instanceof Object ? ids : { contact_uid: ids };

  profile_id && dispatch(updateGirlCoordinateWithProfile(profile_id, contact_uid));
  caller_id && dispatch(updateGirlCoordinateWithContact(caller_id, contact_uid));

  dispatch({
    type: UPDATE_SECONDARY_MODE_IDS,
    payload: { mode, ids: contact_uid },
  })
}

export const addSecondaryModeId = (mode, ids) => (dispatch) => {
  const { caller_id, profile_id, contact_uid } = ids instanceof Object ? ids : { contact_uid: ids };

  profile_id && dispatch(updateGirlCoordinateWithProfile(profile_id, contact_uid));
  caller_id && dispatch(updateGirlCoordinateWithContact(profile_id, caller_id, contact_uid));

  dispatch({
    type: ADD_SECONDARY_MODE_ID,
    payload: { mode, ids: contact_uid },
  })
}

export const addSecondaryModeIds = (mode, ids) => (dispatch) => {
  const caller_ids = ids instanceof Object ? ids.map(({ caller_id }) => caller_id) : ids;
  const profile_ids = ids instanceof Object ? ids.map(({ profile_id }) => profile_id) : ids;
  const contact_uids = ids instanceof Object ? ids.map(({ contact_uid }) => contact_uid) : ids;

  profile_ids.length && dispatch(updateGirlCoordinateWithProfile(profile_ids, contact_uids));
  caller_ids.length && dispatch(updateGirlCoordinateWithContact(caller_ids, contact_uids));

  dispatch({
    type: ADD_SECONDARY_MODE_ID,
    payload: { mode, ids: contact_uids },
  })
}

export const removeSecondaryModeId = (mode, { contact_uid }) => ({
  type: REMOVE_SECONDARY_MODE_ID,
  payload: { mode, id: contact_uid },
});

export const updateClientLocation = (location) => ({
  type: UPDATE_CLIENT_LOCATION,
  payload: { location },
})

export const updateMapLocation = (name, value) => ({
  type: UPDATE_MAP_LOCATION,
  payload: { [name]: value },
})

export const resetClientLocation = () => ({
  type: UPDATE_CLIENT_LOCATION,
  payload: { location: '' },
})

export const resetMapLocation = () => ({ type: RESET_MAP_LOCATION })

export const updateFilter = (filter, value) => ({
  type: UPDATE_FILTER,
  payload: { filter, value },
})

export const updateFilters = (filter, selectOptions) => ({
  type: UPDATE_FILTERS,
  payload: { filter, selectOptions },
})

export const updateActiveGirlIds = (ids) => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.CHAT_ESCORTS]: ids }
})

export const updateFindedGirlId = (id) => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.FINDED_ESCORT]: id }
})

export const updateBufferedGirlIds = (ids) => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.BUFFERED_ESCORTS]: ids }
})

export const updateSelectedGirlsIds = (ids = []) => ({
  type: UPDATE_SECONDARY_MODE_IDS,
  payload: { mode: MAP_SECONDARY_MODES.SELECT_ESCORTS, ids }
})

export const updateRadiusGirlsIds = (ids = []) => ({
  type: UPDATE_PRIMARY_MODE_PROPS,
  payload: { radiusGirlsIds: ids }
})

export const updatePending = (pending) => ({
  type: UPDATE_PENDING,
  payload: { pending },
});

export const updateMapUpdatedState = () => ({ type: UPDATE_MAP_STATE });

export const updateSubwayLines = (value) => ({
  type: UPDATE_SUBWAY_LINES,
  payload: { subwayLines: value }
})

export const updateMapState = () => ({ type: UPDATE_MAP_STATE });

export const resetFindedGirlId = () => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.FINDED_ESCORT]: null }
});

export const resetCoordinatesOnTarget = () => ({ type: RESET_COORDINATES_ON_TARGET });

export const resetMapState = () => ({ type: RESET_MAP_STATE })

export const resetMapFilters = () => ({ type: RESET_MAP_FILTERS })

const initialState = {
  zoom: DEFAULT_ZOOM,
  center: CENTER_COORDINATES,
  girlsData: {},
  clientData: CLIENT_INITIAL_OBJECT,
  clientLocation: '',
  primaryMode: null,
  prevPrimaryMode: null,
  secondaryMode: {},
  girlIdsBySecondaryMode: {},
  location: {
    country: null,
    city: null,
    area: null,
  },
  filters: {},
  filteredGirlIdsByFilters: {},
  subwayLines: true,
  updated: false,
  pending: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_MAP_STATE:
      return {
        ...state,
        updated: true,
      }

    case UPDATE_ZOOM:
      return {
        ...state,
        zoom: payload.zoom,
      }

    case UPDATE_CENTER:
      return {
        ...state,
        center: payload.center,
      }
    case UPDATE_GIRLS_COORDINATES_DATA:
      const { girls, radiusProfiles } = payload;
      // const savedProfileIds = Object.values(state.girlsData).map(data => data.girl?.contact_uid);
      const convertedGirls = Array.isArray(girls) ? girls : Object.values(girls);
      const convertedRadiusProfiles = Array.isArray(radiusProfiles) ? radiusProfiles : Object.values(radiusProfiles || {});
      const targetGirlId = state.girlIdsBySecondaryMode[MAP_SECONDARY_MODES.TARGET_ESCORT];
      const findedGirlId = state.filteredGirlIdsByFilters[MAP_GIRL_FILTERS.FINDED_ESCORT];

      const groupedCoords = {};

      convertedGirls.sort((prevGirl, nextGirl) => {
        return nextGirl.id - prevGirl.id
      });

      convertedGirls.forEach((coords) => {
        const key = `${Number(coords.latitude).toFixed(5)},${Number(coords.longitude).toFixed(5)}`;

        if (!groupedCoords[key]) {
          groupedCoords[key] = [];
        }

        groupedCoords[key].push(coords);
      })

      Object.values(groupedCoords).forEach((group) => {
        if (group.length > 1) {
          group = group.filter(coord => ![targetGirlId, findedGirlId].includes(coord.contact_uid));

          const angleStep = (2 * Math.PI) / group.length;
          const coefficient = group.length > 10 ? group.length - 10 : 0;
          const radius = 0.00004 + 0.00001 * coefficient;

          group.forEach((coord, index) => {
            const angle = index * angleStep;
            coord.latitude = parseFloat(coord.latitude) + radius * Math.cos(angle);
            coord.longitude = parseFloat(coord.longitude) + radius * Math.sin(angle);
          })
        }
      });

      const newUpdateGirlsData = {};
      const availableGirlIds = [...state.filteredGirlIdsByFilters[MAP_GIRL_FILTERS.AVAILABLE_ESCORTS] || []];

      convertedGirls.forEach(girl => {
        if (girl.available_status) {
          availableGirlIds.push(girl.contact_uid);
        }

        const convertedGirl = {
          ...girl,
          latitude: Number(girl.latitude),
          longitude: Number(girl.longitude),
        }

        newUpdateGirlsData[girl.contact_uid] = { ...state.girlsData[girl.contact_uid], girl: convertedGirl };
      });

      convertedRadiusProfiles?.forEach(profile => {
        newUpdateGirlsData[profile.contact_uid] = { ...state.girlsData[profile.contact_uid], profile };
      });

      return {
        ...state,
        girlsData: { ...state.girlsData, ...newUpdateGirlsData },
        filteredGirlIdsByFilters: {
          ...state.filteredGirlIdsByFilters,
          [MAP_GIRL_FILTERS.AVAILABLE_ESCORTS]: availableGirlIds,
        },
      }
    case UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION:
      const { ids, id, data } = payload;
      let newData;
      if (ids) {
        newData = ids.map((id, index) => {
          return [id, { ...state.girlsData[id], ...data[index] }];
        })
      } else {
        newData = [[id, { ...state.girlsData[id], ...data }]]
      }

      return {
        ...state,
        girlsData: {
          ...state.girlsData,
          ...Object.fromEntries(newData),
        },
      }

    case UPDATE_CLIENT_DATA:
      return {
        ...state,
        clientData: {
          ...state.clientData,
          ...payload,
        },
      }

    case UPDATE_PRIMARY_MODE: {
      return {
        ...state,
        primaryMode: payload.mode,
        prevPrimaryMode: state.primaryMode,
      }
    }

    case UPDATE_MODE_PROPS: {
      return {
        ...state,
        modeProps: {
          ...state.modeProps,
          ...payload,
        }
      }
    }

    case UPDATE_SECONDARY_MODE: {
      const { mode, value } = payload;

      return {
        ...state,
        secondaryMode: {
          ...state.secondaryMode,
          [mode]: value ?? !state.secondaryMode[mode],
        },
      }
    }

    case UPDATE_MAP_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          ...payload,
        }
      }

    case UPDATE_SUBWAY_LINES:
      return {
        ...state,
        subwayLines: payload.subwayLines,
      }

    case RESET_SECONDARY_MODE: {
      return {
        ...state,
        secondaryMode: {},
      }
    }

    case RESET_MAP_LOCATION:
      return {
        ...state,
        location: initialState.location
      }

    case UPDATE_SECONDARY_MODE_IDS: {
      const { mode, ids } = payload;

      return {
        ...state,
        girlIdsBySecondaryMode: {
          ...state.girlIdsBySecondaryMode,
          [mode]: ids,
        },
      }
    }

    case UPDATE_TARGET_COORDINATES_WITH_CURRENT_COORDINATES: {
      return {
        ...state,
        modeProps: {
          ...state.modeProps,
          [MAP_SECONDARY_MODES.TARGET_ESCORT]: state.center,
        }
      }
    }

    case ADD_SECONDARY_MODE_ID: {
      const { mode, ids } = payload;

      return {
        ...state,
        girlIdsBySecondaryMode: {
          ...state.girlIdsBySecondaryMode,
          [mode]: [...new Set([...(state.girlIdsBySecondaryMode[mode] || []), ...([ids].flat())])],
        }
      }
    }

    case REMOVE_SECONDARY_MODE_ID: {
      const { mode, id } = payload;

      return {
        ...state,
        girlIdsBySecondaryMode: {
          ...state.girlIdsBySecondaryMode,
          [mode]: state.girlIdsBySecondaryMode[mode].filter(girlId => girlId !== id),
        }
      }
    }

    case UPDATE_PRIMARY_MODE_GIRL_IDS:
      return {
        ...state,
        girlIdsByModes: {
          ...state.girlIdsByModes,
          ...payload,
        },
      }

    case UPDATE_CLIENT_LOCATION:
      return {
        ...state,
        clientLocation: payload.location,
      }

    case UPDATE_FILTERS: {
      const { selectOptions = [] } = payload;

      const newFilters = Object.entries(state.filters).reduce((acc, [key, value]) => {
        if (selectOptions.includes(key)) {
          acc[key] = false;
        } else {
          acc[key] = value;
        }

        return acc;
      }, {});

      return {
        ...state,
        filters: {
          ...newFilters,
          [payload.filter]: !state.filters[payload.filter],
        }
      }
    }

    case UPDATE_FILTER:
      const { filter, value } = payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          [filter]: value || !state.filters[payload.filter],
        }
      }

    case UPDATE_FILTER_GIRL_IDS:
      return {
        ...state,
        filteredGirlIdsByFilters: {
          ...state.filteredGirlIdsByFilters,
          ...payload,
        },
      }

    case UPDATE_PENDING:
      return {
        ...state,
        pending: payload.pending,
      }

    case RESET_COORDINATES_ON_TARGET:
      return {
        ...state,
        center: state.modeProps[MAP_PRIMARY_MODES.TARGET_ESCORT],
      }

    case RESET_MAP_STATE: {
      return initialState;
    }

    case RESET_MAP_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
        filteredGirlIdsByFilters: {
          ...state.filteredGirlIdsByFilters,
          [MAP_GIRL_FILTERS.FINDED_ESCORT]: null,
        }
      }
    }

    default:
      return state;
  }
}

