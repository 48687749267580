import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import store from 'redux/store';

import { useDidMount, usePrevious } from 'hooks';
import { readNotification, addNotifications } from 'redux/ducks/notifications';
import { getContactsById } from 'redux/ducks/contacts';

import './PublicChatNotificationsList.scss';
import List from 'components/List/List';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import { Notification } from 'components/Notification';
import { getNotificationProps } from 'utils/getNotiifcationProps';


export const LoadingContactsPengingContext = React.createContext(null);

const PublicChatNotificationsList = (props) => {
  const {
    notifications,
    addNotifications,
    readNotification,
    savedNotificationsIds,
  } = props;

  const [userNotifications, setUserNotifications] = useState([]);
  const [isPending, setPending] = useState(false);
  const [pendingCallerIds, setPendingCallerIds] = useState([]);

  const previousUserNotificationsLength = usePrevious(userNotifications.length);

  const getNotifications = (offset = 0) => {
    const state = store.getState();

    return addNotifications(offset)
      .then((notifications) => {
        const callerIds = notifications
          .flatMap(notification => getNotificationProps(notification, 'callerId'))
          .filter((id) => Boolean(id) && !state.contacts.entities[id]);
        // const callerIdsWithoutDuplicates = [...new Set(callerIds)];
        
        setPendingCallerIds(callerIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}));

        if (callerIds.length) {
          props.getContactsById(callerIds)
            .finally(() => setPendingCallerIds({}));
        }
      });
  }

  useDidMount(() => {
    setPending(true)

    getNotifications()
      .finally(() => setPending(false));
  });

  useEffect(() => {
    setUserNotifications(savedNotificationsIds.map(id => notifications[id]))
  }, [notifications]);

  const ListItem = useCallback((props) => {
    const readAction = () => {
      return readNotification(props.item.id);
    }

    return (
      <Notification
        readAction={readAction}
        {...props}
      />
    );
  }, []);

  useEffect(() => {
		if (userNotifications.length === 10 && previousUserNotificationsLength === 11) {
      addNotifications(10);
    }
  }, [userNotifications])

  return (
    <LoadingContactsPengingContext.Provider
      value={{ pendingCallerIds, setPendingCallerIds }}
    >
      <div className="user-notifications">
        <div className="user-notifications__header">Important Notifications</div>

        <List
          list={userNotifications}
          limit={20}
          classPrefix='user-notifications'
          scrollInitialPosition='top'
          loadMore={getNotifications}
          listLoadPending={isPending}
          scrollbarProps={{
            renderTrackVertical: CustomScrollbarTrack,
          }}
        >
          <ListItem />
        </List>
      </div>
    </LoadingContactsPengingContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  unreadCount: state.notifications.unreadCount,
  notifications: state.notifications.entities,
  savedNotificationsIds: state.notifications.savedIds,
});

const mapDispatchToProps = {
  readNotification,
  addNotifications,
  getContactsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicChatNotificationsList);
