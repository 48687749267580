import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { GirlsMapModalContext } from '../../GirlsMapModal';
import { MAP_SECONDARY_MODES } from 'redux/ducks/map';
import { selectMapGirlIdsBySecondaryMode } from 'redux/selectors/selectors';

import Marker from 'components/UI/Marker/Marker';


const ClusterMarker = ({ cluster, supercluster, ...restProps }) => {
  const { clientData } = useSelector(state => state.map);
  const selectedGirlsIds = useSelector(state => selectMapGirlIdsBySecondaryMode(state, MAP_SECONDARY_MODES.SELECT_ESCORTS));
  const selectMode = useSelector(state => state.map.secondaryMode[MAP_SECONDARY_MODES.SELECT_ESCORTS]);

  const { flyTo } = useContext(GirlsMapModalContext)

  const clusteredMarkers = supercluster.getLeaves(cluster.id);
  const isClientMarkerClustered = clusteredMarkers.some(({ properties }) => properties.category === 'clients');
  const isClusterSelected = clusteredMarkers.every(({ properties }) => selectedGirlsIds?.includes(properties.contact_uid));

  const { radius, zoom } = clientData;

  const handleClick = () => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      25
    );
    
    flyTo({ lat: restProps.lat, lng: restProps.lng }, expansionZoom);
  }

  if (isClientMarkerClustered) {
    restProps = {
      radius,
      zoom,
      ...restProps
    }
  }

  return (
    <Marker
      className="cluster-marker"
      onClick={handleClick}
      selected={selectMode && isClusterSelected}
      {...restProps}
    >
      {cluster.properties.point_count}
    </Marker>
  );
}

export default ClusterMarker;
