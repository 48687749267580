import classNames from 'classnames';

import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { PROFILE_BOOKING_DATE_CONFIG } from 'config/dates-сonfig';
import { classModifier } from '../../../../utils';

import './BookingGirlItem.scss';

const DETAILS_ITEMS = [
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]


const BookingGirlItem = (props) => {
  const {
    className,
    Client,
    ConfirmationStatus,
    Markers,
    Operator,
    Requirements,
    Date,
    Details,
    isDeleted,
    handleOpenBookingModal,
    isNotClickable,
  } = props;

  const classes = classNames(
    classModifier('booking-girl-item', [
      isDeleted && 'deleted',
      !isNotClickable && 'clickable',
    ]),
    className,
  )

  return (
    <div
      className={classes}
      onClick={() => !isNotClickable && handleOpenBookingModal()}
    >
      <div className="booking-girl-item__row">
        <Client
          className="booking-girl-item__client"
          isAvatarShowed
        />

        <div className="booking-girl-item__graphs">
          <div className="booking-girl-item__graphs-row">
            <Requirements />
            <ConfirmationStatus />
          </div>

          <Markers className="booking-girl-item__markers" />
        </div>
      </div>

      <div className="booking-girl-item__row">
        <Date className="booking-girl-item__date" config={PROFILE_BOOKING_DATE_CONFIG} />
        <Details items={DETAILS_ITEMS} />
        <Operator />
      </div>
    </div>
  )
}

export default BookingGirlItem;

