import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from 'hooks';
import { useContext } from 'react';

import { classModifier } from 'utils';
import { MAP_SECONDARY_MODES, updateCenter, updateZoom } from 'redux/ducks/map';
import ICONS from 'assets/icons';
import { GirlsMapContext } from '../GirlsMap/GirlsMap';

const RecenterButton = ({ clusters }) => {
  const {
    girlsData,
    center: { lat, lng },
    girlIdsBySecondaryMode: {
      [MAP_SECONDARY_MODES.TARGET_ESCORT]: targetGirlId,
    },
  } = useSelector(state => state.map);

  const { isStreetViewVisible } = useContext(GirlsMapContext);

  const dispatch = useDispatch();

  const isTargetMarkerNotVisible = !clusters.find(cluster => cluster.properties.contact_uid === targetGirlId);
  const targetGirl = girlsData[targetGirlId]?.girl;
  const girlsCenter = { lat: targetGirl?.latitude, lng: targetGirl?.longitude }
  const isTargetCoordinatesChanged = (
    Math.abs(lat - targetGirl?.latitude) > 0.00000001
    || Math.abs(lng - targetGirl?.longitude) > 0.00000001
  )

  const flyToTargetMarker = () => {
    dispatch(updateCenter(girlsCenter));

    if (isTargetMarkerNotVisible) {
      dispatch(updateZoom(21));
    }
  }

  useDidMount(flyToTargetMarker);

  const classes = classModifier(
    'girls-map__toggle-btn',
    ['inner-map', 'recenter']
  )

  if (isStreetViewVisible) return null;

  return (
    <button
      className={classes}
      onClick={flyToTargetMarker}
      disabled={!isTargetCoordinatesChanged}
    >
      <ICONS.location className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default RecenterButton;
