import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useDidUpdate } from 'hooks';
import { classModifier } from 'utils';
import { updateAdditionalTitleFilters } from 'redux/ducks/sessions';

import ICONS from 'assets/icons';
import { ADDITIONAL_SESSION_FILTERS } from 'config/constants';

const GalleryHeaderIncludeFilter = (props) => {
  const {
    label,
    filter,
    activeSession,
    canIEditSession,
    additionalFilters,
  } = props;

  const additionalFilter = additionalFilters[filter];
  const initialState = Array.isArray(additionalFilter) ? false : !isNaN(+additionalFilter);
  const isDisabled = filter === ADDITIONAL_SESSION_FILTERS.holidays && isNaN(+additionalFilters[ADDITIONAL_SESSION_FILTERS.includeoff]);

  const [isHideFilter, setIsHideFilter] = useState(initialState);

  const dispatch = useDispatch();

  useDidUpdate(() => {
    setIsHideFilter(initialState);
  }, [activeSession, additionalFilter]);

  useDidUpdate(() => {
    dispatch(updateAdditionalTitleFilters(
      filter,
      !isHideFilter,
      activeSession,
      additionalFilters,
      canIEditSession,
      !isHideFilter
    ));
  }, [isHideFilter])

  return (
    <div
      className={classModifier('gallery-header-include-filter', [
        isHideFilter && 'checked',
        isDisabled && 'disabled',
      ])}
      onClick={() => !isDisabled && setIsHideFilter(prev => !prev)}
    >
      <div className="gallery-header-include-filter__checkbox">
        {isHideFilter && <ICONS.check className="gallery-header-include-filter__icon" />}
      </div>

      <span className="gallery-header-include-filter__label">{label}</span>
    </div>
  );
};

export default GalleryHeaderIncludeFilter;