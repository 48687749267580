import { useDispatch } from 'react-redux';

import ICONS from 'assets/icons';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';

import './HeaderModalButtons.scss';
import HeaderModalBookingBtn from './components/HeaderModalBookingBtn';
import HeaderModalTasksBtn from './components/HeaderModalTasksBtn';
import HeaderModalMapBtn from './components/HeaderModalMapBtn';


const HeaderModalButtons = () => {
  const dispatch = useDispatch();

  return (
    <div className="header-modal-btns">
      <HeaderModalBookingBtn />
      <HeaderModalMapBtn />
      <HeaderModalTasksBtn />

      <button
        className="header-modal-btns__button"
        onClick={() => dispatch(openModal(MODAL_TYPES.multiMessageModal))}
      >
        <ICONS.msgArrows className="header-modal-btns__icon" width="20px" height="20px" />
      </button>

      <button className="header-modal-btns__button">
        <ICONS.robot className="header-modal-btns__icon" width="20px" height="20px" />
      </button>
    </div>
  )
}

export default HeaderModalButtons;
