import React, { useMemo } from 'react';

import CheckBoxFormItem from './components/CheckBoxFormItem/CheckBoxFormItem';

const CheckBoxFormItems = ({ telsValues, emailsValues }) => {

  const selectTelsOptions = useMemo(() => telsValues 
    ? telsValues.map(item => ({
        value: item.uuid,
        valueLabel: item.labelName,
        label: item.tel, 
      }))
    : []
  , [telsValues]);

  const selectEmailsOptions = useMemo(() => emailsValues 
    ? emailsValues?.map(item => ({
        value: item.uuid,
        label: item.email, 
      }))
    : []
  , [emailsValues]);

  return (
    <>
      <CheckBoxFormItem 
        viaTitle="Phone"
        viaFieldName="via.via_phone_uuid"
        selectOptions={selectTelsOptions}
      />

      <CheckBoxFormItem 
        viaTitle="SMS"
        viaFieldName="via.via_sms_phone_uuid"
        selectOptions={selectTelsOptions}
      />

      <CheckBoxFormItem 
        viaTitle="Email"
        viaFieldName="via.via_email_uuid"
        selectOptions={selectEmailsOptions}
      />

      <CheckBoxFormItem 
        viaTitle="Telegram"
        viaFieldName="via.via_telegram"
      />
    </>
)};

export default CheckBoxFormItems;