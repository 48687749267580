import { CONTACTS_LIMIT } from 'config/constants';
import { getActiveDivaGirls } from 'redux/ducks/divaGirls';

export default ({ dispatch, query, offset, cancelToken }) => {
  return dispatch(getActiveDivaGirls({
    params: {
      'filter-is_available_now': query ? 0 : 1,
      'search': query,
      'includeoff': false, // param to get all diva profiles
      cancelToken,
    },
    quickBooking: true,
  }))
    .then(({ girls, activeGirlsFromChats, activeGirl }) => {
      let dataPrepared = [];

      if (query === '') {
        const girlsAvailableNow = girls.filter(
          (girl) => !activeGirlsFromChats.includes(girl.diva_default_id)
        );

        dataPrepared = offset
          ? girlsAvailableNow
          : [...activeGirlsFromChats, ...girlsAvailableNow];

        activeGirl.id && !offset && dataPrepared.unshift(activeGirl);
      } else {
        dataPrepared = girls;
      }

      return ({
        newItems: dataPrepared,
        newHasMore: girls.length === CONTACTS_LIMIT,
      });
    })
    .catch(console.error)
  }
