import { useDispatch, useSelector } from 'react-redux';

import { classModifier } from 'utils';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { selectContactsByIds, selectIsMapStateSaved } from 'redux/selectors/selectors';
import ICONS from 'assets/icons';


const HeaderModalMapBtn = () => {
  const isMapStateSaved = useSelector(selectIsMapStateSaved);
  const activeCallers = useSelector((state) => selectContactsByIds(state, state.girlChats.tabs));

  const dispatch = useDispatch();

  const handleClick = () => {
    const props = { mapProps: { activeCallers } };

    dispatch(openModal(MODAL_TYPES.girlsMap, props));
  }

  return (
    <button
      className="header-modal-btns__button"
      onClick={handleClick}
    >
      <ICONS.map
        className={classModifier("header-modal-btns__icon", isMapStateSaved && 'active' )}
        width="20px"
        height="20px"
      />
    </button>
  )
}

export default HeaderModalMapBtn;
