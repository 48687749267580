import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useBuildNavigation from 'hooks/useBuildNavigation';
import { MAP_SECONDARY_MODES, updateMapState } from 'redux/ducks/map';

import { selectMapGirlBySecondaryMode } from 'redux/selectors/selectors';
import { GirlsMapModalContext } from '../../GirlsMapModal';
import BackToFullMapButton from '../ControlElements/BackToFullMapButton';
import SendNavigationPathButton from '../ControlElements/SendNavigationPathButton';
import SendEscortsButton from '../ControlElements/SendEscortsButton';


const NavigationControls = {};

NavigationControls.Header = () => {
  const { clientData } = useSelector(state => state.map);
  const [navigationGirl] = useSelector(state => selectMapGirlBySecondaryMode(state, MAP_SECONDARY_MODES.TARGET_ESCORT));

  const { map } = useContext(GirlsMapModalContext);

  const {
    initializeNavigation,
    buildNavigation,
    resetNavigation,
  } = useBuildNavigation();

  initializeNavigation(map);

  useEffect(() => {
    const { latitude, longitude } = navigationGirl.girl;

    resetNavigation();
    buildNavigation(clientData, { latitude, longitude });

    return resetNavigation;
  }, [clientData, navigationGirl.girl]);

  return (
    <div className="girls-map__header">
      <div className="girls-map__buttons-wrap">
        <BackToFullMapButton />
        <SendNavigationPathButton />
        <SendEscortsButton />
      </div>
    </div>
  )
}

NavigationControls.Footer = () => <div className="girls-map__footer"></div>;

export default NavigationControls;
