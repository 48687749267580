export default (ringtoneURL, isLooped = false) => {
  if (!ringtoneURL) return null;

  const ringtone = new Audio(ringtoneURL);

  if (isLooped) {
    ringtone.loop = true;
  }

  return ringtone;
};