import React, { memo } from "react";
import { useDispatch } from "react-redux";
import BookButton from "components/BookButton/BookButton";

import API from "api/api";
import { MODAL_TYPES, openModal } from "redux/ducks/activeWindows";
import { DIVA_GIRLS_LINK } from "redux/ducks/divaGirls";
import { classModifier } from "utils";

import AsyncButton from "components/AsyncButton/AsyncButton";
import ICONS from "assets/icons";

const ProfileCardActions = ({ updateComparedIdFromRecentlyViewed, id, id_new, ...props }) => {
  const dispatch = useDispatch();

  const {
    isInBuffer,
    recentlyViewedIds
  } = props;

  const prevProfile = () => updateComparedIdFromRecentlyViewed({ action: "prev", id });

  const nextProfile = () => updateComparedIdFromRecentlyViewed({ action: "next", id });

  const onBuffer = () => {
    if (isInBuffer) {
      props.removeBufferedId(id, props.activeSession, props.canIEditSession);
    }
    else {
      props.updateBufferedId(id, props.activeSession, props.canIEditSession, id_new, props.profileContactUid);
    }
  }

  const handleSendGirlLink = () => {
    const messageObj = { text: `${DIVA_GIRLS_LINK}${props.profileLink}` };

    return API.sendMessage(messageObj, { id: props.activeCallerId })
      .then(() => {
        props.addGirlsIdsThatLinksIsSendInChat(props.activeSession, id);
      })
      .catch(err => {
        console.error(err);

        if (err.response && err.response.data && err.response.data.status === 403) {
          dispatch(openModal(MODAL_TYPES.error, { text: err.response.data.message }));
        }
      });

  }

  const isActive = props.sessionIds.includes(props.activeSession);
  const isBtnSent = props.girlsIdsThatLinksIsSend?.includes(id);
  
  return (
    <div className="profile-card__actions">
     
      <div className="profile-card__actions-btn-wrap">
        {!props.isBufferZone && props.hostProfileId !== id && recentlyViewedIds.length > 2 && <>
          <div
            className="profile-card__actions-btn profile-card__actions-btn--prev"
            onClick={prevProfile}>
          </div>

          <div
            className="profile-card__actions-btn profile-card__actions-btn--next"
            onClick={nextProfile}>
          </div>
        </>}
      </div>

      {!props.isBufferZone &&
        <button
          className={classModifier('profile-card__buffer-btn', isInBuffer && 'remove' )}
          onClick={onBuffer}
          title={isInBuffer ? 'remove from buffer' : 'add to buffer'}
        >
          {isInBuffer
            ? <ICONS.close />
            : <ICONS.buffer width={20} height={20} />  
          }
        </button>
      }

      <div className="profile-card__buttons-wrap">
        {isActive &&
          <AsyncButton
            className={classModifier('profile-card__btn-send-link', [isBtnSent && 'done'])}
            onClick={handleSendGirlLink}
            spinnerSize={20}
            disabled={isBtnSent}
          >
            {!isBtnSent
              ? 'send'
              : (
                <p className="profile-card__btn-send-link-text">
                  sent
                  <span className="profile-card__btn-sent-icon"><ICONS.check /></span>
                </p>
              )
            }
          </AsyncButton>
        }

        {!!props.activeSession &&
          <BookButton
            profileId={id}
            className="booked-button"
          />
        }
      </div>
    </div>
  );
};

export default memo(ProfileCardActions);
