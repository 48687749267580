import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MAP_SECONDARY_MODES, updateSecondaryMode, updateSecondaryModeIds, updateSelectedGirlsIds } from 'redux/ducks/map';

import ICONS from 'assets/icons';


const SelectEscortsButton = () => {
  const selectMode = useSelector(state => state.map.secondaryMode[MAP_SECONDARY_MODES.SELECT_ESCORTS]);
  const selectedGirlIds = useSelector(state => state.map.girlIdsBySecondaryMode[MAP_SECONDARY_MODES.SELECT_ESCORTS]);

  const dispatch = useDispatch();

  const handleSelectionClick = () => dispatch(updateSecondaryMode(MAP_SECONDARY_MODES.SELECT_ESCORTS));
  const handleClearClick = () => dispatch(updateSelectedGirlsIds());

  useEffect(() => {
    if (!selectMode) {
      dispatch(updateSecondaryModeIds(MAP_SECONDARY_MODES.SELECT_ESCORTS, []));
    }
  }, [selectMode]);

  return (
    <>
      {!!selectedGirlIds?.length && (
        <button
          className="girls-map__toggle-btn"
          onClick={handleClearClick}
        >
          Clear Selected
          <ICONS.close className="girls-map__toggle-btn-icon" />
        </button>
      )}

      <button
        className="girls-map__toggle-btn"
        onClick={handleSelectionClick}
      >
        {selectMode ? 'Exit Selection' : 'Select Escorts'}
        <ICONS.check className="girls-map__toggle-btn-icon" />
      </button>
    </>
  )
}

export default SelectEscortsButton;
