import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useSupercluster from 'use-supercluster';
import GoogleMapReact from 'google-map-react';

import API from 'api/api';
import { useDidUpdate, useToggle } from 'hooks';
import { MAP_DEFAULT_OPTIONS } from 'config/constants';
import { MAP_PRIMARY_MODES, MAP_SECONDARY_MODES, addSecondaryModeIds, updateCenter, updateGirlsCoordinatesData, updateMapUpdatedState, updateSecondaryModeIds, updateZoom } from 'redux/ducks/map';
import { selectFilteredGirlCoordinates } from 'redux/selectors/selectors';
import { createPoint, createPoints } from '../../utils/createPoints';
import getClientRadiusBounds from '../../utils/getClientRadiusBounds';
import useSelectMarkersOnMap from 'hooks/useSelectMarkersOnMap';
import { GirlsMapModalContext } from '../../GirlsMapModal';

import Spinner from 'components/UI/Spinner/Spinner';
import ClusterMarker from '../Markers/ClusterMarker';
import ClientMarker from '../Markers/ClientMarker';
import GirlsMarker from '../Markers/GirlsMarker';
import { Marker } from '@react-google-maps/api';
import GirlPreviewOnMap from '../GirlPreviewOnMap/GirlPreviewOnMap';
import ShowSubwayLinesButton from '../ControlElements/ShowSubwayLinesButton';
import SimilarEscortsButton from '../ControlElements/SimilarEscortsButton';
import RecenterButton from '../ControlElements/RecenterButton';


export const GirlsMapContext = React.createContext({});

const GirlsMap = ({ mapRef, mapsRef }) => {
  const {
    zoom,
    center,
    girlsData,
    clientData,
    primaryMode,
    secondaryMode,
    girlIdsBySecondaryMode: {
      [MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT]: hostForSimilarEscortId,
    },
  } = useSelector((state) => state.map);
  const filteredGirlCoordinates = useSelector(selectFilteredGirlCoordinates);

  const [bounds, setBounds] = useState([]);
  const [boundsForApi, setBoundsForApi] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [isStreetViewVisible, setIsStreetViewVisible] = useState(false);
  const [isClearCache, setIsClearCache] = useToggle(true);

  const {
    isGoogleMapLoaded,
    setIsGoogleMapLoaded,
    map,
  } = useContext(GirlsMapModalContext);

  const dispatch = useDispatch();

  const isTargetMode = primaryMode === MAP_PRIMARY_MODES.TARGET_ESCORT;
  const isNavigationMode = primaryMode === MAP_PRIMARY_MODES.NAVIGATION;
  const isSelectedMode = secondaryMode[MAP_SECONDARY_MODES.SELECT_ESCORTS] || false;
  
  const mapContainerRef = useRef(null);
  const refsForSelect = {
    map,
    mapContainer: mapContainerRef.current
  }
  
  const girlsPoints = createPoints(filteredGirlCoordinates, 'girls');
  const clientPoint = createPoint(clientData, 'clients');

  const { clusters, supercluster } = useSupercluster({
    points: [...girlsPoints, clientPoint],
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const {
    selectedMarkers,
    selectionBox,
    getMarkerProps,
  } = useSelectMarkersOnMap(refsForSelect, girlsPoints, isSelectedMode);
  
  const getGirlsCoordinatesByBounds = () => {
    const [longitudeMin, latitudeMin, longitudeMax, latitudeMax] = boundsForApi;
    const hostProfile = girlsData[hostForSimilarEscortId]?.profile;
    
    const config = {
      latitudeMin,
      latitudeMax,
      longitudeMin,
      longitudeMax,
      cacheClear: isClearCache || !!hostProfile?.id,
      ...(clientData.radius.value && {
        radius: clientData.radius.value,
        centerLatitude: clientData.latitude,
        centerLongitude: clientData.longitude,
        ...getClientRadiusBounds(clientData)
      }),
      ...(hostProfile?.id && { ['host-profile']: hostProfile?.id }),
    };

    setIsPending(true);
    setIsClearCache(false);

    API.getGirlsCoordinatesByBounds(config)
      .then(({ data }) => {
        dispatch(updateGirlsCoordinatesData(data));
        dispatch(updateSecondaryModeIds(MAP_SECONDARY_MODES.RADIUS_ESCORTS, data.radiusProfiles));
      })
      .catch(console.error)
      .finally(() => {
        setIsPending(false);
      });
  };

  const updateMapState = (map) => {
    const bounds = map.getBounds();

    setBoundsForApi([
      bounds.getSouthWest().lng(),
      bounds.getSouthWest().lat(),
      bounds.getNorthEast().lng(),
      bounds.getNorthEast().lat(),
    ]);
    // setZoom(map.getZoom());
  };

  const handleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsRef.current = maps;

    updateMapState(map);
    setIsGoogleMapLoaded(true)
  }

  const handleMapChange = ({ zoom, bounds, center }) => {
    dispatch(updateZoom(zoom));
    dispatch(updateCenter(center));

    setBounds([
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat,
    ]);
  }

  const onMove = useCallback(() => updateMapState(map), [map]);
  const onStreetViewVisibleChange = () => setIsStreetViewVisible(map.getStreetView().getVisible());

  useEffect(() => {
    if (!isGoogleMapLoaded) return;

    const moveListenerHandle = google.maps.event.addListener(map, 'idle', onMove);
    const streetViewListenerHandle = google.maps.event.addListener(
      map.getStreetView(),
      'visible_changed',
      onStreetViewVisibleChange
    );

    return () => {
      google.maps.event.removeListener(moveListenerHandle);
      google.maps.event.removeListener(streetViewListenerHandle);
    };
  }, [isGoogleMapLoaded]);

  useDidUpdate(() => {
    getGirlsCoordinatesByBounds();
  }, [boundsForApi, clientData, hostForSimilarEscortId])

  useEffect(() => {
    if (!selectedMarkers.length) return;

    const girlProperties = selectedMarkers.map(marker => marker.properties);

    dispatch(addSecondaryModeIds(MAP_SECONDARY_MODES.SELECT_ESCORTS, girlProperties));
  }, [selectedMarkers])

  return (
    <GirlsMapContext.Provider value={{ isStreetViewVisible }}>
      <div className="girls-map__content">
        {(isTargetMode || isNavigationMode) && <GirlPreviewOnMap />}

        <div
          className="girls-map__content-map"
          ref={mapContainerRef}
        >
          {isTargetMode && <><SimilarEscortsButton /><RecenterButton clusters={clusters} /></>}
          {isGoogleMapLoaded && <ShowSubwayLinesButton />}

          {isPending && <Spinner className="girls-map__spinner-wrap" spinnerSize={44} />}

          <GoogleMapReact
            zoom={zoom}
            center={center}
            options={MAP_DEFAULT_OPTIONS}
            yesIWantToUseGoogleMapApiInternals
            shouldUnregisterMapOnUnmount={false}
            onGoogleApiLoaded={handleApiLoaded}
            onTilesLoaded={() => dispatch(updateMapUpdatedState())}
            onChange={handleMapChange}
          >
            {isGoogleMapLoaded && clusters.map(cluster => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, category } = cluster.properties;

              const markerProps = {
                lat: latitude,
                lng: longitude,
                key: cluster.properties.id,
                cluster,
                supercluster,
                onDoubleClick: e => e.stopPropagation(),
                ...getMarkerProps(),
              }

              if (isCluster) {
                return <ClusterMarker {...markerProps} />;
              } else if (category === 'clients') {
                return <ClientMarker {...markerProps} />;
              } else if (category === 'girls') {
                return <GirlsMarker {...markerProps} />;
              } else {
                return <Marker {...markerProps} />;
              }
            })}
          </GoogleMapReact>

          {selectionBox}
        </div>

      </div>
    </GirlsMapContext.Provider>
  )
}

export default React.memo(GirlsMap);
