import API from 'api/api';
import { addToEntitiesIfMissing, getDateByTimezoneOffset } from 'utils';
import { getLocalRate } from 'utils/getServiceRate';
import { normalize } from 'utils/normalizeContacts';
import { v4 } from 'uuid';
import { UPDATE_BOOKING_REQUEST, UPDATE_SESSION_BOOKING, REMOVE_BOOKING } from './sessions';
import convertToOptimizeBooking from '../../utils/convertToOptimizeBooking';
import { BOOKING_STATUSES, BOOKING_STATUSES_NAMES } from 'config/constants';
import updateEntities from 'utils/updateEntities';
import { getActiveDivaGirls } from './divaGirls';
import { selectUserTimezone } from '../selectors/selectors';

export const ADD_BOOKINGS = 'ADD_BOOKINGS';
export const DELETE_BOOKING = 'BOOKINGS/DELETE_BOOKING';
export const GET_ALL_REQUIREMENTS = 'BOOKINGS/GET_ALL_REQUIREMENTS';
export const GET_BOOKINGS_BY_CALLER_ID = 'GET_BOOKINGS_BY_CALLER_ID';
export const ADD_BOOKINGS_BY_CALLER_ID = 'ADD_BOOKINGS_BY_CALLER_ID';
export const SET_LOAD_BOOKINGS_PENDING = 'SET_LOAD_BOOKINGS_PENDING';
export const SET_UPDATE_BOOKINGS_PENDING = 'SET_UPDATE_BOOKINGS_PENDING';
export const ADD_BOOKINGS_BY_FILTERS = 'ADD_BOOKINGS_BY_FILTERS';
export const DELETE_SEARCHED_BOOKINGS = 'DELETE_SEARCHED_BOOKINGS';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const UPDATE_BOOKING_UUID = 'UPDATE_BOOKING_UUID';
export const UPDATE_BOOKING_SCHEDULED_MSGS_BY_BOOKING = 'UPDATE_BOOKING_SCHEDULED_MSGS_BY_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const UPDATE_BOOKINGS = 'UPDATE_BOOKINGS';
export const UPDATE_REVEALED_MODE = 'UPDATE_REVEALED_MODE';

export const SET_BOOKING_LOGS = 'SET_BOOKING_LOGS';
export const CLEAR_BOOKING_LOGS = 'CLEAR_BOOKING_LOGS';

const unfinishedStatuses = [
  BOOKING_STATUSES[BOOKING_STATUSES_NAMES.PENDING].id,
  BOOKING_STATUSES[BOOKING_STATUSES_NAMES.IN_PROGRESS].id,
  BOOKING_STATUSES[BOOKING_STATUSES_NAMES.PRE_PENDING].id,
  BOOKING_STATUSES[BOOKING_STATUSES_NAMES.DELETED].id,
]
const isAbsentProfileInformation = (booking) => {
  const { profile_id, profile_id_duo } = booking;

  if (!booking.group_girls || !booking.profile_pictures) {
    return true;
  }

  if (profile_id_duo) {
    return !booking.group_girls[profile_id] || !booking.group_girls[profile_id_duo]
      || !booking.profile_pictures[profile_id] || !booking.profile_pictures[profile_id_duo]
  }

  return !booking.group_girls[profile_id] || !booking.profile_pictures[profile_id];
}

export const getAllRequirements = () => dispatch => {
  return API.getAllRequirements()
    .then(({ data }) => {
      dispatch({
        type: GET_ALL_REQUIREMENTS,
        payload: data
      })
    })
};

export const getBookingsByCallerId = (caller, isNotFull = true, dateFrom = null) => dispatch => {
  dispatch(setUpdateBookingsPending(true));

  return API.getOptimizedBookingsByDate({
    clientId: caller,
    status: unfinishedStatuses,
    orderBy: 'date',
    sort: 'desc',
    girlAvailability: true,
    ...(dateFrom ? { dateFrom } : {}),
  })
    .then(({ data }) => {
      const optBookingsList = data.bookings?.map((booking) => convertToOptimizeBooking(booking, true)) || [];

      dispatch({
        type: ADD_BOOKINGS,
        payload: optBookingsList,
      })

      return optBookingsList;
    })
    .then((bookings) => {
      const profileIds = bookings.filter(isAbsentProfileInformation)
        .flatMap((booking) => [Number(booking.profile_id), Number(booking.profile_id_duo)])
        .filter(Number) || [];

      if (profileIds.length) {
        dispatch(getActiveDivaGirls({ params: { 'filter-profileid': profileIds } }))
      }

      return bookings;
    })
    .catch(console.error)
    .finally(() => dispatch(setUpdateBookingsPending(false)))
};


export const getUnfinishedBookingsByCallerId = (id, offset = 0) => dispatch => {
  dispatch(setUpdateBookingsPending(true));

  return API.getContactUnfinishedBookings(id, offset)
    .then(({ data: bookings }) => {
      dispatch({
        type: offset ? ADD_BOOKINGS_BY_CALLER_ID : GET_BOOKINGS_BY_CALLER_ID,
        payload: {
          clientId: id,
          ...normalize(bookings?.map((booking) => convertToOptimizeBooking(booking, true)) || []),
        }
      })

      return bookings;
    })
    .then((bookings) => {
      const profileIds = bookings.filter(isAbsentProfileInformation)
        .flatMap((booking) => [Number(booking.profile_id), Number(booking.profile_id_duo)])
        .filter(Number) || [];

      if (profileIds.length) {
        dispatch(getActiveDivaGirls({ params: { 'filter-profileid': profileIds } }))
      }

      return bookings;
    })
    .catch(console.error)
    .finally(() => dispatch(setUpdateBookingsPending(false)))
};

export const getUnfinishedBookingsByGirlId = (girlId, dateFrom = null) => (dispatch) => {
  return API.getOptimizedBookingsByDate({
    girlId,
    status: unfinishedStatuses,
    ...(dateFrom ? { dateFrom } : {}),
  })
    .then(({ data }) => {
      const optBookingsList = data.bookings?.map((booking) => convertToOptimizeBooking(booking, true)) || [];

      dispatch({
        type: ADD_BOOKINGS,
        payload: optBookingsList,
      })
    })
    .catch(console.error)
  // TODO load girl profiles if information is absent (code in prod)
}

const getServices = (services) => {
  return services
    .filter(service => service.checked)
    .map(item => ({ name: item.name, price: item.price }));
}

const getRequirements = requirements => {
  return requirements
    .filter(({ isChecked }) => isChecked)
    .map(({ id }) => id);
}

export const createBookingByValues = (values, activeSessionId, profileId, contactId, forceBooking) => (dispatch) => {
  const addressUuid = v4();
  // const profileUid = v4();
  const profileUid = values.profile.contact_uid;
  const profileDuoUid = v4();
  const isDuo = !!values.escort;
  const meetingType = isDuo ? 'duo' : 'solo';
  const notes = values.notes || '';
  const requirements = getRequirements(values.requirements)
  const currType = values.type === 'incall_duo' 
    ? 'outcall' 
    : values.type === 'incall_both' ? 'incall' : values.type;

  const config = {
    isDuo,
    taxiFee: values.taxi?.value,
    priceType: values.rate?.type,
    discount: values.discount?.value,
    sessionId: activeSessionId,
    profileId: profileId,
    type: currType,
    meetingType,
    profileUid,
    profileDuoUid,
    duration: values.bookingDuration.label,
    date: values.date,
    rate: String(getLocalRate(values)),
    services: getServices(values.services),
    requirements,
    caller_id: contactId,
    confirmation_status: values.bookingStatus?.label,
    notes,
    taxiNote: values.taxiNoteSolo,
    notesAttachments: values.notesAttachments,
    address: values.type === 'outcall' && {
      ...values.address,
      uuid: addressUuid,
      type: +values.address.type,
      latitude: String(values.address.latitude),
      longitude: String(values.address.longitude),
    },
    ...(values.notesAttachments ? { notesAttachments: values.notesAttachments } : {}),
    duoConfig: isDuo && {
      type: values.type === 'incall_duo' 
        ? 'incall' 
        : values.type === 'incall_both' ? 'incall' : 'outcall',
      taxiNote: values.taxiNoteDuo,
      duration: values.bookingDuration?.label,
      discount: values.escort.discount?.value,
      rate: String(getLocalRate(values.escort) + (values.escortRate?.value || 0)),
      profile_id: values.escort.id,
      contact_uid: profileDuoUid,
      services: JSON.stringify(getServices(values.escort.services)),
      taxi_fee: values.escort.taxi?.value,
      price_type: values.rate?.type,
      requirements: getRequirements(values.duoRequirements)
    },
  }

  if (typeof forceBooking === "boolean") {
    config.forceBooking = forceBooking;
  }

  dispatch(setUpdateBookingsPending(true));

  return API.createBooking(config);
};

export const updateBookingByValues = (
  values,
  booking,
  cancelPrevRequest,
  newCancelToken,
  bookingId,
  forceBooking
) => (dispatch, getState) => {
  const uuid = getState().bookings.entities[bookingId]?.address?.uuid;
  dispatch(setUpdateBookingsPending(true));

  const addressUuid = v4();

  const isDuo = !!values.escort; // meeting_type
  const meetingType = isDuo ? 'duo' : 'solo';
  const notes = values.notes || '';
  const requirements = values?.requirements
    ?.filter(({ isChecked }) => isChecked)
    ?.map(({ requirement_id }) => requirement_id);

  let bookingIdSolo, bookingIdDuo;
  if (booking.groupGirls?.length) {
    bookingIdSolo = booking.id;
    bookingIdDuo = booking.groupGirls.find(profile => profile.bookingId !== bookingIdSolo)?.bookingId
  }

  const config = {
    isDuo,
    taxiFee: +values.taxi.value,
    priceType: values.rate?.priceType,
    discount: +values.discount.value,
    meetingType,
    bookingId: bookingIdSolo || booking.id,
    isSuccess: values.is_success,
    reason: values.reason,
    description: values.description,
    operatorId: values.operatorId,
    sessionId: booking.session_id,
    profileId: values.profileId,
    type: values.type,
    duration: values.bookingDuration.label,
    date: values.date,
    rate: getLocalRate(values),
    services: getServices(values.services),
    requirements,
    caller_id: booking.callerId,
    confirmation_status: values.bookingStatus.label,
    notes,
    uidGroup: booking.uuid_group,
    contact_uid: values.profile?.contact_uid,
    taxiNote: values.taxiNote,
    address: values.type === 'outcall' && values.address && {
      ...values.address,
      uuid: uuid || addressUuid,
      latitude: String(values.address.latitude),
      longitude: String(values.address.longitude),
    },
    duoConfig: isDuo && {
      bookingId: bookingIdDuo,
      type: values.escort.type,
      duration: values.escort.bookingDuration?.label,
      discount: values.escort.discount?.value,
      rate: String(getLocalRate(values.escort)),
      profile_id: values.escort?.id,
      services: JSON.stringify(getServices(values.escort.services)),
      taxi_fee: values.escort.taxi?.value,
      taxiNote: values.escort.taxiNote,
      price_type: values.escort.rate?.type,
      contact_uid: values.escort.contact_uid,
      requirements: values?.duoRequirements
        ?.filter(({ isChecked }) => isChecked)
        ?.map(({ requirement_id, id }) => requirement_id || id)?.filter(Boolean)
    },
    ...(values.notesAttachments ? { notesAttachments: values.notesAttachments } : {})
  }

  if (forceBooking) {
    config.forceBooking = forceBooking;
  }

  cancelPrevRequest();

  dispatch(updateBookingUUID(bookingId, uuid || addressUuid))

  return API.updateBooking(config, newCancelToken())
};

export const updateBookingUUID = (bookingId, uuid) => ({
  type: UPDATE_BOOKING_UUID,
  payload: { bookingId, uuid },
})

export const deleteBooking = (booking_id) => dispatch => {
  dispatch({
    type: DELETE_BOOKING,
    payload: booking_id
  })
};

export const setUpdateBookingsPending = (value) => ({
  type: SET_UPDATE_BOOKINGS_PENDING,
  payload: value,
});

export const setUpdateBookingsPendingGlobal = value => dispatch => {
  dispatch({
    type: SET_UPDATE_BOOKINGS_PENDING,
    payload: value
  });
};

export const setBookings = (bookings) => ({
  type: ADD_BOOKINGS,
  payload: bookings,
});

export const deleteAttachment
  = (booking_id, attachment_number) => dispatch => {
    return API.deleteAttachment(booking_id, attachment_number)
      .then(() => {
        dispatch({
          type: DELETE_ATTACHMENT,
          payload: { booking_id, attachment_number }
        })
      })
  }

export const setBookingLog = (payload) => ({
  type: SET_BOOKING_LOGS,
  payload,
})

export const clearBookingLog = (payload) => ({
  type: CLEAR_BOOKING_LOGS,
  payload,
})

export const updateBookingScheduledMessagesByBooking =
  (bookingId, scheduleMessage) => (dispatch) => {
    dispatch({
      type: UPDATE_BOOKING_SCHEDULED_MSGS_BY_BOOKING,
      payload: {
        bookingId,
        scheduleMessage,
      },
    });
  };

export const updateBooking = (payload) => ({
  type: UPDATE_BOOKING,
  payload
})

export const updateBookings = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_BOOKINGS,
    payload
  });

  if (Array.isArray(payload)) {
    const profileIds = payload.filter(isAbsentProfileInformation).map((booking) => booking.profile_id) || [];

    if (profileIds.length) {
      dispatch(getActiveDivaGirls({ params: { 'filter-profileid': profileIds } }))
    }
  }
}

export const updateRevealedMode = () => ({ type: UPDATE_REVEALED_MODE });

const initialState = {
  requirements: [],
  entities: {},
  clients: {
    ids: {},
  },
  auxiliaryIds: [],
  updateBookingsPending: false,
  log: [],
  revealed: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_BOOKINGS: {
      return {
        ...state,
        entities: addToEntitiesIfMissing(
          state.entities,
          payload,
        ),
      }
    }

    case GET_ALL_REQUIREMENTS:
      return {
        ...state,
        requirements: payload
      }

    case GET_BOOKINGS_BY_CALLER_ID:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        clients: {
          ids: {
            ...state.clients.ids,
            [payload.clientId]: payload.result,
          },
        }
      }

    case ADD_BOOKINGS_BY_CALLER_ID:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        clients: {
          ids: {
            ...state.clients.ids,
            [payload.clientId]: [
              ...state.clients.ids[payload.clientId],
              ...payload.result
            ],
          },
        }
      }

    case UPDATE_SESSION_BOOKING: {
      const preparedGroupGirls = typeof (payload.booking.group_girls) === 'string'
        ? JSON.parse(payload.booking.group_girls)
        : payload.booking.group_girls;

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.booking.id]: {
            ...state.entities[payload.booking.id],
            ...payload.booking,
            group_girls: preparedGroupGirls,
          },
        }
      }
    }

    case UPDATE_BOOKING: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            ...payload.props,
            isNotFull: false,
          },
        }
      }
    }

    case UPDATE_BOOKINGS: {
      return {
        ...state,
        entities: updateEntities(
          state.entities,
          payload
        )
      }
    }

    case DELETE_BOOKING: {
      const updateBookingState = state.entities;
      delete updateBookingState[payload];

      return {
        ...state,
        entities: updateBookingState
      }
    }

    case DELETE_ATTACHMENT: {
      const { booking_id, attachment_number } = payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [booking_id]: {
            ...state.entities[booking_id],
            notes_attachments: state.entities[booking_id].notes_attachments.filter((_, i) => i !== attachment_number)
          }
        }
      }
    }

    case SET_UPDATE_BOOKINGS_PENDING:
      return {
        ...state,
        updateBookingsPending: payload,
      }

    case SET_BOOKING_LOGS:
      return {
        ...state,
        log: payload,
      }

    case CLEAR_BOOKING_LOGS:
      return {
        ...state,
        log: [],
      }

    case UPDATE_BOOKING_UUID: {
      if (!state.entities[payload.bookingId] || !state.entities[payload.bookingId]?.address) return state;
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.bookingId]: {
            ...state.entities[payload.bookingId],
            address: {
              ...state.entities[payload.bookingId].address,
              uuid: payload.uuid,
            }
          }
        }
      }
    }

    case UPDATE_BOOKING_SCHEDULED_MSGS_BY_BOOKING: {
      const updateBookingMsgs = state.entities[payload.bookingId].scheduleMessages.map((msg) =>
        msg.id === payload.scheduleMessage.id ? payload.scheduleMessage : msg
      )
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.bookingId]: {
            ...state.entities[payload.bookingId],
            scheduleMessages: updateBookingMsgs,
          },
        },
      };
    }

    case UPDATE_BOOKING_REQUEST: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.request.booking_id]: {
            ...state.entities[payload.request.booking_id],
            requests: state.entities[payload.request.booking_id].requests.concat(payload.request)
          }
        }
      };
    }

    case UPDATE_REVEALED_MODE: {
      return {
        ...state,
        revealed: !state.revealed
      }
    }

    case REMOVE_BOOKING: {
      if (!state.entities[payload.booking.id]) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.booking.id]: {
            ...state.entities[payload.booking.id],
            status: 6,
          }
        },
      };
    }

    default:
      return state;
  }
};
