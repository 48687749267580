import { connect } from 'react-redux';

import { ADDITIONAL_SESSION_FILTERS } from 'config/constants';
import { getAdditionalFiltersFromDiva } from 'redux/ducks/divaGirls';
import { selectCanIEditSession, selectPropertyOfActiveSession } from 'redux/selectors/selectors';
import { updateProfileFilters, clearAdditionalFilters } from "redux/ducks/sessions";

import './GalleryHeaderFilters.scss';
import ICONS from 'assets/icons';
import GalleryHeaderIncludeFilter from './GalleryHeaderIncludeFilter';
import GalleryHeaderProfileFilters from './GalleryHeaderProfileFilters';

const GalleryHeaderFilters = (props) => {
  const {
    activeSession,
    canIEditSession,
    additionalFilters,
    clearAdditionalFilters,
  } = props;

  const resetFilters = () => {
    clearAdditionalFilters(activeSession, canIEditSession);
  };

  return (
    <div className="gallery-header-filters">
      <div className="gallery-header-filters__header">
        <span className="gallery-header-filters__includes-filters-text">Include:</span>

        <div className="gallery-header-filters__include-filter">
          <GalleryHeaderIncludeFilter
            label="Unavailable"
            activeSession={activeSession}
            canIEditSession={canIEditSession}
            additionalFilters={additionalFilters}
            filter={ADDITIONAL_SESSION_FILTERS.includeoff}
          />
        </div>

        <div className="gallery-header-filters__include-filter">
          <GalleryHeaderIncludeFilter
            label="Avoid"
            activeSession={activeSession}
            canIEditSession={canIEditSession}
            additionalFilters={additionalFilters}
            filter={ADDITIONAL_SESSION_FILTERS.banned}
          />
        </div>

        <span className="gallery-header-filters__includes-filters-text">Filter:</span>

        <div className="gallery-header-filters__include-filter">
          <GalleryHeaderIncludeFilter
            label="On holiday"
            activeSession={activeSession}
            canIEditSession={canIEditSession}
            additionalFilters={additionalFilters}
            filter={ADDITIONAL_SESSION_FILTERS.holidays}
          />
        </div>

        <button className="gallery-header-filters__clear-btn" onClick={resetFilters}>
          <ICONS.close className="gallery-header-filters__clear-btn-icon"/> Clear All
        </button>
      </div>

      <div className="gallery-header-filters__divider"></div>

      <GalleryHeaderProfileFilters
        canIEditSession={canIEditSession}
        activeSession={activeSession}
        additionalFilters={additionalFilters}
        updateProfileFilters={updateProfileFilters}
      />
    </div>
  );
};

const mSTP = state => ({
  canIEditSession: selectCanIEditSession(state),
  activeSession: state.sessions.activeSession,
  additionalFilters: selectPropertyOfActiveSession(state, 'additionalFilters'),
})

const mDTP = {
  updateProfileFilters,
  clearAdditionalFilters,
  getAdditionalFiltersFromDiva,
}

export default connect(mSTP, mDTP)(GalleryHeaderFilters);
