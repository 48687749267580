import LocationPreview from 'components/LocationPreview/LocationPreview';
import NavigationPreview from 'components/NavigationPreview/NavigationPreview';


const getLocationLink = (location) => {
  return `https://www.google.com/maps/?q=${location.lat},${location.lng}`;
}

const getNavigationLink = (origin, destination) => {
  const host = "https://www.google.com/maps/dir/";
  const originAttributes = `origin=${origin.lat},${origin.lng}`;
  const destinationAttributes = `destination=${destination.lat},${destination.lng}`;
  const travelmode = "travelmode=driving";

  return `${host}?api=1&${originAttributes}&${destinationAttributes}&${travelmode}`;
}


const AttachemntMapPreview = ({ location, route, siteTitle }) => {
  const { origin, destination } = route || {};

  return (
    <article className="attachment-map">
      <a
        href={route ? getNavigationLink(origin, destination) : getLocationLink(location)}
        title={siteTitle.trim()}
        target="_blank"
        onClick={event => event.preventDefault()}
      >
        {route ? (
          <NavigationPreview origin={origin} destination={destination} />
        ) : (
          <LocationPreview location={location} />
        )}
      </a>
    </article>
  )
}

export default AttachemntMapPreview;
