import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MAP_GIRL_FILTERS, MAP_PRIMARY_MODES, updateActiveGirlIds, updateFilter } from 'redux/ducks/map'
import { useDidMount } from 'hooks'
import { selectCallerIdsFromActiveChats, selectContactUIDSFromActiveChats, selectGirlsIdsFromActiveChats } from 'redux/selectors/selectors'
import { GirlsMapModalContext } from '../../GirlsMapModal'

import AllEscortsButton from '../ControlElements/AllEscortsButton'
import ClientLocationInput from '../ControlElements/ClientLocationInput'
import ClientRadiusSelect from '../ControlElements/ClientRadusSelect'
import SelectEscortsButtons from '../ControlElements/SelectEscortsButtons'
import SendEscortsButton from '../ControlElements/SendEscortsButton'


const DefaultControls = {};

DefaultControls.Header = () => {
  const { clientData } = useSelector((state) => state.map);
  const uidsOfActiveGirlChats = useSelector(selectContactUIDSFromActiveChats);
  const callerIdsOfActiveGirlChats = useSelector(selectCallerIdsFromActiveChats);

  const dispatch = useDispatch();

  const { flyToCurrentGirlMarkers } = useContext(GirlsMapModalContext);

  useDidMount(() => {
    dispatch(updateActiveGirlIds(uidsOfActiveGirlChats));
    dispatch(updateFilter(MAP_GIRL_FILTERS.CHAT_ESCORTS, true));

    if (!clientData.latitude) {
      flyToCurrentGirlMarkers(callerIdsOfActiveGirlChats);
    }
  })

  return (
    <div className="girls-map__header">
      <div className="girls-map__description">
        Showing escorts with which you are having open conversation in chats.
      </div>

      <AllEscortsButton />
    </div>
  )
}

DefaultControls.Footer = () => (
  <div className="girls-map__footer">
    <div className="girls-map__footer-side">
      <ClientLocationInput />
      <ClientRadiusSelect />
    </div>

    <div className="girls-map__footer-side">
      <SelectEscortsButtons />
      <SendEscortsButton />
    </div>
  </div>
)

export default DefaultControls;
