import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import { CONTACT_TYPES, ROUTES } from 'config/constants';
import { getContactsById } from 'redux/ducks/contacts';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { closeModal } from 'redux/ducks/activeWindows';
import { GirlsMapModalContext } from '../../GirlsMapModal';

import TypingIndicator from 'components/UI/TypingIndicator/TypingIndicator';
import ICONS from 'assets/icons';


const GirlsMarkerTooltip = (props) => {
  const {
    girlProperties,
    setShouldCloseTooltip,
    handleTargetGirl,
  } = props;

  const { clientData } = useSelector(state => state.map);
  const { latitude, longitude } = clientData;

  const [girlData, setGirlData] = useState(null);
  const [pending, setPending] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const { saveMapState } = useContext(GirlsMapModalContext);

  const handleOpenGirlChat = () => {
    dispatch(getContactsById(girlProperties.caller_id, CONTACT_TYPES.GIRL))
      .then((contact) => {
        dispatch(updateActiveContact(contact, true));
        setShouldCloseTooltip(true);
        saveMapState();
        
        if (location.pathname === ROUTES.main) {
          dispatch(closeModal());
        }
      });
  };

  useEffect(() => {
    if (latitude === null || longitude === null) return;

    setPending(true);

    API.getDistanceToGirl(girlProperties.caller_id, latitude, longitude)
      .then(({ data }) => setGirlData(data))
      .catch(console.error)
      .finally(() => setPending(false));
  }, [girlProperties, latitude, longitude]);

  return (
    <div className="girls-map__tooltip">
      <header className="girls-map__tooltip-header">
        <span>
          {pending
            ? <TypingIndicator />
            : (girlData?.distance && `${girlData?.distance} miles`)
          }
        </span>
        
        <div className="girls-map__tooltip-buttons">
          <button onClick={handleOpenGirlChat}>
            <ICONS.comments />
          </button>

          <button onClick={handleTargetGirl}>
            <ICONS.mapDash />
          </button>
        </div>
      </header>

      <div className='girls-map__tooltip-content'>
        {girlProperties.fn}
      </div>
    </div>
  )
}

export default React.memo(
  GirlsMarkerTooltip,
  (prevProps, nextProps) => {
    return prevProps.girlProperties.caller_id === nextProps.girlProperties.caller_id;
  }
);
