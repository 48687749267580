import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectUnfinishedBookingsByClientOrGirl } from 'redux/selectors/selectors';
import { classModifier } from 'utils';
import { useToggle } from 'hooks';

import { BOOKING_ITEM_VIEWS, BookingItemNew } from 'components/BookingItemNew';
import List from 'components/List/List';

const {
  CLIENT_PINNED,
  GIRL_PINNED,
} = BOOKING_ITEM_VIEWS;

const bookingHeight = 46;

const PinnedBookings = (props) => {
  const [isOpened, setIsOpened] = useToggle(false);

  const bookings = useSelector((state) => selectUnfinishedBookingsByClientOrGirl(state, props));
  const bookingType = props.clientId ? CLIENT_PINNED : GIRL_PINNED;

  const sortedBookings = useMemo(() => {
    return bookings.sort(({ date: prevDate }, { date: nextDate }) => {
      return moment(prevDate).diff(moment(nextDate))
    })
  }, [bookings]);

  const bookingContainerStyle = {
    height: sortedBookings.length > 3 ? bookingHeight * 3 : sortedBookings.length * bookingHeight
  }

  if (!bookings.length) {
    return null;
  }

  return (
    <div className="pinned-items__bookings-wrapper">
      <div className={classModifier('pinned-items__bookings', isOpened && 'open')}>
        <div className="pinned-booking__text" onClick={setIsOpened}>
          {sortedBookings.length} Bookings
        </div>

        <List
          classPrefix="pinned-items"
          list={sortedBookings}
          autoHide
          scrollbarProps={{
            style: bookingContainerStyle
          }}
        >
          <BookingItemNew type={bookingType} />
        </List>
      </div>
    </div>
  )
}

export default PinnedBookings;
