export const BOOKING_ITEM_VIEWS = Object.freeze({
  CONFINED: 'CONFINED',
  LINED: 'LINED',
  SHORT_LINED: 'SHORT_LINED',
  MODAL_LINED: 'MODAL_LINED',
  SESSION: 'SESSION',
  TABLE: 'TABLE',
  GIRL: 'GIRL',
  CLIENT: 'CLIENT',
  CLIENT_PINNED: 'CLIENT_PINNED',
  GIRL_PINNED: 'GIRL_PINNED',
  WEEK: 'WEEK',
  ATTACHMENT: 'ATTACHMENT',
  GTab: 'GTab',
  Form: 'Form',
})

export const BOOKING_DETAILS_ITEMS_NAMES = Object.freeze({
  DURATION: 'DURATION',
  TYPE: 'TYPE',
  RATE: 'RATE',
  DATE: 'DATE',
  AGE: 'AGE',
  HEIGHT: 'HEIGHT',
  BREAST_SIZE: 'BREAST_SIZE',
  DRESS: 'DRESS',
  NATIONALITY: 'NATIONALITY',
  AGENTS: 'AGENTS',
  REQUESTS: 'REQUESTS',
})

export const BOOKING_STATUSES = {
  0: 'cancelled',
  1: 'pending',
  2: 'in progress',
  3: 'finished',
  4: 'feedback received',
  5: 'pre-pending',
};

export const BOOKING_MARKERS = {
  BOOKING: 'BOOKING',
  PREBOOKING: 'PREBOOKING',
}

export const BOOKING_STATES = {
  BOOKING: 'BOOKING',
  PREBOOKING: 'PREBOOKING',
  REQUESTS: 'REQUESTS',
  NOTES: 'NOTES',
  AGENT: 'AGENT',
}
