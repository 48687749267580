import { CONTACT_TYPES, INCOMING_INTERACTION_TYPES } from 'config/constants';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import getMapCoordinatesFromLink from './getMapCoordinatesFromLink';
import isMapLink from './isMapLink';

export default (event, dispatch, interaction, callerIdsOfActiveGirlChats) => {
  const linkElement = event.target.closest('a');
  
  if (!linkElement) return;

  event.stopPropagation();
  
  const contactTypes = [
    interaction.contact?.type,
    interaction.caller?.type,
    interaction.interaction?.caller?.type,
  ];
  const interactionType = interaction.interaction?.type || interaction.type;
  const isClientMessage = (
    contactTypes.includes(CONTACT_TYPES.CLIENT) && INCOMING_INTERACTION_TYPES.includes(interactionType)
  );

  if (isClientMessage && isMapLink(linkElement.title)) {
    event.preventDefault();

    const linkObj = { site_url: linkElement.href, site_title: linkElement.title };
    const mapCoordinates = getMapCoordinatesFromLink(linkObj);

    const mapProps = {
      clientLocation: mapCoordinates?.location,
      callerIdsOfActiveGirlChats,
    }

    dispatch(openModal(MODAL_TYPES.girlsMap, { mapProps }));
  } else {
    window.open(linkElement.href, '_blank');
  }
}
