import API from "api/api";

import {
  GET_CLIENTS_FROM_TABS,
  REMOVE_CLIENT,
  UPDATE_CLIENT,
  UPDATE_GIRL,
  updateRecentTabs,
} from "./contacts";
import { NEW_INCOMING_CALL, NEW_INCOMING_QUEUE_CALL, NEW_OUTGOING_CALL } from "./calls";
import {
  ADD_REMINDED_GIRL_ID,
  CLEAN_GIRL_CONVERSATION_MEDIA,
  CLEAN_GIRL_MSG_REMINDERS,
  DELETE_GIRL_MSG,
  DELETE_REMINDED_GIRL_ID,
  GET_GIRL_CONVERSATION_MEDIA,
  GET_GIRL_GROUP_TIMELINE,
  GET_GIRL_GROUP_TIMELINE_ERROR,
  GET_GIRL_GROUP_TIMELINE_PENDING,
  GET_GIRL_MSG_CONTEXT,
  GET_GIRL_MSG_REMINDERS,
  GET_GIRL_TIMELINE,
  GET_GIRL_TIMELINE_ERROR,
  GET_GIRL_TIMELINE_PENDING,
  GET_REMINDED_GIRLS_MSGS_IDS,
  GET_SEARCHED_GIRL_MESSAGES,
  GLOBAL_GIRL_MESSAGE_SEARCH,
  REMOVE_GIRL_REMIND_MSG,
  REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE,
  REMOVE_GIRL_TAB,
  REMOVE_GIRL_TABS,
  SET_GIRL_UPDATE_PENDING,
  SET_TIMELINE_GIRL_ARCHIVE_STATUS, setGirlGroupWebmasterMsgs,
  STOP_SEARCH_GIRL_MESSAGES,
  UNREAD_GIRL_MESSAGES,
  UPDATE_ACTIVE_GIRL,
  UPDATE_GIRL_GROUP_TIMELINE,
  UPDATE_GIRL_MSG,
  UPDATE_GIRL_MSG_REMINDERS,
  UPDATE_GIRL_TIMELINE,
  UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH,
  UPDATE_SEARCHED_GIRL_MESSAGES,
} from "./girlChats";
import { formatInteractionType, onRemoveChatTab } from "./roomChats";
import { addToEntitiesIfMissing, getDateByTimezoneOffset, getShortDate } from "utils";

import { NEW_MISSED_CALL, UPDATE_VOICEMAIL_STATUS } from "./missedCalls";
import { CHAT_SOURCES, CHAT_TYPES, CONTACT_TYPES, INTERACTION_TYPES } from "config/constants";
import { MODAL_TYPES, openModal } from "./activeWindows";
import { selectRecentTabs } from "redux/selectors/selectors";
import convertToOptimizeBooking from 'utils/convertToOptimizeBooking';
import { UPDATE_BOOKINGS } from './bookings';
import { getActiveDivaGirls } from './divaGirls';

export const SET_CLIENT_UPDATE_PENDING = 'SET_CLIENT_UPDATE_PENDING';

export const GET_REMINDED_CLIENTS_MSGS_IDS = 'GET_REMINDED_CLIENTS_MSGS_IDS';
export const ADD_REMINDED_CLIENT_ID = 'ADD_REMINDED_CLIENT_ID';
export const DELETE_REMINDED_CLIENT_ID = 'DELETE_REMINDED_CLIENT_ID';

export const GET_SEARCHED_CLIENT_MESSAGES = 'GET_SEARCHED_CLIENT_MESSAGES';
export const UPDATE_SEARCHED_CLIENT_MESSAGES = 'UPDATE_SEARCHED_CLIENT_MESSAGES';
export const GLOBAL_CLIENT_MESSAGE_SEARCH = 'GLOBAL_CLIENT_MESSAGE_SEARCH';
export const UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH = 'UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH';
export const STOP_SEARCH_CLIENT_MESSAGES = 'STOP_SEARCH_CLIENT_MESSAGES';
export const REMOVE_CLIENT_MSG_REMIND = 'REMOVE_CLIENT_MSG_REMIND';
export const REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE = "REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE";

export const UPDATE_ACTIVE_CLIENT = "UPDATE_ACTIVE_CLIENT";

export const GET_CLIENT_TIMELINE_PENDING = "GET_CLIENT_TIMELINE_PENDING";
export const GET_CLIENT_TIMELINE = "GET_CLIENT_TIMELINE";
export const GET_CLIENT_TIMELINE_ERROR = "GET_CLIENT_TIMELINE_ERROR";

export const UPDATE_CLIENT_TIMELINE = "UPDATE_CLIENT_TIMELINE";
export const SET_TIMELINE_CLIENT_ARCHIVE_STATUS = "SET_TIMELINE_CLIENT_ARCHIVE_STATUS";

export const CHANGE_MESSAGE_STATUS = "CHANGE_MESSAGE_STATUS";
export const CHANGE_CALL_STATUS = "CHANGE_CALL_STATUS";
export const REMOVE_CLIENT_TAB = 'REMOVE_CLIENT_TAB';
export const REMOVE_CLIENT_TABS = 'REMOVE_CLIENT_TABS';
export const SET_CLIENT_TABS = 'SET_CLIENT_TABS';

export const NEW_OUTGOING_MESSAGE = "NEW_OUTGOING_MESSAGE";
export const NEW_INCOMING_MESSAGE = "NEW_INCOMING_MESSAGE";

export const UNREAD_CLIENT_MESSAGES = "UNREAD_CLIENT_MESSAGES";
export const NEW_MESSAGE_ATTACHMENTS = "NEW_MESSAGE_ATTACHMENTS";

export const GET_CLIENT_CONVERSATION_MEDIA = "GET_CLIENT_CONVERSATION_MEDIA";
export const UPDATE_CLIENT_CONVERSATION_MEDIA = "UPDATE_CLIENT_CONVERSATION_MEDIA";
export const UPDATE_GIRL_CONVERSATION_MEDIA = "UPDATE_GIRL_CONVERSATION_MEDIA";
export const CLEAN_CLIENT_CONVERSATION_MEDIA = "CLEAN_CLIENT_CONVERSATION_MEDIA";

export const GET_CLIENT_MSG_REMINDERS = "GET_CLIENT_MSG_REMINDERS";
export const CLEAN_CLIENT_MSG_REMINDERS = 'CLEAN_CLIENT_MSG_REMINDERS';
export const UPDATE_CLIENT_MSG_REMINDERS = 'UPDATE_CLIENT_MSG_REMINDERS';

export const GET_CLIENT_MSG_CONTEXT = "GET_CLIENT_MSG_CONTEXT";
export const GET_GLOBAL_CLIENT_MSG_CONTEXT = "GET_GLOBAL_CLIENT_MSG_CONTEXT";

export const UPDATE_CLIENT_MSG_CONTEXT = "UPDATE_CLIENT_MSG_CONTEXT";
export const UPDATE_GIRL_MSG_CONTEXT = "UPDATE_GIRL_MSG_CONTEXT";

export const GET_CLIENT_DATE_MSG_CONTEXT = "GET_CLIENT_DATE_MSG_CONTEXT";
export const UPDATE_CLIENT_DATE_MSG_CONTEXT = "UPDATE_CLIENT_DATE_MSG_CONTEXT";
export const CLEAN_CLIENT_DATE_MSG_CONTEXT = "CLEAN_CLIENT_DATE_MSG_CONTEXT";

export const GET_GIRL_DATE_MSG_CONTEXT = "GET_GIRL_DATE_MSG_CONTEXT";
export const UPDATE_GIRL_DATE_MSG_CONTEXT = "UPDATE_GIRL_DATE_MSG_CONTEXT";
export const CLEAN_GIRL_DATE_MSG_CONTEXT = "CLEAN_GIRL_DATE_MSG_CONTEXT";

export const CLEAN_CLIENT_MSG_CONTEXT = "CLEAN_CLIENT_MSG_CONTEXT";
export const CLEAN_GIRL_MSG_CONTEXT = "CLEAN_GIRL_MSG_CONTEXT";

export const NEW_SCHEDULED_MESSAGE = "NEW_SCHEDULED_MESSAGE";

export const GET_CLIENT_SCHEDULED_MSGS_COUNT = "GET_CLIENT_SCHEDULED_MSGS_COUNT";
export const GET_CLIENT_SCHEDULED_MSGS = "GET_CLIENT_SCHEDULED_MSGS";
export const UPDATE_CLIENT_SCHEDULED_MSGS = 'UPDATE_CLIENT_SCHEDULED_MSGS';
export const CLEAN_CLIENT_SCHEDULED_MSGS = "CLEAN_CLIENT_SCHEDULED_MSGS";

export const GET_GIRL_SCHEDULED_MSGS_COUNT = "GET_GIRL_SCHEDULED_MSGS_COUNT";
export const GET_GIRL_SCHEDULED_MSGS = "GET_GIRL_SCHEDULED_MSGS";
export const UPDATE_GIRL_SCHEDULED_MSGS = 'UPDATE_GIRL_SCHEDULED_MSGS';
export const CLEAN_GIRL_SCHEDULED_MSGS = "CLEAN_GIRL_SCHEDULED_MSGS";

export const CREATE_NEW_CHAT = "CREATE_NEW_CHAT";
export const DELETE_NEW_CHAT = "DELETE_NEW_CHAT";

export const PIN_CLIENT_MSG = 'PIN_CLIENT_MSG';
export const CHANGE_ACTIVE_PINNED_CLIENT_MSG = 'CHANGE_ACTIVE_PINNED_CLIENT_MSG';
export const CHANGE_ACTIVE_PINNED_GIRL_MSG = 'CHANGE_ACTIVE_PINNED_GIRL_MSG';
export const DELETE_PINNED_CLIENT_MSG = 'DELETE_PINNED_CLIENT_MSG';
export const DELETE_PINNED_GIRL_MSG = 'DELETE_PINNED_GIRL_MSG';
export const PIN_GIRL_MSG = 'PIN_GIRL_MSG';
export const FIX_CLIENT_TAB = 'FIX_CLIENT_TAB';
export const INIT_UNFIXED_CLIENT_TAB = 'INIT_UNFIXED_CLIENT_TAB';

export const UPDATE_CLIENT_MSG = 'UPDATE_CLIENT_MSG';
export const DELETE_CLIENT_MSG = 'DELETE_CLIENT_MSG';

export const SET_CLIENTS_RECENT_TABS = 'SET_CLIENTS_RECENT_TABS';

export const START_SEARCH_CLIENTS_MSGS = 'START_SEARCH_CLIENTS_MSGS';
export const START_SEARCH_GIRLS_MSGS = 'START_SEARCH_GIRLS_MSGS';

export const DELETE_TELEGRAM_MSG = 'DELETE_TELEGRAM_MSG';
export const CHANGE_CHAT_SOURCE_CLIENT = 'CHANGE_CHAT_SOURCE_CLIENT';

export const SET_CHAT_BOOKINGS_PENDING = 'SET_CHAT_BOOKINGS_PENDING';
export const ADD_CHAT_BOOKINGS = 'ADD_CHAT_BOOKINGS';

export const LIMIT = 15;

const isAbsentProfileInformation = (booking) => {
  const { profile_id, profile_id_duo } = booking;

  if (!booking.group_girls || !booking.profile_pictures) {
    return true;
  }

  if (profile_id_duo) {
    return !booking.group_girls[profile_id] || !booking.group_girls[profile_id_duo]
      || !booking.profile_pictures[profile_id] || !booking.profile_pictures[profile_id_duo]
  }

  return !booking.group_girls[profile_id] || !booking.profile_pictures[profile_id];
}

export const getContactTimeline = (contactId, chatType, userTimezone, withCancel = true, query = '', isGroup = false) => (dispatch, getState) => {
  const activeGroupInternalId = getState().girlChats.groupChat.internalId;
  const activeGroupType = getState().girlChats.activeGroupType;

  const request = isGroup
    ? API.getGroupInteractions(contactId, null, withCancel, activeGroupType)
    : API.getInteractions(contactId, null, withCancel);

  if (contactId === "new_chat") {
    return dispatch({
      type: GET_CLIENT_TIMELINE,
      payload: {
        contactId
      }
    });
  }

  dispatch({
    type:
      chatType === CHAT_TYPES.CLIENT
        ? GET_CLIENT_TIMELINE_PENDING
        : isGroup
          ? GET_GIRL_GROUP_TIMELINE_PENDING
          : GET_GIRL_TIMELINE_PENDING
  });

  request
    .then(res => {
      let groupMessages = [];
      let incomingGroupMessages = [];

      if (isGroup) {
        // let lastGroupMsg = null;
        const groupData = res.data.messages.reverse();

        groupData.forEach((msg) => {
          if (!msg.out) {
            incomingGroupMessages.push(msg.id);
          }

          groupMessages = [
            ...groupMessages,
            {
              ...msg,
              ...(!!msg.media ? {
                  images: [{
                    src: msg.media.path,
                    url: msg.media.path,
                  }]
                } : {}
              ),
            }
          ];

          // if (lastGroupMsg && lastGroupMsg === msg.grouped_id) {
          //   const msgIndex = groupMessages
          //     .findIndex((item) => item.grouped_id === msg.grouped_id);
          //
          //   const newMedia = msg.media?.uploaded && {
          //     src: msg.media.uploaded.path,
          //     url: msg.media.uploaded.path,
          //   };
          //
          //   if (newMedia) {
          //     groupMessages[msgIndex] = {
          //       ...groupMessages[msgIndex],
          //       images: [
          //         ...groupMessages[msgIndex].images,
          //         newMedia,
          //       ],
          //     }
          //   }
          // } else {
          //   groupMessages = [
          //     ...groupMessages,
          //     {
          //       ...msg,
          //       ...(msg.media ? {
          //         images: []
          //       } : {}),
          //       ...(msg.media?.uploaded ? {
          //           images: [{
          //             src: msg.media.uploaded?.path,
          //             url: msg.media.uploaded?.path,
          //           }]
          //         } : {}
          //       ),
          //     }
          //   ];
          // }

          // lastGroupMsg = msg.grouped_id;
        });
      }

      const reminderedMsgIds = res.data.messages?.filter(item => item.has_reminder)
        .map(item => item.id);
      const messages = !isGroup
        ? groupInteractionsByTimezone(res.data.messages, userTimezone)
        : groupInteractionsByTimezone(groupMessages, userTimezone, null, isGroup);

      if (chatType === CHAT_TYPES.CLIENT) {
        dispatch(getOptimizedChatBookings(res.data.messages, true));
      }

      if (isGroup) {
        API.getTelegramWebmasterMsgs(activeGroupInternalId, incomingGroupMessages)
          .then(({ data }) => {
            if (!Array.isArray(data)) {
              const ids = Object.keys(data).map(id => Number(id));

              if (ids) dispatch(setGirlGroupWebmasterMsgs(ids));
            }
          }).catch(console.error);
      }

      if (!!reminderedMsgIds?.length) {
        dispatch({
          type: chatType === CHAT_TYPES.CLIENT ? GET_REMINDED_CLIENTS_MSGS_IDS : GET_REMINDED_GIRLS_MSGS_IDS,
          payload: res.data.messages.filter(item => item.has_reminder).map(item => item.id)
        })
      }

      dispatch({
        type: chatType === CHAT_TYPES.CLIENT
          ? GET_CLIENT_TIMELINE
          : isGroup
            ? GET_GIRL_GROUP_TIMELINE
            : GET_GIRL_TIMELINE,
        payload: {
          ...res.data,
          messages: messages,
          contactId,
          // the telegram api doesn't use pages, so we count them manually for groups
          ...(isGroup ? {
              currentPage: Math.ceil(res.data.count / LIMIT) - Math.floor(0 / LIMIT),
              pageCount: Math.ceil(res.data.count / LIMIT),
            } : {}
          ),
        }
      });
    })
    .then(() => {
      if (query) {
        dispatch({
          type: chatType === CHAT_TYPES.CLIENT
            ? START_SEARCH_CLIENTS_MSGS
            : START_SEARCH_GIRLS_MSGS,
          payload: query
        })
      }
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type:
          chatType === CHAT_TYPES.CLIENT
            ? GET_CLIENT_TIMELINE_ERROR
            : isGroup ? GET_GIRL_GROUP_TIMELINE_ERROR : GET_GIRL_TIMELINE_ERROR,
        payload: contactId
      });
    });
};

export const updateContactTimeline = (contact, page, loadDirection, userTimezone, isGroup, isArchive) => (dispatch, getState) => {
  const offset = getState().girlChats.groupChat.offset + LIMIT;
  const activeGroupInternalId = getState().girlChats.groupChat.internalId;
  const activeGroupType = getState().girlChats.activeGroupType;

  const request = isGroup
    ? API.getGroupInteractions(contact.id, offset, true, activeGroupType)
    : API.getInteractions(contact.id, page, true, isArchive);

  request
    .then(res => {
      // if we have 10 messages only and offset = 10 we will get []
      if (!res.data.messages.length) {
        if (isArchive) {
          if (contact.type === 1) {
            dispatch({
              type: SET_TIMELINE_CLIENT_ARCHIVE_STATUS,
              payload: true,
            });
          } else {
            dispatch({
              type: SET_TIMELINE_GIRL_ARCHIVE_STATUS,
              payload: true,
            });
          }
        }

        return;
      }

      let groupMessages = [];
      let incomingGroupMessages = [];

      if (isGroup) {
        // let lastGroupMsg = null;
        const groupData = res.data.messages.reverse();

        groupData.forEach((msg) => {
          if (!msg.out) {
            incomingGroupMessages.push(msg.id);
          }

          groupMessages = [
            ...groupMessages,
            {
              ...msg,
              ...(!!msg.media ? {
                  images: [{
                    src: msg.media.path,
                    url: msg.media.path,
                  }]
                } : {}
              ),
            }
          ];

          // if (lastGroupMsg && lastGroupMsg === msg.grouped_id) {
          //   const msgIndex = groupMessages
          //     .findIndex((item) => item.grouped_id === msg.grouped_id);
          //
          //   const newMedia = msg.media?.uploaded && {
          //     src: msg.media.uploaded.path,
          //     url: msg.media.uploaded.path,
          //   };
          //
          //   if (newMedia) {
          //     groupMessages[msgIndex] = {
          //       ...groupMessages[msgIndex],
          //       images: [
          //         ...groupMessages[msgIndex].images,
          //         newMedia,
          //       ],
          //     }
          //   }
          // } else {
          //   groupMessages = [
          //     ...groupMessages,
          //     {
          //       ...msg,
          //       ...(msg.media ? {
          //         images: []
          //       } : {}),
          //       ...(msg.media?.uploaded ? {
          //           images: [{
          //             src: msg.media.uploaded?.path,
          //             url: msg.media.uploaded?.path,
          //           }]
          //         } : {}
          //       ),
          //     }
          //   ];
          // }
          //
          // lastGroupMsg = msg.grouped_id;
        });
      }

      const messages = !isGroup
        ? groupInteractionsByTimezone(res.data.messages, userTimezone)
        : groupInteractionsByTimezone(groupMessages, userTimezone, null, isGroup);

      if (contact.type === CHAT_TYPES.CLIENT) {
        dispatch(getOptimizedChatBookings(res.data.messages, true));
      }

      if (isGroup) {
        API.getTelegramWebmasterMsgs(activeGroupInternalId, incomingGroupMessages)
          .then(({ data }) => {
            if (!Array.isArray(data)) {
              const ids = Object.keys(data).map(id => Number(id));

              if (ids) dispatch(setGirlGroupWebmasterMsgs(ids));
            }
          }).catch(console.error);
      }

      dispatch({
        type: contact.type === 1
          ? UPDATE_CLIENT_TIMELINE
          : isGroup ? UPDATE_GIRL_GROUP_TIMELINE : UPDATE_GIRL_TIMELINE,
        payload: {
          ...res.data,
          messages: messages,
          isArchiveDisplayed: isArchive,
          loadDirection,
          userTimezone,
          // the telegram api doesn't use pages, so we count them manually for groups
          ...(isGroup ? {
              offset: offset,
              currentPage: page,
              pageCount: Math.ceil(res.data.count / LIMIT),
            } : {}
          ),
        }
      });
    })
    .catch(err => console.error(err));
};

export const pinClientMsg = (msg, callerId, callerType) => dispatch => {
  return API.pinClientMsg(msg.id, callerId)
    .then(() => {
      dispatch({
        type: callerType === 1
          ? PIN_CLIENT_MSG
          : PIN_GIRL_MSG,
        payload: msg
      });
    })
    .catch(console.error);
};

export const deletePinnedMessage = (chatId, type) => dispatch => {
  return API.deletePinnedMessage(chatId)
    .then(() => {
      dispatch({
        type: type === 1
          ? DELETE_PINNED_CLIENT_MSG
          : DELETE_PINNED_GIRL_MSG,
        payload: chatId,
      });
    })
    .catch(console.error);
};

export const deleteRemindedMsgId = (type, msgId) => dispatch => {
  dispatch({
    type: type === 1
      ? DELETE_REMINDED_CLIENT_ID
      : DELETE_REMINDED_GIRL_ID,
    payload: msgId,
  });
};

export const addRemindedMsgId = (type, msgId) => dispatch => {
  dispatch({
    type: type === 1
      ? ADD_REMINDED_CLIENT_ID
      : ADD_REMINDED_GIRL_ID,
    payload: msgId,
  });
};


export const changeActivePinnedMessage = (msg, callerType) => dispatch => {
  dispatch({
    type: callerType === 1
      ? CHANGE_ACTIVE_PINNED_CLIENT_MSG
      : CHANGE_ACTIVE_PINNED_GIRL_MSG,
    payload: msg,
  });
};

export const updateActiveContact = (contact, fromMoreTabs = false) => (dispatch, getState) => {
  const chatType = contact.type === CONTACT_TYPES.CLIENT ? CONTACT_TYPES.CLIENT : CONTACT_TYPES.GIRL;
  const recentTabsFromState = selectRecentTabs(getState(), chatType);

  // If the tab is fixed, unfix it when activating a contact with the same ID
  if (recentTabsFromState.visible.includes(contact.id)) {
    const updatedRecentTabs = {
      all: recentTabsFromState.all.filter(tabId => tabId !== contact.id),
      visible: recentTabsFromState.visible.filter(tabId => tabId !== contact.id),
    }

    dispatch(updateRecentTabs(updatedRecentTabs, chatType));
  }

  // Contact in tabs but not active
  dispatch({
    type: contact.type === 1 ? UPDATE_ACTIVE_CLIENT : UPDATE_ACTIVE_GIRL,
    payload: {
      contact,
      fromMoreTabs
    }
  });

  if (contact.type === 1) {
    API.switchSalesSessionByCaller(contact.id)
      .catch(err => console.error(err));
  }
};

export const removeContactTab = (removedId, chatType) => dispatch => {
  chatType === CHAT_TYPES.ROOM
    ? dispatch(onRemoveChatTab(removedId))
    : dispatch(onRemoveContactTab(removedId, chatType)) // else only delete from tabs
};

export const onRemoveContactTab = (removedId, chatType) => dispatch => {
  dispatch({
    type: chatType === CHAT_TYPES.CLIENT ? REMOVE_CLIENT_TAB : REMOVE_GIRL_TAB,
    payload: removedId
  });
};

export const removeContactTabsFromContextMenu = (
  activeTab,
  type,
  query
) => dispatch => {
  dispatch({
    type: type === 1 ? REMOVE_CLIENT_TABS : REMOVE_GIRL_TABS,
    payload: { activeTab, query }
  });
};

export const setClientTabs = (newTabs) => dispatch => {
  dispatch({
    type: SET_CLIENT_TABS,
    payload: newTabs,
  });
}

export const sendEmailMessage = (message, recipient) => dispatch => {
  const { email } = recipient.emails[0]

  return API.sendEmailMessageWithFiles({ config: message, recipient: email })
    .catch(err => {
      console.error(err);

      if (err.response && err.response.data && err.response.data.status === 403) {
        dispatch(openModal(MODAL_TYPES.error, { text: err.response.data.message }));
      }
    });
}

export const sendMessage = (message, recipient) => dispatch => {
  return API.sendMessage(message, recipient)
    .then(() => true)
    .catch(err => {
      console.error(err);

      if (err.response && err.response.data && err.response.data.status === 403) {
        dispatch(openModal(MODAL_TYPES.error, { text: err.response.data.message }));
      }
    });
};

export const newIncomingMessage = (message, userTimezone) => dispatch => {
  dispatch({
    type: NEW_INCOMING_MESSAGE,
    payload: {
      interaction: message,
      userTimezone,
    }
  });

  // showNotification(message, () => dispatch(updateActiveContact(message.caller)));
};

export const newOutgoingMessage = (message, userTimezone) => dispatch => {
  dispatch({
    type: NEW_OUTGOING_MESSAGE,
    payload: {
      interaction: message,
      userTimezone,
    }
  });
};

export const changeCallStatus = call => dispatch => {
  dispatch({
    type: CHANGE_CALL_STATUS,
    payload: call
  });
};

export const changeMessageStatus = call => dispatch => {
  dispatch({
    type: CHANGE_MESSAGE_STATUS,
    payload: call
  });
};

export const markMessageAsUnread = (msg, callerType) => dispatch => {
  if (msg.type !== 4) return;

  return API.markMessageAsUnread(msg.id)
    .then(res => {
      dispatch({
        type:
          callerType === 1 ? UNREAD_CLIENT_MESSAGES : UNREAD_GIRL_MESSAGES,
        payload: {
          interaction: { ...msg, count: res.data, isIamRelated: true },
        }
      });
    })
    .catch(err => console.error(err));
};

export const markContactChatAsRead = contact => dispatch => {
  return API.markContactChatAsRead(contact.id)
    .then(res => {
      dispatch(updateActiveContact(contact));
    })
    .catch(err => console.error(err));
};

export const setMessageReminder = (type, msg, inTime) => async dispatch => {
  if (![3, 4, 9, 10, 13, 14, 18, 19, 20, 21, 22, 23].includes(msg.type)) return;
  try {
    await API.setMessageReminder(msg.caller_id, msg.id, inTime);
    dispatch(addRemindedMsgId(type, msg.id));
  }
  catch (message) {
    return console.error(message);
  }
};

export const removeMessageReminder = (remindId, type, isNotificationEnd = false, isMainTimeline = false) => dispatch => {
  if (!isMainTimeline) {
    if (isNotificationEnd) {
      dispatch({
        type: type === 1
          ? REMOVE_CLIENT_MSG_REMIND
          : REMOVE_GIRL_REMIND_MSG,
        payload: remindId
      });
    }
    // else {
    //   return API.removeMessageReminder(remindId)
    //     .then(() => {
    //       dispatch({
    //         type: type === 1
    //           ? REMOVE_CLIENT_MSG_REMIND
    //           : REMOVE_GIRL_REMIND_MSG,
    //         payload: remindId
    //       });
    //     })
    //     .catch(console.error);
    // }
  } else {
    dispatch({
      type: type === 1
        ? REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE
        : REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE,
      payload: remindId
    })
  }
};

export const newMsgAttachments = attachments => dispatch => {
  dispatch({
    type: NEW_MESSAGE_ATTACHMENTS,
    payload: attachments
  });
};

export const getTimelineMedia = (contact, userTimezone) => dispatch => {
  dispatch({
    type:
      contact.type === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  return API.getConversationMedia(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_CONVERSATION_MEDIA
            : GET_GIRL_CONVERSATION_MEDIA,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.MEDIA,
        }
      });
    })
    .catch(console.error);
};

export const updateContactTimelineMedia = (contact, page, loadDirection, userTimezone, isArchive) => dispatch => {
  return API.getConversationMedia(contact.id, page, isArchive)
    .then(res =>
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_CONVERSATION_MEDIA
            : UPDATE_GIRL_CONVERSATION_MEDIA,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection,
          isArchiveDisplayed: isArchive,
        }
      })
    )
    .catch(console.error);
};

export const cleanTimelineMedia = contactType => dispatch => {
  dispatch({
    type:
      contactType === 1
        ? CLEAN_CLIENT_CONVERSATION_MEDIA
        : CLEAN_GIRL_CONVERSATION_MEDIA
  });
};

export const getContactReminders = (contact, userTimezone) => dispatch => {
  dispatch({
    type:
      contact.type === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  return API.getContactReminders(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_MSG_REMINDERS
            : GET_GIRL_MSG_REMINDERS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.REMINDERS,
        }
      });
    })
    .catch(console.error);
};

export const cleanContactReminders = contactType => dispatch => {
  dispatch({
    type:
      contactType === 1
        ? CLEAN_CLIENT_MSG_REMINDERS
        : CLEAN_GIRL_MSG_REMINDERS
  });
};


export const updateContactReminders = (contact, page, loadDirection, userTimezone) => dispatch => {
  return API.getContactReminders(contact.id, page)
    .then(res =>
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_MSG_REMINDERS
            : UPDATE_GIRL_MSG_REMINDERS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      })
    )
    .catch(console.error);
};


export const getContactMessageContext = (contextMsgId, type, contact = null, query = "", userTimezone) => async dispatch => {
  let contactType = contact ? contact.type : type;

  // TODO: pass instead int array and check like type.includes(contact.type)
  if (contactType === 3 || contactType === 4 || contactType === 5) {
    contactType = 2;
  }

  // cleanContactMsgContext
  if (!contextMsgId) {
    if (query) {
      return dispatch(searchContactMessage(query, contact));
    }
    return dispatch({
      type: contactType === 1 ? CLEAN_CLIENT_MSG_CONTEXT : CLEAN_GIRL_MSG_CONTEXT
    });
  }

  // if contact passed it is globalMsgSearch so we need update contact => get contact timeline => make search if query passed => and get contact msg context
  if (contact) {
    dispatch({
      type: contactType === 1 ? UPDATE_ACTIVE_CLIENT : UPDATE_ACTIVE_GIRL,
      payload: {
        contact,
        isGlobalMsgSearch: true,
        showSearchQuery: contactType !== type
      }
    });

    // search contact message if query passed
    // if (query) {
    //   await dispatch(searchContactMessage(query, contact));
    // }

  } else {
    dispatch({
      type:
        contactType === 1
          ? GET_CLIENT_TIMELINE_PENDING
          : GET_GIRL_TIMELINE_PENDING
    });
  }

  API.getMessageContext(contextMsgId)
    .then(res => {
      dispatch({
        type: contactType === 1 ? GET_CLIENT_MSG_CONTEXT : GET_GIRL_MSG_CONTEXT,
        payload: {
          contextMsgId,
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone)
        }
      });
    })
    .catch(console.error);
};

export const updateContactMessageContext = (contextMsgId, page, loadDirection, contact, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? SET_CLIENT_UPDATE_PENDING
      : SET_GIRL_UPDATE_PENDING,
    payload: true,
  });

  API.getMessageContext(contextMsgId, page)
    .then(res => {
      dispatch({
        type: contact.type === 1 ? UPDATE_CLIENT_MSG_CONTEXT : UPDATE_GIRL_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(console.error);
}

export const createContactChat = () => ({ type: CREATE_NEW_CHAT });

export const fixClientTab = (tabId) => ({ type: FIX_CLIENT_TAB, payload: tabId });

export const initUnfixedClientTab = (tabId) => ({ type: INIT_UNFIXED_CLIENT_TAB, payload: tabId });

export const searchContactMessage = (query, contact, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  return API.searchContactMessage(contact.id, query)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? GET_SEARCHED_CLIENT_MESSAGES
          : GET_SEARCHED_GIRL_MESSAGES,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          query
        }
      });
    })
    .catch(err => console.error(err));
};

export const updateContactMessageSearch = (contact, page, loadDirection, query, userTimezone, isArchive) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? SET_CLIENT_UPDATE_PENDING
      : SET_GIRL_UPDATE_PENDING,
    payload: true,
  });

  API.searchContactMessage(contact.id, query, page, isArchive)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? UPDATE_SEARCHED_CLIENT_MESSAGES
          : UPDATE_SEARCHED_GIRL_MESSAGES,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection,
          isArchiveDisplayed: isArchive,
        }
      });
    })
    .catch(err => console.error(err));
};

export const searchGlobalContactMessage = (query, contact, userTimezone) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  API.searchContactMessage('', query)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? GLOBAL_CLIENT_MESSAGE_SEARCH
          : GLOBAL_GIRL_MESSAGE_SEARCH,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          query
        }
      });
    })
    .catch(err => console.error(err));
};

export const updateGlobalContactMessageSearch = (contact, page, query, loadDirection, userTimezone, isArchive) => (dispatch, getState) => {
  dispatch({
    type: contact.type === 1
      ? SET_CLIENT_UPDATE_PENDING
      : SET_GIRL_UPDATE_PENDING,
    payload: true,
  });

  API.searchContactMessage('', query, page, isArchive)
    .then(res => {
      dispatch({
        type: contact.type === 1
          ? UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH
          : UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection,
          isArchiveDisplayed: isArchive,
        }
      });
    })
    .catch(err => console.error(err));
};

export const stopContactMessageSearch = (contact, status) => dispatch => {
  dispatch({
    type: contact.type === 1
      ? STOP_SEARCH_CLIENT_MESSAGES
      : STOP_SEARCH_GIRL_MESSAGES,
    payload: status
  });
};

export const getContactDateMsgContext = (contextDate, contactId, chatType, userTimezone) => dispatch => {
  dispatch({
    type: chatType === 1
      ? GET_CLIENT_TIMELINE_PENDING
      : GET_GIRL_TIMELINE_PENDING
  })

  return API.getContactDateMsgContext(contextDate, contactId)
    .then(res => {
      dispatch({
        type: chatType === 1 ? GET_CLIENT_DATE_MSG_CONTEXT : GET_GIRL_DATE_MSG_CONTEXT,
        // payload: res.data
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone)
        }
      });
    })
}

export const updateContactDateMsgContext = (contact, page, loadDirection, contextDate, userTimezone) => dispatch => {
  const contextUTCDateByLocalTimezoneOffset = new Date(contextDate) - new Date().getTimezoneOffset() * 60000;

  return API.getContactDateMsgContext(contextUTCDateByLocalTimezoneOffset, contact.id, page)
    .then(res => {
      // if we have 10 messages only and offset = 10 we will get []
      if (!res.data.messages.length) return;

      dispatch({
        type: contact.type === 1
          ? UPDATE_CLIENT_DATE_MSG_CONTEXT
          : UPDATE_GIRL_DATE_MSG_CONTEXT,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    });
}

export const cleanContactDateMsgContext = chatType => dispatch => {
  dispatch({
    type:
      chatType === 1
        ? CLEAN_CLIENT_DATE_MSG_CONTEXT
        : CLEAN_GIRL_DATE_MSG_CONTEXT
  });
};

export const getScheduledMsgs = (contact, userTimezone) => dispatch => {
  dispatch({
    type:
      contact.type === 1
        ? GET_CLIENT_TIMELINE_PENDING
        : GET_GIRL_TIMELINE_PENDING
  });

  return API.getContactScheduledMsgs(contact.id)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? GET_CLIENT_SCHEDULED_MSGS
            : GET_GIRL_SCHEDULED_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.SCHEDULED_MSGS,
        }
      });
    })
    .catch(console.error);
}

export const updateScheduledMsgs = (contact, page, loadDirection, userTimezone) => dispatch => {
  return API.getContactScheduledMsgs(contact.id, page)
    .then(res => {
      dispatch({
        type:
          contact.type === 1
            ? UPDATE_CLIENT_SCHEDULED_MSGS
            : UPDATE_GIRL_SCHEDULED_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection
        }
      });
    })
    .catch(console.error);
}

export const cleanScheduledMsgs = chatType => dispatch => {
  dispatch({
    type:
      chatType === 1
        ? CLEAN_CLIENT_SCHEDULED_MSGS
        : CLEAN_GIRL_SCHEDULED_MSGS
  });
};

export const newScheduledMsg = (msg, userTimezone) => dispatch => {
  dispatch({
    type: NEW_SCHEDULED_MESSAGE,
    payload: {
      interaction: msg,
      userTimezone
    }
  });
};

export const updateContactMsg = (msg) => dispatch => {
  dispatch({
    type: msg.caller.type === CONTACT_TYPES.CLIENT
      ? UPDATE_CLIENT_MSG
      : UPDATE_GIRL_MSG,
    payload: msg,
  });
};

export const deleteContactMsg = (msg) => dispatch => {
  dispatch({
    type: msg.caller.type === CONTACT_TYPES.CLIENT
      ? DELETE_CLIENT_MSG
      : DELETE_GIRL_MSG,
    payload: msg,
  });
};

export const getScheduledMsgsCount = (contactId, contactType) => dispatch => {
  return API.getScheduledMsgsCount(contactId)
    .then(res => {
      dispatch({
        type: contactType === CHAT_TYPES.CLIENT
          ? GET_CLIENT_SCHEDULED_MSGS_COUNT
          : GET_GIRL_SCHEDULED_MSGS_COUNT,
        payload: res.data,
      })
    });
};

export const startSearchContactsMsgsInChat = (chatType, query) => dispatch => {
  dispatch({
    type: chatType === CHAT_TYPES.CLIENT
      ? START_SEARCH_CLIENTS_MSGS
      : START_SEARCH_GIRLS_MSGS,
    payload: query
  });
};

export const deleteTelegramMsgRequest = id => dispatch => {
  return API.deleteTelegramMsg(id)
    .catch(err => {
      console.error(err);

      if (err.response?.data?.status === 403) {
        dispatch(openModal(MODAL_TYPES.error, { text: err.response.data.message }));
      }
    });
};

export const deleteTelegramMsg = payload => ({
  type: DELETE_TELEGRAM_MSG,
  payload,
});

export const getOptimizedChatBookings = (messages, isNotFull = true) => (dispatch, getState) => {
  const existingBookingIds = Object.keys(getState().bookings.entities);
  const bookingIds = [];

  messages.forEach((message) => {
    const booking = message.attachments?.chatUpdate?.bookingId;
    const bookingId = Array.isArray(booking) ? booking[0] : booking;

    if (bookingId && !existingBookingIds.includes(String(bookingId))) {
      bookingIds.push(bookingId);
    }
  });

  dispatch({ type: SET_CHAT_BOOKINGS_PENDING });

  if (bookingIds.length) {
    return API.getOptimizedChatBookings(bookingIds)
      .then(({ data: bookings }) => {
        const optBookingsList = bookings?.map((item) => convertToOptimizeBooking(item, isNotFull)) || [];

        dispatch({
          type: UPDATE_BOOKINGS,
          payload: optBookingsList,
        });

        return optBookingsList;
      })
      .then((bookings) => {
        const profileIds = bookings.filter(isAbsentProfileInformation)
          .flatMap((booking) => [Number(booking.profile_id), Number(booking.profile_id_duo)])
          .filter(Number) || [];

        if (profileIds.length) {
          dispatch(getActiveDivaGirls({ params: { 'filter-profileid': profileIds } }))
        }
      })
      .catch(console.error)
  }
};

const initialState = {
  active: null,

  tabs: [],
  unfixedTab: null,
  recentTabs: {
    all: [], // all recent tabs we worked with
    visible: [], // tabs that can be returned by undo button.
  },

  pinnedMsgs: [],
  activePinnedMsgs: null,

  timelinePending: false,
  updatePending: false,

  timeline: [],
  timelinePageCount: null,
  timelineLowerLoadedPage: null,
  timelineCurrentPage: null,
  newInteractionType: null,
  remindersMsgsIds: [],

  search: '',
  isGlobalMsgSearch: false,
  showSearchQuery: false,

  contextMsgId: null,
  contextDate: null,

  chatSource: CHAT_SOURCES.MSGS,

  scheduledMsgsCount: 0,
  remindersCount: 0,

  bookings: [],
  bookingDetails: [],
  callerBookingPending: false,

  auxiliaryTimeline: [],
  auxiliaryPageCount: null,
  auxiliaryLowerLoadedPage: null,
  auxiliaryHigherLoadedPage: null,
  auxiliaryCurrentPage: null,

  isArchiveDisplayed: false,
  isAuxiliaryArchiveDisplayed: false,

  hasArchive: false,
  auxiliaryHasArchive: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_UPDATE_PENDING:
      return {
        ...state,
        updatePending: action.payload,
      };

    case GET_CLIENTS_FROM_TABS: {
      const getUpdatedActive = () => {
        if (action.payload.ids.includes(action.payload.active)) {
          // if we already have our active contact
          return action.payload.active;
        } else if (
          !!action.payload.ids.length &&
          !action.payload.ids.includes(action.payload.active)
        ) {
          // if our active has been changed => chose new active from tabs
          return action.payload.ids[0];
        } else return null;
      };

      const getUpdatedUnfixedTab = () => {
        if (state.unfixedTab && action.payload.ids.includes(state.unfixedTab)) {
          return state.unfixedTab
        }
        // if we have unfixedTab that was removed from server
        else {
          return null
        }
      }

      return {
        ...state,
        tabs: action.payload.ids,
        active: getUpdatedActive(),
        unfixedTab: getUpdatedUnfixedTab()
      };
    }
    case INIT_UNFIXED_CLIENT_TAB: {
      return {
        ...state,
        unfixedTab: action.payload
      }
    }

    case FIX_CLIENT_TAB: {
      if (action.payload !== state.unfixedTab) return state;

      return {
        ...state,
        unfixedTab: null,
      }
    }
    case UPDATE_ACTIVE_CLIENT: {
      // if we mark msgs as read
      const clientId = action.payload.contact.id;
      if (clientId === 'new_chat') {
        return {
          ...state,
          active: "new_chat",
          timeline: [],
          timelinePageCount: 1,
          timelineLowerLoadedPage: 1,
          timelineCurrentPage: 1,
        }
      }
      if (clientId === state.active && !action.payload.showSearchQuery && !action.payload.fromMoreTabs) {
        return state;
      }
      if (clientId === state.active && action.payload.showSearchQuery) {
        return {
          ...state,
          isGlobalMsgSearch: action.payload.isGlobalMsgSearch ? true : false,
          showSearchQuery: action.payload.showSearchQuery ? true : false,
          search: action.payload.isGlobalMsgSearch ? state.search : '',
        };
      }

      const isInTabs = state.tabs.indexOf(clientId) !== -1;

      let updatedTabs = [...state.tabs];

      let updatedUnfixedTab = state.unfixedTab;

      if (action.payload.fromMoreTabs) {
        updatedTabs = [clientId, ...state.tabs.filter(id => id !== clientId)];

        if (clientId === state.active) {
          return {
            ...state,
            tabs: updatedTabs,
          }
        }
      } else if (!isInTabs) {
        if (state.unfixedTab) { //if tab not fixed replace her by action.payload.chatId
          if (updatedTabs.length === 20) { // limit - 20 msgs;
            updatedTabs[19] = clientId;
          } else {
            updatedTabs = updatedTabs.map(id => {
              if (id === state.unfixedTab) {
                return clientId;
              }
              return id;
            });
          }
        } else {
          if (updatedTabs.length === 20) { // limit - 20 msgs;
            updatedTabs[19] = clientId;
          } else {
            updatedTabs.push(clientId);
          }
        }

        updatedUnfixedTab = clientId;
      }

      return {
        ...state,
        tabs: updatedTabs,
        unfixedTab: updatedUnfixedTab,
        active: clientId,

        pinnedMsgs: [],
        activePinnedMsgs: null,

        timeline: [],
        timelinePageCount: null,
        timelineLowerLoadedPage: null,
        timelineCurrentPage: null,

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,

        contextMsgId: null,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,
        scheduledMsgsCount: 0,

        isGlobalMsgSearch: action.payload.isGlobalMsgSearch ? true : false,
        showSearchQuery: action.payload.showSearchQuery ? true : false,
        search: action.payload.isGlobalMsgSearch ? state.search : '',

        isArchiveDisplayed: false,
      };
    }
    case START_SEARCH_CLIENTS_MSGS: {
      return {
        ...state,
        timelinePending: true,
        showSearchQuery: true,
        search: action.payload
      }
    }
    case GET_CLIENT_TIMELINE_PENDING: {
      return {
        ...state,
        timelinePending: true,
        // auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        // auxiliaryCurrentPage: null,
        // auxiliaryPageCount: null,
        // auxiliaryLowerLoadedPage: null
      };
    }
    case GET_CLIENT_TIMELINE: {
      if (action.payload.contactId === "new_chat") {
        return state;
      }
      // additional check
      if (action.payload.contactId !== state.active) {
        return state;
      }
      if (!action.payload.messages.length) {
        return {
          ...state,
          timelinePending: false,
          remindersCount: action.payload.remindersCount,
          timelinePageCount: action.payload.pageCount,
          timelineLowerLoadedPage: action.payload.currentPage,
          timelineCurrentPage: action.payload.currentPage,
          bookingDetails: action.payload.bookingDetails,
          hasArchive: action.payload.has_archive,
        };
      }


      return {
        ...state,
        timelinePending: false,
        timeline: action.payload.messages,
        timelinePageCount: action.payload.pageCount,
        timelineLowerLoadedPage: action.payload.currentPage,
        timelineCurrentPage: action.payload.currentPage,
        newInteractionType: null,
        pinnedMsgs: action.payload.pinnedMessage,
        remindersCount: action.payload.remindersCount,
        activePinnedMsgs: action.payload.pinnedMessage[action.payload.pinnedMessage.length - 1],

        search: '',
        isGlobalMsgSearch: false,
        showSearchQuery: false,

        // contextMsgId: null,
        contextDate: null,
        // chatSource: CHAT_SOURCES.MSGS,

        bookingDetails: action.payload.bookingDetails,

        hasArchive: action.payload.has_archive,
      };
    }
    case UPDATE_CLIENT_TIMELINE: {
      return {
        ...state,
        timeline: onUpdateTimeline(state.timeline,
          action.payload.messages,
          action.payload.loadDirection,
          action.payload.userTimezone),
        timelineCurrentPage: action.payload.currentPage,
        // do not update page count if we scroll to top because new msgs can update it dynamically
        timelinePageCount: action.payload.loadDirection === 'up'
          ? state.timelinePageCount
          : action.payload.pageCount,
        newInteractionType: null,
        ...(action.payload.isArchiveDisplayed ? { isArchiveDisplayed: true } : {}),
      }
    }
    case SET_TIMELINE_CLIENT_ARCHIVE_STATUS: {
      return {
        ...state,
        isArchiveDisplayed: action.payload,
      }
    }
    case REMOVE_CLIENT_REMINDER_FROM_MAIN_TIMELINE: {
      const updatedTimeline = [...state.timeline];
      const updatedLastGroup = updatedTimeline[updatedTimeline.length - 1]
        .filter(interaction => interaction.type !== 7 && interaction.id !== action.payload);

      updatedTimeline[updatedTimeline.length - 1] = updatedLastGroup;

      return {
        ...state,
        timeline: updatedTimeline,
      }
    }
    case REMOVE_CLIENT_TAB: {
      const updatedActive = onRemoveTabUpdateActiveContact(
        state.tabs,
        state.active,
        action.payload
      )

      if (updatedActive === 'new_chat') {
        return {
          ...state,
          timeline: [],
          tabs: onRemoveTab(state.tabs, action.payload),
          unfixedTab: action.payload === state.unfixedTab ? null : state.unfixedTab,
          active: updatedActive,
          timelinePageCount: 1,
          timelineLowerLoadedPage: 1,
          timelineCurrentPage: 1,
        }
      }

      return {
        ...state,
        timeline: action.payload === state.active ? [] : state.timeline,
        tabs: onRemoveTab(state.tabs, action.payload),
        unfixedTab: action.payload === state.unfixedTab ? null : state.unfixedTab,
        active: updatedActive,
        bookingDetails: [],
      };
    }
    case REMOVE_CLIENT_TABS: {
      let updatedTimeline = [];
      let updatedActive = null;

      if (
        state.active === action.payload.activeTab &&
        action.payload.query !== "all"
      ) {
        updatedTimeline = state.timeline;
        updatedActive = state.active;
      } else if (
        state.active !== action.payload.activeTab &&
        action.payload.query !== "all"
      ) {
        updatedActive = action.payload.activeTab;
      }

      return {
        ...state,
        active: updatedActive,
        timeline: updatedTimeline,
        tabs: onRemoveTabs(state.tabs, action.payload)
      };
    }
    case SET_CLIENT_TABS: {
      const prevIndexOfUnfixedTab = state.tabs.indexOf(state.unfixedTab);
      const newIndexOfUnfixedTab = action.payload.indexOf(state.unfixedTab);
      const isUnfixedTabMoved = newIndexOfUnfixedTab === -1 || newIndexOfUnfixedTab !== prevIndexOfUnfixedTab;
      return {
        ...state,
        tabs: action.payload,
        ...(isUnfixedTabMoved ? { unfixedTab: null } : {})
      }
    }
    case NEW_OUTGOING_CALL:
    case NEW_INCOMING_QUEUE_CALL:
    case NEW_INCOMING_CALL:
    case NEW_INCOMING_MESSAGE:
    case NEW_OUTGOING_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;
      const newInteraction = { ...action.payload.interaction, isNew: true };

      if (state.active === contactId) {
        return {
          ...state,
          timeline: onInteraction(state.timeline, newInteraction, action.payload.userTimezone),
          newInteractionType: newInteraction.type
        };
      }
      if (action.type === NEW_INCOMING_MESSAGE && state.tabs.includes(contactId)) {
        const updatedTabs = state.tabs.filter(id => id !== contactId);

        updatedTabs.unshift(contactId);

        return {
          ...state,
          tabs: updatedTabs,
        }
      }
      return state;
    }
    case NEW_SCHEDULED_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;

      if (state.active === contactId && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        return {
          ...state,
          auxiliaryTimeline: onInteraction(state.auxiliaryTimeline,
            action.payload.interaction,
            action.payload.userTimezone),
          newInteractionType: action.payload.interaction.type,
          scheduledMsgsCount: state.scheduledMsgsCount + 1
        };
      }
      return state;
    }
    case UPDATE_CLIENT: {
      if (action.payload.isTypeChanged && action.payload.wasContactInTabs) {
        const updatedContactId = action.payload.ids
          ? action.payload.ids[0]
          : action.payload.pinIds[0];

        const updatedTabs = onRemoveTab(state.tabs, updatedContactId);
        let updatedActive = state.active;
        let updatedTimeline = state.timeline;

        if (action.payload.isActive) {
          updatedActive = onRemoveTabUpdateActiveContact(
            state.tabs,
            state.active,
            updatedContactId
          );
          updatedTimeline = [];
        }

        const updatedUnfixedTab = state.unfixedTab === updatedContactId
          ? null
          : state.unfixedTab;

        return {
          ...state,
          active: updatedActive,
          tabs: updatedTabs,
          timeline: updatedTimeline,
          unfixedTab: updatedUnfixedTab
        };
      }
      return state;
    }
    case UPDATE_GIRL: {
      if (action.payload.isTypeChanged && action.payload.wasContactInTabs) {
        const updatedEntities = action.payload.entities;
        const updatedContactId = action.payload.ids
          ? action.payload.ids[0]
          : action.payload.pinIds[0];

        const isTypeChangedToClient = updatedEntities[updatedContactId].type === 1;

        if (isTypeChangedToClient) {
          const updatedTabs = [...state.tabs, updatedContactId];

          let updatedActive = state.active ? state.active : updatedContactId;

          let updatedTimeline = state.active ? state.timeline : [];

          if (action.payload.isActive) {
            updatedTimeline = [];
            updatedActive = updatedContactId;
          }

          return {
            ...state,
            active: updatedActive,
            tabs: updatedTabs,
            timeline: updatedTimeline
          };
        }

        return state;
      }

      return state;
    }
    case REMOVE_CLIENT: {
      const isContactInTabs =
        state.tabs.indexOf(action.payload) === -1 ? false : true;

      if (!isContactInTabs) {
        // if contact not in tabs return
        return state;
      }

      const updatedTabs = onRemoveTab(state.tabs, action.payload);
      let updatedActive = state.active;
      let updatedTimeline = state.timeline;

      if (state.active === action.payload) {
        updatedActive = onRemoveTabUpdateActiveContact(
          state.tabs,
          state.active,
          action.payload
        );
        updatedTimeline = [];
      }

      const updatedUnfixedTab = state.unfixedTab === action.payload
        ? null
        : state.unfixedTab;

      return {
        ...state,
        active: updatedActive,
        tabs: updatedTabs,
        timeline: updatedTimeline,
        unfixedTab: updatedUnfixedTab
      };
    }
    case CHANGE_MESSAGE_STATUS:
    case CHANGE_CALL_STATUS: {
      if (
        state.active &&
        (state.active === action.payload.caller_id || // if smsStatus
          state.active === action.payload.callerId)
      ) {
        // if callStatus
        return {
          ...state,
          timeline: onStatusChange(state.timeline, action.payload)
        };
      }
      return state;
    }
    case NEW_MESSAGE_ATTACHMENTS: {
      if (state.active && state.active === action.payload.caller_id) {
        return {
          ...state,
          timeline: onAttachmentsAdding(state.timeline, action.payload),
          newInteractionType: INTERACTION_TYPES.MSG_ATTACHMENT
        };
      }
      return state;
    }
    case UNREAD_CLIENT_MESSAGES: {
      return {
        ...state,
        timeline: updateUnreadMsgsInTimeline(state.timeline, action.payload.interaction)
      };
    }

    case GET_CLIENT_MSG_CONTEXT: {
      return {
        ...state,
        timelinePending: false,

        isGlobalMsgSearch: false,
        showSearchQuery: false,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,

        contextMsgId: action.payload.contextMsgId,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages
      };
    }

    case CHANGE_CHAT_SOURCE_CLIENT: {
      return {
        ...state,
        chatSource: action.payload,
        isAuxiliaryArchiveDisplayed: false,
      };
    }

    case UPDATE_CLIENT_MSG_CONTEXT: {
      //exit from context
      // if (action.payload.currentPage === action.payload.pageCount && action.payload.loadDirection === 'down') {
      //   return {
      //     ...state,
      //     timeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
      //     timelinePageCount: action.payload.pageCount,
      //     timelineLowerLoadedPage: action.payload.currentPage,
      //     timelineCurrentPage: state.auxiliaryHigherLoadedPage,

      //     auxiliaryCurrentPage: null,
      //     auxiliaryLowerLoadedPage: null,
      //     auxiliaryHigherLoadedPage: null,
      //     auxiliaryPageCount: null,
      //     auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      //     contextMsgId: null,
      //     search: '',
      //   }
      // }

      return {
        ...state,
        updatePending: false,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline,
          action.payload.messages,
          action.payload.loadDirection,
          action.payload.userTimezone),
        auxiliaryCurrentPage: action.payload.currentPage,

        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
      }
    }

    case CLEAN_CLIENT_MSG_CONTEXT: {
      return {
        ...state,
        contextMsgId: null,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      }
    }

    case GET_CLIENT_DATE_MSG_CONTEXT: {
      return {
        ...state,
        timelinePending: false,

        search: '',
        contextMsgId: null,
        chatSource: CHAT_SOURCES.MSGS,

        contextDate: action.payload.contextDate,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages
      }
    }

    case UPDATE_CLIENT_DATE_MSG_CONTEXT: {
      // if (action.payload.currentPage === action.payload.pageCount && action.payload.loadDirection === 'down') {
      //   return {
      //     ...state,
      //     timeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection),
      //     timelinePageCount: action.payload.pageCount,
      //     timelineLowerLoadedPage: action.payload.currentPage,
      //     timelineCurrentPage: state.auxiliaryHigherLoadedPage,

      //     auxiliaryCurrentPage: null,
      //     auxiliaryLowerLoadedPage: null,
      //     auxiliaryHigherLoadedPage: null,
      //     auxiliaryPageCount: null,
      //     auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      //     contextDate: null,
      //     search: '',
      //   }
      // }
      return {
        ...state,

        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline,
          action.payload.messages,
          action.payload.loadDirection,
          action.payload.userTimezone),
        auxiliaryCurrentPage: action.payload.currentPage,

        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
      }
    }

    case CLEAN_CLIENT_DATE_MSG_CONTEXT: {
      return {
        ...state,
        contextDate: null,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
      }
    }
    case GET_SEARCHED_CLIENT_MESSAGES: {
      return {
        ...state,
        search: action.payload.query,
        timelinePending: false,

        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages,

        contextMsgId: null,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,
        isGlobalMsgSearch: state.isGlobalMsgSearch
          ? false
          : state.isGlobalMsgSearch,

        isAuxiliaryArchiveDisplayed: false,
      };
    }

    case UPDATE_GLOBAL_CLIENT_MESSAGE_SEARCH:
    case UPDATE_SEARCHED_CLIENT_MESSAGES: {
      return {
        ...state,
        // searchedMessages: onUpdateTimeline(state.searchedMessages, action.payload.messages, action.payload.loadDirection),
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.pageCount,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline,
          action.payload.messages,
          action.payload.loadDirection,
          action.payload.userTimezone),
        auxiliaryPageCount: action.payload.pageCount,
        updatePending: false,
        ...(action.payload.isArchiveDisplayed ? { isAuxiliaryArchiveDisplayed: true } : {}),
      }
    }

    case STOP_SEARCH_CLIENT_MESSAGES: {
      return {
        ...state,
        search: '',

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        auxiliaryPageCount: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryCurrentPage: null,

        contextMsgId: null,
        isGlobalMsgSearch: false
      }
    }

    case GLOBAL_CLIENT_MESSAGE_SEARCH: {
      return {
        ...state,
        isGlobalMsgSearch: true,
        search: action.payload.query,
        chatSource: CHAT_SOURCES.MSGS,

        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages,
        timelinePending: false,

        isAuxiliaryArchiveDisplayed: false,
      };
    }

    case PIN_CLIENT_MSG: {
      return {
        ...state,
        pinnedMsgs: [...state.pinnedMsgs, action.payload],
        activePinnedMsgs: action.payload
      }
    }

    case CHANGE_ACTIVE_PINNED_CLIENT_MSG: {
      return {
        ...state,
        activePinnedMsgs: action.payload || state.pinnedMsgs[state.pinnedMsgs.length - 1]
      }
    }

    case DELETE_PINNED_CLIENT_MSG: {
      const filteredPinnedMsgs = state.pinnedMsgs.filter((el) => el.id !== action.payload);

      return {
        ...state,
        pinnedMsgs: filteredPinnedMsgs,
        activePinnedMsgs: filteredPinnedMsgs[filteredPinnedMsgs.length - 1],
      }
    }

    case NEW_MISSED_CALL: {
      const isActive = state.active === +action.payload.id.split('_')[0];

      if (!isActive) return state;

      const newTimeline = isActive && state.timeline.map(group => {
        return group.map(msg => {
          if (msg.id === action.payload.entity.id) {
            return action.payload.entity;
          }
          return msg;
        });
      });

      return {
        ...state,
        timeline: newTimeline
      }
    }

    case UPDATE_VOICEMAIL_STATUS: {
      const isActive = state.active === action.payload.callerId

      const newTimeline = isActive && state.timeline.map(group => {
        return group.map(msg => {
          if (msg.id === action.payload.callId) {
            return { ...msg, voicemail: action.payload.voicemail };
          }
          return msg;
        });
      });

      return {
        ...state,
        timeline: isActive
          ? newTimeline
          : state.timeline
      }
    }

    case GET_CLIENT_CONVERSATION_MEDIA: 
    case GET_CLIENT_SCHEDULED_MSGS:
    case GET_CLIENT_MSG_REMINDERS: {
      return {
        ...state,
        timelinePending: false,
        contextDate: null,
        chatSource: action.payload.chatSource,

        auxiliaryTimeline: action.payload.messages,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryHasArchive: action.payload.has_archive ? action.payload.has_archive : state.auxiliaryHasArchive,
      };
    }

    case UPDATE_CLIENT_CONVERSATION_MEDIA:
    case UPDATE_CLIENT_SCHEDULED_MSGS:
    case UPDATE_CLIENT_MSG_REMINDERS: {
      return {
        ...state,

        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline,
          action.payload.messages,
          action.payload.loadDirection,
          action.payload.userTimezone),
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryCurrentPage: action.payload.currentPage,
        ...(action.payload.isArchiveDisplayed ? { isAuxiliaryArchiveDisplayed: true } : {}),
      };
    }

    case CLEAN_CLIENT_CONVERSATION_MEDIA:
    case CLEAN_CLIENT_SCHEDULED_MSGS:
    case CLEAN_CLIENT_MSG_REMINDERS: {
      if (state.search) {
        return state;
      }

      return {
        ...state,

        // chatSource: CHAT_SOURCES.MSGS,

        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
      }
    }

    case CREATE_NEW_CHAT: {
      if (state.tabs.includes("new_chat")) {
        return {
          ...state,
          active: "new_chat",
          timeline: [],
          timelinePageCount: 1,
          timelineLowerLoadedPage: 1,
          timelineCurrentPage: 1,
        }
      }
      return {
        ...state,
        active: "new_chat",
        tabs: [...state.tabs, "new_chat"],
        timeline: [],


        timelinePageCount: 1,
        timelineLowerLoadedPage: 1,
        timelineCurrentPage: 1,
      };
    }

    case REMOVE_CLIENT_MSG_REMIND: {
      const updatedReminders = [];

      state.auxiliaryTimeline.forEach(remindersByDate => {
        const updatedRemindersByDate = remindersByDate.filter(remind => remind.id !== action.payload);

        if (updatedRemindersByDate.length) {
          updatedReminders.push(updatedRemindersByDate);
        }
      })

      return {
        ...state,
        remindersCount: state.remindersCount - 1,
        auxiliaryTimeline: updatedReminders
      }
    }

    case UPDATE_CLIENT_MSG: {
      let updatedTimeline = 'timeline';

      if (action.payload.type === 11 && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        updatedTimeline = 'auxiliaryTimeline'
      }

      return {
        ...state,
        [updatedTimeline]: state[updatedTimeline].map(group => {
          return group.map(msg => {
            if (msg.id === action.payload.id) {
              return {
                ...msg,
                body: action.payload.body,
                edited: action.payload.edited || true,
                date: action.payload.date ? action.payload.date : null,
                hasUrls: action.payload.hasUrls || msg.hasUrls,
                attachments: action.payload.attachments,
                ...((action.payload.type === 15 || action.payload.type === 16) ? {
                  status: action.payload.status || msg.status,
                  duration: action.payload.duration || msg.duration,
                } : {}),
                ...((
                  action.payload.type === INTERACTION_TYPES.OUTGOING_MSG_IPHONE_SMS ||
                  action.payload.type === INTERACTION_TYPES.OUTGOING_MSG_IPHONE_IMESSAGE ||
                  action.payload.type === INTERACTION_TYPES.OUTGOING_MSG_WHATSAPP
                ) ? {
                  status: action.payload.status || msg.status,
                  type: action.payload.type || msg.type,
                  isRead: action.payload.isRead || msg.isRead,
                } : {}),
              };
            }
            return msg;
          });
        }),
      };
    }

    case GET_REMINDED_CLIENTS_MSGS_IDS: {
      const remindersMsgsIds = action.payload;

      return {
        ...state,
        remindersMsgsIds
      }
    }

    case DELETE_REMINDED_CLIENT_ID: {
      const remindersMsgsIds = state.remindersMsgsIds;
      const deletedItemIdx = state.remindersMsgsIds.indexOf(action.payload);

      if (deletedItemIdx !== -1) {
        remindersMsgsIds.splice(deletedItemIdx, 1);
      }

      return {
        ...state,
        remindersMsgsIds
      }
    }

    case ADD_REMINDED_CLIENT_ID: {
      return {
        ...state,
        remindersCount: state.remindersCount + 1,
        remindersMsgsIds: [...state.remindersMsgsIds, action.payload]
      }
    }

    case DELETE_CLIENT_MSG: {
      let updatedTimelineName = 'timeline';

      if (action.payload.type === 11 && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        updatedTimelineName = 'auxiliaryTimeline'
      }

      const updatedTimeline = [];

      state[updatedTimelineName].forEach(group => {
        const updatedGroup = group.filter(msg => msg.id !== action.payload.id);

        if (updatedGroup.length) {
          updatedTimeline.push(updatedGroup);
        }
      })

      return {
        ...state,
        [updatedTimelineName]: updatedTimeline,
        scheduledMsgsCount: action.payload.type === 11 && state.scheduledMsgsCount >= 1
          ? state.scheduledMsgsCount - 1
          : state.scheduledMsgsCount
      };
    }

    case SET_CLIENTS_RECENT_TABS: {
      if (!action.payload) return state;

      return {
        ...state,
        recentTabs: action.payload,
      }
    }

    case GET_CLIENT_SCHEDULED_MSGS_COUNT: {
      if (action.payload === state.scheduledMsgsCount) return state;

      return {
        ...state,
        scheduledMsgsCount: action.payload,
      }
    }

    case DELETE_TELEGRAM_MSG: {
      const { timeline, auxiliaryTimeline } = state;
      const { id } = action.payload;

      return {
        ...state,
        timeline: removeFromTimeline(timeline, id),
        auxiliaryTimeline: removeFromTimeline(auxiliaryTimeline, id),
      };
    }

    case SET_CHAT_BOOKINGS_PENDING: {
      return {
        ...state,
        callerBookingPending: true,
      }
    }

    case ADD_CHAT_BOOKINGS: {
      const { entities } = action.payload;

      return {
        ...state,
        callerBookingPending: false,
        bookings: addToEntitiesIfMissing(
          state.bookings,
          entities,
        ),
      };
    }

    default:
      return state;
  }
};

// Redux helpers start

// Group interactions in array of arrays by userTimezone
// but in redux we save in UTC time which comes from server
export const groupInteractionsByTimezone = (interactions, userTimezone, userId = null, isGroup = false, isOperatorsChat = false) => {
  const groupsByTimezone = {};
  let lastDateCreated = null;

  interactions.forEach((interaction, index, array) => {
    // if rooms interactions
    if (userId) {
      interaction = {
        ...interaction,
        type: formatInteractionType(interaction, userId)
      }
    }

    if (isGroup) {
      interaction = {
        ...interaction,
        dateCreated: interaction.date * 1000,
      }
    }

    if (isOperatorsChat) {
      if (interaction.dateCreated) {
        lastDateCreated = interaction.dateCreated;
      } else {
        if (!lastDateCreated) {
          lastDateCreated = array.slice(index + 1).find(item => !!item.dateCreated);
        }

        interaction = {
          ...interaction,
          dateCreated: lastDateCreated,
        }
      }
    }

    const dateByTimezone = getDateByTimezoneOffset(userTimezone, interaction.dateCreated);
    const groupDate = getShortDate(dateByTimezone);

    const groupByTimezone = groupsByTimezone[groupDate]
      ? groupsByTimezone[groupDate]
      : [];

    groupsByTimezone[groupDate] = [...groupByTimezone, interaction];
  });

  return Object.values(groupsByTimezone);
}

export const onUpdateTimeline = (timeline, newInteractions, loadDirection = 'up', userTimezone) => {
  if (!timeline || !timeline.length) {
    return newInteractions;
  } else if (!newInteractions.length) {
    return timeline;
  }

  if (loadDirection === 'up') {
    const firstInteractionGroup = timeline[0];
    const firstInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone,
      firstInteractionGroup[0].dateCreated));


    const lastNewInteractionGroup = newInteractions[newInteractions.length - 1];
    const lastNewInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone,
      lastNewInteractionGroup[0].dateCreated));

    if (firstInteractionDateByTimezone === lastNewInteractionDateByTimezone) {
      const updatedFirstGroup = [...lastNewInteractionGroup, ...firstInteractionGroup];
      const updatedTimeline = timeline.slice(1);

      return [...newInteractions.slice(0, -1), updatedFirstGroup, ...updatedTimeline];
    } else {
      return [...newInteractions, ...timeline];
    }
  } else {
    const lastInteractionGroup = timeline[timeline.length - 1];
    const lastInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone,
      lastInteractionGroup[0].dateCreated));

    const firstNewInteractionGroup = newInteractions[0];
    const firstNewInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone,
      firstNewInteractionGroup[0].dateCreated));

    if (lastInteractionDateByTimezone === firstNewInteractionDateByTimezone) {
      let updatedLastGroup = [...lastInteractionGroup, ...firstNewInteractionGroup];
      let updatedTimeline = timeline.slice(0, -1);

      return [...updatedTimeline, updatedLastGroup, ...newInteractions.slice(1)];
    } else {
      return [...timeline, ...newInteractions];
    }
  }
};


export const onInteraction = (timeline, newInteraction, userTimezone) => {
  if (timeline.length) {
    const lastInteractionGroup = [...timeline[timeline.length - 1]];
    const lastInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone,
      lastInteractionGroup[0].dateCreated));
    const newInteractionDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone,
      newInteraction.dateCreated));

    if (lastInteractionDateByTimezone === newInteractionDateByTimezone) {
      lastInteractionGroup.push(newInteraction);

      return [...timeline.slice(0, -1), lastInteractionGroup];
    } else {
      return [...timeline, [newInteraction]];
    }
  } else {
    return [[newInteraction]];
  }
};

export const onStatusChange = (activeTimeline, payload) => {
  const lastInteractionGroup = activeTimeline[activeTimeline.length - 1];

  const interactionId = payload.callId ? payload.callId : payload.id;

  const newTimeline = activeTimeline.length
    ? lastInteractionGroup.map(interaction => {
      if (interactionId === interaction.id) {
        return {
          ...interaction,
          status: payload.status,
          duration: payload.duration || null
        }
      } else {
        return interaction
      }
    })
    : [];

  return [...activeTimeline.slice(0, -1), newTimeline];
};

export const updateUnreadMsgsInTimeline = (timeline, firstUnreadMsg) => {
  let unread = false;

  return timeline.map(group =>
    group.map(msg => {
      if (msg.id === firstUnreadMsg.id) {
        unread = true;
      }
      return unread ? { ...msg, isRead: 0 } : msg;
    })
  );
};

export const onAttachmentsAdding = (timeline, payload) => {
  return timeline.map(group =>
    group.map(interaction => {
      return payload.id === interaction.id
        ? {
          ...interaction,
          attachments: payload.attachments
        }
        : interaction;
    })
  );
};


export const onRemoveTab = (tabs, removedTab) =>
  tabs.filter(tab => tab !== removedTab);

export const onRemoveTabUpdateActiveContact = (tabs, active, removedTab) => {
  let newActiveContact;

  if (active === removedTab) {
    // we need to change activeContact
    if (tabs.length === 1) {
      newActiveContact = null;
    } else if (tabs.length >= 2) {
      let idx = tabs.indexOf(removedTab);

      if (idx === 0) {
        newActiveContact = tabs[idx + 1];
      } else {
        newActiveContact = tabs[idx - 1];
      }
    }
  } else {
    newActiveContact = active; // activeContact stays
  }
  return newActiveContact;
};

export const onRemoveTabs = (tabs, { activeTab, query }) => {
  let updatedTabs;

  switch (query) {
    case "all": {
      updatedTabs = [];
      break;
    }
    case "other": {
      updatedTabs = [activeTab];
      break;
    }
    case "right": {
      const idx = tabs.indexOf(activeTab);

      updatedTabs =
        idx + 1 === tabs.length ? tabs : [...tabs].splice(0, idx + 1);
      break;
    }
    case "left": {
      const idx = tabs.indexOf(activeTab);

      updatedTabs = idx === 0 ? tabs : [...tabs].splice(idx);
      break;
    }
    default:
      break;
  }
  return updatedTabs;
};

export const removeFromTimeline = (timeline, delId) => {
  return timeline
    .map(group => group.map(item => {
      return item.id === delId
        ? {
          ...item,
          ...(item.hasOwnProperty("is_deleted") ? { is_deleted: true } : {}),
          ...(item.hasOwnProperty("deleted") ? { deleted: true } : {}),
        }
        : item
    }))
}

export const deleteFromTimeline = (timeline, delId) => {
  return timeline
    .map(group => group.filter(item => item.id !== delId))
    .filter(group => group.length > 0);
}

export const onTaskComplete = (timeline, webMasterTask) => {
  return timeline
    .map(group => group.map(item => {
      if (item.id === webMasterTask.id) {
        return {
          ...item,
          is_completed: !item.is_completed,
        }
      }

      return item;
    }))
}

// Redux helpers stop
