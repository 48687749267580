import classNames from "classnames";

import { classModifier } from "utils";
import getBookingStates from "components/BookingItemNew/helpers/getBookingStates";

import "./BookingMarkers.scss";
import Tooltip from "components/UI/Tooltip/Tooltip";
import { useSelector } from "react-redux";
import { selectContactById } from "redux/selectors/selectors";
import { BOOKING_STATES } from "components/BookingItemNew/config/constants";

const BookingMarkers = ({
  className,
  booking,
  items = getBookingStates(booking)(),
}) => {
  const agentName = useSelector(
    (state) => selectContactById(state, booking.agent_id)?.fn
  );

  return (
    <div className={classNames(className, "booking-markers")}>
      {items.map((markerName, i) => (
        <Tooltip
          key={i}
          text={
            markerName === BOOKING_STATES.AGENT && agentName
              ? agentName
              : markerName
          }
        >
          <div
            className={classModifier("booking-markers__item", markerName)}
          ></div>
        </Tooltip>
      ))}
    </div>
  );
};

export default BookingMarkers;
